import React, { useEffect } from 'react'
import { useStore } from '../../../contexts/store-context/StoreContextProvider'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import CircularLoading from '../circular-progress/CircularLoading';
import storeManagementRestService from '../../../services/rest/StoreManagementRestService';
import { putLoggedInStore, putLoggedInStoreAsync } from '../../../indexeddb/AppContext';

const CheckLoggedInStatus = () => {
    const { store, storeLoaded } = useStore();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (storeLoaded) {
            if (store) {
                if (store?.status === 'ONBOARDING') {
                    storeManagementRestService.getStoreById(store.partyId).then(res => {
                        if (res.status === 'ACTIVE') {
                            putLoggedInStore(res);
                            navigate('/');
                        } else {
                            navigate('/onboarding');
                        }
                    })
                }
                else {
                    if (location.pathname === 'login') {
                        navigate('/');
                    }
                }
            } else {
                navigate('/login-register');
            }
        }
    }, [store, storeLoaded]);
    return (
        <>
            {
                storeLoaded ?
                    <>
                        {<Outlet />}
                    </> :
                    <CircularLoading />
            }
        </>
    )
}

export default CheckLoggedInStatus