import baseUrl from "../../constants"
import axios from 'axios'
import axiosCommons from "./AxiosCommons";
import authFetch from "../../utils/interceptor";

const deliveryOptionRestService = {
    putDeliveryOption: async (deliveryOptionId, storeId, data) => {
        const url = new URL(`${baseUrl}stores/${storeId}/delivery-options/${deliveryOptionId}`);
        // const axiosPromise = axios.put(url, data);
        // return axiosCommons.resolveResponse(axiosPromise);
        const options = {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        }
        catch (err) {
            throw new Error(err.message);
        }
    },
    deleteDeliveryOption: (deliveryOptionId, storeId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/delivery-options/${deliveryOptionId}`);
        const axiosPromise = axios.delete(url);
        return axiosCommons.resolveResponse(axiosPromise);
    },
    createDeliveryOption: async (storeId, data) => {
        const url = new URL(`${baseUrl}stores/${storeId}/delivery-option`);
        const options = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        }
        catch (err) {
            throw new Error(err.message);
        }
    },
    getDeliveryOptions: async (storeId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/delivery-options`);
        const options = {
            method: 'GET',
            credentials: 'include',
        };
        try {
            const res = await authFetch(url, options);
            return await res.text();
        }
        catch (err) {
            throw new Error(err.message);
        }
    },
    getDeliveryOptionById: async (storeId, deliveryOptionId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/delivery-options/${deliveryOptionId}`);
        const options = {
            method: 'GET',
            credentials: 'include',
        };
        try {
            const res = await authFetch(url, options);
            return await res.text();
        }
        catch (err) {
            throw new Error(err.message);
        }
    }
}

export default deliveryOptionRestService;