import { Box, Button, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import userPermissionRestService from "../../services/rest/UserPermissionRestService";
import { Card, H2, PrimaryButton } from "@lifease/lifease-storeui-components";

function UserCard(props) {
    const { user, store, handleRemoveUser, index } = props;
    const [userLoading, setUserLoading] = useState(false);
    const handleRemoveClick = async () => {
        setUserLoading(true);
        try {
            const res = await userPermissionRestService.removeUser(store.partyId, user.id);
            handleRemoveUser(index, user);
        } finally {
            setUserLoading(false);
        }
    }
    return (
        <Card>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: 2, alignItems: 'center' }}>
                <H2>{user.mobile}</H2>
                <PrimaryButton onClick={handleRemoveClick} disabled={userLoading}>Remove access</PrimaryButton>
            </Box>
        </Card>
        // <Card>
        //     <CardContent>
        //         <Typography variant="h6" component="div" color='primary'>{user.name}</Typography>
        //         <Typography variant="subtitle1" component="div">{user.mobile}</Typography>
        //         <Button disabled={userLoading} variant="contained" size="small" color="secondary" onClick={handleRemoveClick}>Remove access</Button>
        //     </CardContent>
        // </Card>
    );
}

export default UserCard;