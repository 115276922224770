import baseUrl from "../../constants";
import authFetch from "../../utils/interceptor";

const paymentRestService = {
    getPaymentDetails: async (storeId, orderId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/orders/${orderId}/payment`);
        const options = {
            credentials: 'include',
            method: "GET"
        };
        const res = await authFetch(url, options)
        if (res.ok) {
            return await res.json();
        }
        throw new Error(await (res.json()).message);
    },
    getProvider: async (providerId) => {
        const url = new URL(`${baseUrl}payment-provider/${providerId}`);
        const options = {
            credentials: 'include',
            method: "GET"
        };
        const res = await authFetch(url, options)
        if (res.ok) {
            return await res.json();
        }
        throw new Error(await (res.json()).message);
    },
    getProviderInfoById: async (providerId) => {
        const url = new URL(`${baseUrl}payment-provider/${providerId}`);
        const options = {
            credentials: 'include',
            method: "GET"
        };
        const res = await authFetch(url, options)
        if (res.ok) {
            return await res.json();
        }
        throw new Error(await (res.json()).message);
    }
}

export default paymentRestService;