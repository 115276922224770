import React, { useCallback, useEffect, useState } from 'react';
import lodash from 'lodash'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import productRestService from '../../services/rest/ProductRestService';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { Typography, Grid, Switch, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getLoggedInStore } from '../../indexeddb/AppContext';

const SearchProduct = (props) => {
    const { productCategories, metrics } = props;

    const createCategoryId = () => {
        const listOfIds = productCategories.map((productCategory) => {
            return productCategory.id;
        });
        if (listOfIds.length)
            return listOfIds.join(",");
        else return null;
    }

    const listOfIds = createCategoryId();

    const timeout = 500;
    const [suggestion, setSuggestion] = useState([]);
    const [search, setSearch] = useState('');
    const [productMaster, setProductMaster] = useState(true);
    const [store, setStore] = useState(null);
    useEffect(() => {
        getLoggedInStore().then(store => {
            setStore(store);
        });
    }, []);

    const navigate = useNavigate();

    const handleChangeSuggestion = (e) => {
        const list = createCategoryId();
        if (productMaster) {
            productRestService.productSearchSuggestion(e.target.value, list).then(res => {
                setSuggestion(res);
            })
        } else {
            productRestService.storeProductSearchSuggestion(e.target.value, store.partyId).then(res => {
                setSuggestion(res);
            })
        }
    }
    const debounceFn = useCallback(lodash.debounce(handleChangeSuggestion, timeout), []);

    const changeHandler = (e) => {
        setSearch(e.target.value);
        debounceFn(e);
    }

    const selectHandler = (e) => {
        const text = e.target.innerText;
        if (text) {
            setSearch(e.target.innerText);
            if (productMaster) {
                productRestService.productSearch(text, listOfIds).then(res => {
                    navigate("/add-products", { state: { productCategory: res, search: true, metrics: metrics } })
                });
            } else {
                productRestService.storeProductSearch(text, store.partyId).then(res => {
                    navigate("/view-products", { state: { productCategory: res, search: true, metrics: metrics } })
                })
            }
        }

    }

    const handleKey = (e, flag) => {
        if (flag || e?.which === 13) {
            if (productMaster) {

                productRestService.productSearch(search, listOfIds).then(res => {
                    navigate("/add-products", { state: { productCategory: res, search: true, metrics: metrics } })
                });
            } else {
                productRestService.storeProductSearch(search, store.partyId).then(res => {
                    navigate("/view-products", { state: { productCategory: res, search: true, metrics: metrics } })
                });
            }
        }
    }

    const handleSwitch = (e) => {
        setProductMaster(e.target.checked);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0.5rem 0 0.5rem 0', marginLeft: 2 }}>
            <Grid sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '0', margin: '0' }}>
                <Autocomplete
                    options={suggestion}
                    sx={{ width: 300, padding: '4px' }}
                    freeSolo
                    onInputChange={changeHandler}
                    onChange={selectHandler}
                    handleHomeEndKeys={false}
                    onKeyDown={(e) => { handleKey(e, false) }}
                    renderInput={(params) => <TextField {...params} label=""
                        placeholder="Search Product"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (<InputAdornment position='start' onClick={(e) => handleKey(e, true)}>
                                <IconButton sx={{ position: 'relative' }}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>)
                        }} />}
                />
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', columnGap: '0.3rem', padding: '0', margin: '0' }}>
                <Switch size='small' color='secondary' value={productMaster} onChange={handleSwitch} defaultChecked />
                <Typography variant='subtitle1' fontSize='0.9rem'>{(productMaster) ? 'Master' : 'Store'} catalogue</Typography>
            </Grid>
        </Box>
    )
}

export default SearchProduct;