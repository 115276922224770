import { Alert, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ViewProductCard from "./ViewProductCardComponent";
import { getLoggedInStore } from "../../indexeddb/AppContext";
import productRestService from "../../services/rest/ProductRestService";
import { useInView } from "react-intersection-observer";
import { H2, H4, PrimaryButton, SecondaryButton, UpdateProductCard } from "@lifease/lifease-storeui-components";
import { useDrawer } from "../../contexts/drawer/DrawerContextProvider";
import ProductModalComponent from "./ProductModalComponent";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useDialog } from "../../contexts/dialog/DialogContextProvider";
import { DateTimePicker } from "@mui/x-date-pickers";

function ViewProductsGrid(props) {
    const { productCategory, search, metrics, isAvailable } = props;
    const { openDrawer, closeDrawer } = useDrawer();
    const { showSnackbar } = useSnackbar();
    const { store } = useStore();
    const [products, setProducts] = useState({ loading: true, data: [], minProductId: null });
    const [unavailableProduct, setUnavailableProduct] = useState({ loading: true, data: [], minProductId: null });
    const [hasProducts, setHasProducts] = useState(true);
    const { ref: progressRef, inView } = useInView();
    const productUnavailableDiv = <Alert sx={{ mt: 2, ml: "auto", mr: "auto" }} severity="warning">No more products!!</Alert>;
    const { showDialog, closeDialog } = useDialog();
    const [productLoad, setProductLoad] = useState(false);
    useEffect(() => {
        if (!search) {
            fetchProducts();
        } else {
            setProducts({
                loading: false,
                data: productCategory,
                minProductId: null
            });
            setUnavailableProduct({
                loading: false,
                data: productCategory,
                minProductId: null
            })
        }
    }, [inView, isAvailable]);

    const handleOnButtonClick = async (form, product) => {
        try {
            setProductLoad(true);
            const newProduct = { ...product, ...form };
            const data = await productRestService.updateProduct(store.partyId, product.id, newProduct);
            setProducts(prev => ({ ...prev, data: prev.data.map(item => (newProduct.id === item.id) ? data : item) }));
            closeDrawer();
        } catch (err) {
            showSnackbar('error', err.message);
        } finally { setProductLoad(false); }
    };

    const handleUpdate = (product) => {
        openDrawer(() => <></>, () => <ProductModalComponent loading={productLoad} product={product} metrics={metrics} store={store} handleOnButtonClick={handleOnButtonClick} type={"UPDATE"} />);
    }

    const handleConfirmDialog = async (stockTime, product) => {
        const data = {
            isAvailable: false,
            restockTime: stockTime
        };
        try {
            const store = await getLoggedInStore();
            const response = await productRestService.setProductAvailability(store.partyId, product.id, data);
            setProducts(prev => ({ ...prev, data: prev.data.filter(item => item.id !== product.id) }));
            closeDialog();
        } catch (err) {
            showSnackbar(err.message);
        }
    }

    const handleCloseDialog = (product) => {
        // getLoggedInStore().then(store => {
        //     const data = {
        //         isAvailable: false
        //     };
        //     productRestService.setProductAvailability(store.partyId, product.id, data).then(response => {
        // setProducts(prev => ({ ...prev, data: prev.data.filter(item => item.id !== product.id) }));
        closeDialog();
        //     }).catch(() => { });
        // });
    }

    const DialogTitle = () => <H2 fontSize={'large'}>Product restock time</H2>

    const DialogComponent = (props) => {
        const { product } = props;
        const [stockTime, setStockTime] = useState(null);
        const handleStockChange = (e) => {
            setStockTime(e.toString());
        }
        return <Box sx={{ display: 'flex', flexDirection: 'column', width: 280, rowGap: 2 }}>
            <H4>Add time when product will be in stock</H4>
            <DateTimePicker onAccept={handleStockChange} label={'Restock time(optional)'} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: 2 }}>
                <PrimaryButton color="secondary" variant='contained' onClick={() => { handleConfirmDialog(stockTime, product) }}>Confirm</PrimaryButton>
                <SecondaryButton color="secondary" variant="contained" onClick={() => { handleCloseDialog(product) }}>Cancel</SecondaryButton>
            </Box>
        </Box>
    }

    const handleOnStockChange = async (product, inStock) => {
        if (inStock) {
            try {
                let obj = { isAvailable: inStock };
                await productRestService.updateProduct(store.partyId, product.id, obj);
                setProducts(prev => ({ ...prev, data: products.data.filter(item => item.id !== product.id) }))
            } catch (err) {
                showSnackbar('error', err.message);
            }
        } else {
            showDialog(() => <DialogTitle />, () => <DialogComponent product={product} />)
        }
    }


    return (
        <Grid container rowSpacing={{ xs: 1 }} columnSpacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
            {
                (search && products?.data.length === 0) && <Typography sx={{ width: "100%", display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>No Search results found</Typography>
            }
            {
                products.loading && unavailableProduct.loading &&
                <Typography color={'primary'} sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>Loading products please wait</Typography>
            }
            {isAvailable && products.data.map((product) => (
                <Grid key={product.id} xs={12} sm={12} md={6} item>
                    <UpdateProductCard metrics={metrics} product={product} showButton={true} handleUpdate={(product) => { handleUpdate(product) }} handleStock={() => { handleOnStockChange(product, false) }} />
                    {/* <ViewProductCard key={product.id} product={product} metrics={metrics}></ViewProductCard> */}
                </Grid>
            ))}
            {/* {!isAvailable && unavailableProduct.data.map((product, idx) => (
                <Grid key={idx} xs={12} sm={12} md={6} item>
                    <UpdateProductCard product={product} showButton={false} handleUpdate={(product) => { handleUpdate(product) }} handleStock={handleOnStockChange(product, true)} />
                </Grid>
            ))} */}
            {
                !search && isAvailable &&
                !(products.loading) &&
                (hasProducts ?
                    <Box sx={{ mx: 'auto', mt: 1 }} ref={progressRef}>
                        <CircularProgress size={25} />
                    </Box>
                    :
                    productUnavailableDiv)
            }
        </Grid>
    );

    function fetchProducts() {
        getLoggedInStore().then(store => {
            if (store) {
                productRestService
                    .fetchStoreProducts(store?.partyId, productCategory.id, products.minProductId, isAvailable)
                    .then(res => {
                        if (isAvailable) {
                            setUnavailableProduct({ loading: true, data: [], minProductId: null })
                            if (res.length > 0) {
                                setProducts(prevProducts => ({
                                    ...prevProducts,
                                    loading: false,
                                    minProductId: res[res.length - 1]?.id - 1,
                                    data: [...prevProducts.data, ...res]
                                }))
                            } else {
                                setHasProducts(false);
                                setProducts(prevProducts => ({
                                    ...prevProducts,
                                    loading: false
                                }))
                            }

                        } else {
                            setProducts({ loading: true, data: [], minProductId: null })
                            setHasProducts(true)
                            if (res.length > 0) {
                                setUnavailableProduct(prevProducts => ({
                                    ...prevProducts,
                                    loading: false,
                                    minProductId: res[res.length - 1]?.id - 1,
                                    data: [...prevProducts.data, ...res]
                                }))
                            } else {

                                setUnavailableProduct(prevProducts => ({
                                    ...prevProducts,
                                    loading: false
                                }))
                            }
                        }
                    })
                    .catch(err => {
                        setProducts(prevProducts => ({
                            ...prevProducts,
                            loading: false,
                            minProductId: null,
                            data: []
                        }));
                        setUnavailableProduct(prevProducts => ({
                            ...prevProducts,
                            loading: false,
                            minProductId: null,
                            data: []
                        }));
                    })
            }
        });
    }
}

export default ViewProductsGrid;