import baseUrl from '../../constants';
import axios from 'axios';
import authFetch from '../../utils/interceptor';

const logisticRestService = {
  getQuote: async (storeId, shipmentId) => {
    axios.defaults.withCredentials = true;
    let url = `${baseUrl}logistics/stores/${storeId}/shipments/${shipmentId}:quote`;
    const options = {
      method: 'GET',
      credentials: 'include',
    };
    try {
      const res = await authFetch(url, options);
      return await res.json();
    } catch (err) {
      throw new Error(err.message);
    }
  },

  bookLogistic: async (storeId, shipmentId, data) => {
    const url = `${baseUrl}logistics/stores/${storeId}/shipments/${shipmentId}:book`;
    const options = {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await authFetch(url, options);
      return await res.json();
    } catch (err) {
      throw new Error(err.message);
    }
  },

  cancelLogistic: async (storeId, shipmentId) => {
    let url = `${baseUrl}logistics/stores/${storeId}/shipments/${shipmentId}:cancel`;
    const options = {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const rawRes = await authFetch(url, options);
      return await rawRes.json();
    } catch (err) {
      throw new Error(err.message);
    }
  },
  trackLogistic: async (storeId, shipmentId) => {
    let url = `${baseUrl}logistics/stores/${storeId}/shipments/${shipmentId}:track`;
    const options = {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const rawRes = await authFetch(url, options);
      return await rawRes.json();
    } catch (err) {
      throw new Error(err.message);
    }
  },
};

export default logisticRestService;
