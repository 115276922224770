import {

  Grid,
  Typography,
  Chip,
  useTheme,
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';
import { formatText, getStateColor } from '../../utils/commons';
import ShipmentItem from './ShipmentItemComponent';
import ShipmentAction from './ShipmentActionComponent';
import DelivertTime from '../delivery-time/DelivertTime';
import LogisticsComponent from './LogisticsComponent';
import Logistics from './Logistics';
import { Card, H2, OrderCard, ProductCard } from '@lifease/lifease-storeui-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function ShipmentCard(props) {
  const { shipment, order, metrics, onShipmentAction, index } = props;
  const shipmentClone = cloneDeep(shipment);
  const [acceptedShipment, setAcceptedShipment] = useState({
    ...shipmentClone,
    changed: false,
    netQuantity: 0,
  });
  const [bookingDetails, setBookingDetails] = useState(null);
  const handleMinusClick = (quantity, index) => {
    if (quantity === 0) return;
    setAcceptedShipment((prevShipment) => {
      const prevQuantity = prevShipment.shipmentItems[index].quantity;
      const prevObject = {
        ...prevShipment.shipmentItems[index],
        quantity: prevQuantity - 1,
      };
      const orders = prevShipment.shipmentItems;
      orders[index] = prevObject;
      return {
        ...prevShipment,
        shipmentItems: orders,
        changed: true,
        netQuantity: prevShipment.netQuantity - 1,
      };
    });
  };

  const handlePlusClick = (index) => {
    if (
      shipment.shipmentItems[index].quantity <
      acceptedShipment.shipmentItems[index].quantity + 1
    )
      return;
    setAcceptedShipment((prevShipment) => {
      const prevQuantity = prevShipment.shipmentItems[index].quantity;
      const prevObject = {
        ...prevShipment.shipmentItems[index],
        quantity: prevQuantity + 1,
      };
      const orders = prevShipment.shipmentItems;
      orders[index] = prevObject;
      return {
        ...prevShipment,
        shipmentItems: orders,
        changed: true,
        netQuantity: prevShipment.netQuantity + 1,
      };
    });
  };

  return (
    // <Card
    //   sx={{
    //     mb: 1,
    //     borderTopStyle: 'solid',
    //     borderTopWidth: '4px',
    //     borderColor: theme.palette[statusColor].main,
    //   }}
    // >
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
      <OrderCard shipment={shipment} onAction={() => { }} />
      {/* <OrderCard /> */}
      {/* <CardContent>
        <Grid container sx={{ mb: 1 }}>
          <Grid item xs={6}>
            <Typography variant='subtitle1' component='div'>
              Shipment: {shipment?.id}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Chip
              color={statusColor}
              size='small'
              label={formatText(shipment?.state, '_', 'Title')}
              variant='outlined'
            />
          </Grid>
          {!(
            shipment.state === 'CANCELLED' || shipment.state === 'DELIVERED'
          ) && (
              <Grid item xs={12}>
                <DelivertTime shipment={shipment} />
              </Grid>
            )}
          <Grid item xs={12} sx={{ my: 1 }}>

          </Grid>
          {["ACCEPTED", "PACKED"].includes(shipment?.state) && (
            <Grid item xs={12}>
              <Logistics
                order={order}
                shipmentId={shipment.id}
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
              />
            </Grid>
          )}
        </Grid> */}
      <Accordion sx={{ borderRadius: 2 }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="shipment-items-list"
        >
          <H2>Ordered products list</H2>
        </AccordionSummary>
        <AccordionDetails>
          {acceptedShipment?.shipmentItems?.map((item, idx) => (
            // <Grid item key={item?.productId} xs={12}>
            <ShipmentItem
              key={item?.productId}
              created={acceptedShipment.state === 'CREATED'}
              quantityChanged={acceptedShipment.changed}
              orderedQuantity={shipment?.shipmentItems[idx].quantity}
              item={item}
              handleMinusClick={handleMinusClick}
              handlePlusClick={handlePlusClick}
              index={idx}
              metrics={metrics}
            />
            // </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
      {
        shipment.state !== 'CANCELLED' && shipment.state !== 'DELIVERED' &&
        <Card>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 2 }}>
            <ShipmentAction
              index={index}
              onShipmentAction={onShipmentAction}
              shipment={acceptedShipment}
              shipState={shipment.state}
            />
            {/* <Divider orientation='horizontal' sx={{ my: 1 }} /> */}
            {["ACCEPTED", "PACKED"].includes(shipment?.state) && (
              <Logistics
                order={order}
                shipmentId={shipment.id}
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
              />
            )}
          </Box>
        </Card>
      }
      {/* </CardContent> */}
    </Box>
    // </Card >
  );
}

export default ShipmentCard;
