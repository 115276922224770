import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import DeliveryPartnerModal from './DeliveryPartnerModal';
import logisticRestService from '../../services/rest/LogisticsRestService';
import { PrimaryButton, SecondaryButton } from '@lifease/lifease-storeui-components';

const Logistics = ({
  order,
  shipmentId,
  bookingDetails,
  setBookingDetails,
}) => {
  const { fromPartyId } = order;
  const [quoteDataList, setQuoteDataList] = useState(null);
  const [open, setOpen] = useState(false);

  const getQuote = async (fromPartyId, shipmentId) => {
    try {
      const res = await logisticRestService.getQuote(fromPartyId, shipmentId);
      setQuoteDataList(res.quoteDataList);
    } catch (error) {
      console.error('Error fetching quote:', error);
    }
  };

  const handleClickOpen = () => {
    getQuote(fromPartyId, shipmentId);
    setOpen(true);
  };

  const handleTrackShipment = () => {
    if (bookingDetails && bookingDetails.trackingUrl) {
      window.open(bookingDetails.trackingUrl, '_blank');
    }
  };

  const handleCancel = async (storeId, shipmentId) => {
    try {
      const res = await logisticRestService.cancelLogistic(storeId, shipmentId);
      if (res) {
        setBookingDetails(null);
      }
    } catch (error) {

    }
  };

  const trackBookingFn = async (storeId, shipmentId) => {
    try {
      const res = await logisticRestService.trackLogistic(storeId, shipmentId);
      if (res) {
        setBookingDetails(res);
      }
    } catch (error) {
      console.error('Error tracking booking:', error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'CREATED':
        return 'green';
      case 'ACCEPTED':
        return 'orange';
      case 'LIVE':
        return 'blue';
      case 'CANCELLED':
        return 'red';
      case 'REOPENED':
        return 'green';
      case 'COMPLETED':
        return 'grey';
      default:
        return 'black';
    }
  };

  const formatPickupTime = (pickupTime) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata',
    };
    return new Date(pickupTime).toLocaleString('en-US', options);
  };

  const StatusTypography = ({ status }) => (
    <Typography>
      Status: <span style={{ color: getStatusColor(status) }}>{status}</span>
    </Typography>
  );

  useEffect(() => {
    trackBookingFn(fromPartyId, shipmentId);
  }, [fromPartyId, shipmentId]);

  return (
    <>
      {!bookingDetails && (
        <>
          {
            order.deliveryType === 'HOME_DELIVERY' &&
            <PrimaryButton
              size='small'
              variant='contained'
              style={{ mr: 1, my: 1 }}
              onClick={handleClickOpen}
            >
              Book Delivery Partner
            </PrimaryButton>
          }
        </>
      )}
      {quoteDataList && (
        <DeliveryPartnerModal
          open={open}
          setOpen={setOpen}
          quoteDataList={quoteDataList}
          shipmentId={shipmentId}
          order={order}
          setBookingDetails={setBookingDetails}
        />
      )}

      {bookingDetails && (
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='h6'>{bookingDetails.displayText}</Typography>
            <StatusTypography status={bookingDetails.status} />
          </Box>
          <Typography>Booking ID: {bookingDetails.trackingCode}</Typography>

          {bookingDetails.estimatedFare && (
            <Typography>
              Estimated Fare: ₹{bookingDetails.estimatedFare}
            </Typography>
          )}
          {bookingDetails.actualFare && (
            <Typography>Actual Fare: ₹{bookingDetails.actualFare}</Typography>
          )}

          <Typography>
            Pickup Time:{' '}
            {formatPickupTime(bookingDetails.orderTimings.pickupTime)}
          </Typography>

          {bookingDetails.agentInfo && (
            <Box>
              <Typography variant='subtitle1'>Agent Information:</Typography>
              <Typography>
                Agent Name: {bookingDetails.agentInfo.agentName}
              </Typography>
              <Typography>
                Agent Number: {bookingDetails.agentInfo.agentNumber}
              </Typography>
              {bookingDetails.agentInfo.agentSecondaryNumber && (
                <Typography>
                  Agent Secondary Number:{' '}
                  {bookingDetails.agentInfo.agentSecondaryNumber}
                </Typography>
              )}
              <Typography>
                Vehicle Number: {bookingDetails.agentInfo.vehicleNumber}
              </Typography>
              <Typography>
                Vehicle Type: {bookingDetails.agentInfo.vehicleType}
              </Typography>
            </Box>
          )}

          <Button
            size='small'
            variant='contained'
            color='success'
            sx={{ mr: 1, my: 1 }}
            onClick={() => trackBookingFn(fromPartyId, shipmentId)}
          >
            Refresh
          </Button>

          <Button
            size='small'
            variant='contained'
            color='primary'
            sx={{ mr: 1, my: 1 }}
            onClick={handleTrackShipment}
          >
            Track
          </Button>

          <Button
            size='small'
            color='error'
            variant='contained'
            sx={{ mr: 1, my: 1 }}
            onClick={() => handleCancel(fromPartyId, shipmentId)}
          >
            Cancel
          </Button>
        </Box>
      )}
    </>
  );
};

export default Logistics;
