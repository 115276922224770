import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ViewProductsGrid from '../../components/products/ViewProductsGridCeomponent';
import productRestService from '../../services/rest/ProductRestService';
import { UpdateProductCard } from '@lifease/lifease-storeui-components';

const UpdateProductsGrid = (props) => {
    const { categoryId } = props;
    const [metrics, setMetrics] = useState([]);
    const [products, setProducts] = useState([]);
    // const { productCategory, search, metrics, isAvailable }
    useEffect(() => {
        productRestService.getMetrics().then(res => {
            setMetrics(res);
        });
    }, []);
    return (
        <>
            {/* <UpdateProductCard
                product={}
            /> */}
            <ViewProductsGrid
                metrics={metrics}
                productCategory={{ id: categoryId }}
                search={false}
                isAvailable={true}
            />
        </>
    )
}

export default UpdateProductsGrid