import { Box } from '@mui/material'
import React from 'react'
import { weekdays } from '../../utils/luxonUtils';
import ScheduleForm from './ScheduleForm';

const ScheduleList = (props) => {
    const { schedule, editable, onSwitchChange, handleAddEditClick } = props;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
            {
                weekdays.map((day) => {
                    return (
                        <ScheduleForm key={day} day={day} timeSlots={schedule[day]} editable={editable} onSwitchChange={onSwitchChange} handleAddEditClick={handleAddEditClick} />
                    )
                })
            }
        </Box>
    )
}

export default ScheduleList