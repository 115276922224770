import { Box } from '@mui/material'
import React from 'react'
import FilledHeader from '../../components/commons/headers/FilledHeader'
import OnboardingListComponent from '../../components/onboarding/OnboardingListComponent'
import { useStore } from '../../contexts/store-context/StoreContextProvider'
import { H2 } from '@lifease/lifease-storeui-components'

const Onboarding = () => {
    const { store } = useStore();
    return (
        <Box>
            <FilledHeader headText={'Onboarding'} backUrl={'/login-register'} />
            {
                store &&
                <Box sx={{ display: 'flex', columnGap: 1, justifyContent: 'center', mt: 6, mb: 4 }}>
                    <H2><b>Store Name:</b></H2>
                    <H2>{store.storeName}</H2>
                </Box>

            }
            <OnboardingListComponent />
        </Box>
    )
}

export default Onboarding