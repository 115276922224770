import axios from 'axios';
import baseUrl from '../../constants';
import axiosCommons from './AxiosCommons';
import authFetch from '../../utils/interceptor';

const storeManagementRestService = {
    getStoreById: async (storeId) => {
        const url = new URL(`${baseUrl}stores/${storeId}`);
        const options = {
            method: 'GET',
            Credential: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    updateStoreStatus: async (storeId, data) => {
        const url = new URL(`${baseUrl}stores/${storeId}`);
        const options = {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    }
};

export default storeManagementRestService;