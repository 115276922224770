import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StoreTypeForm from './StoreTypeForm';
import LoginForm from '../commons/login-form/LoginForm';
import VerifySendToggleButtons from '../commons/login-form/VerifySendToggleButtons';
import StoreRegistrationRestService from '../../services/rest/StoreRegistrationRestService';
import loginRestService from '../../services/rest/LoginRestService';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { getFirebaseToken } from '../../indexeddb/AppContext';
import { setReactGA } from '../../utils/googleAnalytics';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import ReferralCodeForm from './ReferralCodeForm';

const StoreRegistrationForm = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { setStore } = useStore();
  const [registerForm, setRegisterForm] = useState({
    storeName: '',
    storeType: '',
    mobile: '',
    otp: '',
    verify: false,
    loading: false,
    referralCode: '',
  });

  const [storeTypes, setStoreTypes] = useState([]);

  useEffect(() => {
    StoreRegistrationRestService.getStoreTypes().then((res) => {
      setStoreTypes(
        res.map((item) => ({ value: item.storeType, label: item.displayName }))
      );
    });
  }, []);

  const registerFormChangeHandler = (e) => {
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
  };

  const onButtonClicked = async () => {
    setRegisterForm((prevForm) => ({ ...prevForm, loading: true }));
    try {
      if (registerForm.verify) {
        const firebaseToken = await getFirebaseToken();
        await loginRestService.verifyOtp(
          { phone: registerForm.mobile, otp: registerForm.otp },
          firebaseToken
        );
        const data = {
          storeName: registerForm.storeName,
          storeType: registerForm.storeType,
          mobile: registerForm.mobile,
          otp: registerForm.otp,
          loginRequest: {
            mobile: registerForm.mobile,
            otp: registerForm.otp,
            firebaseToken: firebaseToken,
            app: process.env.REACT_APP_NAME,
          },
          referralCode: registerForm.referralCode,
        };
        const storeRes = await StoreRegistrationRestService.registerStore(data);
        const store = await loginRestService.customerApi();
        setStore(storeRes);
        setReactGA('userId', store.partyId);
        showSnackbar('success', 'Registration Successful');
        navigate('/onboarding');
      } else {
        await loginRestService.setRegisteredNumber(registerForm.mobile);
        setRegisterForm((prevForm) => ({
          ...prevForm,
          loading: false,
          verify: true,
        }));
      }
    } catch (err) {
      const error =
        err.message === 'null' ? 'Something went wrong' : err.message;
      showSnackbar('error', error);
      setRegisterForm((prevForm) => ({
        ...prevForm,
        loading: false,
        verify: true,
      }));
    }
  };

  const timerFunction = async () => {
    setRegisterForm((prevForm) => ({ ...prevForm, loading: true }));
    try {
      await loginRestService.setRegisteredNumber(registerForm.mobile);
    } catch (err) {
      const error =
        err.message === 'null' ? 'Something went wrong' : err.message;
      showSnackbar('error', error);
    } finally {
      setRegisterForm((prevForm) => ({
        ...prevForm,
        loading: false,
        verify: true,
      }));
    }
  };

  const handleClear = (name) => {
    setRegisterForm((prevForm) => ({ ...prevForm, [name]: '' }));
  };

  return (
    <Box
      sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 6 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 3,
          width: '100%',
          px: 3,
        }}
      >
        <StoreTypeForm
          storeTypes={storeTypes}
          handleClear={handleClear}
          registerForm={registerForm}
          handleOnChange={registerFormChangeHandler}
        />
        <LoginForm
          handleClear={handleClear}
          form={registerForm}
          handleFormChange={registerFormChangeHandler}
        />
        <VerifySendToggleButtons
          timerFunction={timerFunction}
          disabled={registerForm.loading}
          onButtonClick={onButtonClicked}
          verify={registerForm.verify}
        />
        <ReferralCodeForm
          registerForm={registerForm}
          handleOnChange={registerFormChangeHandler}
        />
      </Box>
    </Box>
  );
};

export default StoreRegistrationForm;
