import baseUrl from "../../constants";
import axios from "axios";
import axiosCommons from "./AxiosCommons";
import authFetch from "../../utils/interceptor";

/**
 * All Rest call related to Order
 */
const orderRestService = {

    getOrderDetails: async (storeId, orderId) => {
        let url = `${baseUrl}stores/${storeId}/orders/${orderId}`;
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },

    getOrderByState: async (storeId, orderState, minOrderId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/orders`);
        url.searchParams.set('orderState', orderState);
        if (minOrderId) {
            url.searchParams.set('maxOrderId', minOrderId);
        }
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    getOrdersByFilters: async (storeId, filters, shipBefore, pageNo) => {
        const url = new URL(`${baseUrl}stores/${storeId}/shipments`);
        if (filters) {
            url.searchParams.set('filteredStates', filters);
        }
        if (shipBefore) {
            url.searchParams.set('shipBefore', shipBefore);
        }
        if (pageNo) {
            url.searchParams.set('pageNo', pageNo);
        }
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
        return [];
    }
}

export default orderRestService;