import React from "react";
import { Typography, Grid } from "@mui/material";
import ShipmentCard from "./ShipmentCardComponent";

function Shipment(props) {
    const { shipmentsData, order, metrics, onShipmentAction } = props;
    return (
        <Grid container>
            {/* <Typography variant="body1" sx={{ my: 1 }}>
                Shipment Details
            </Typography> */}
            <Grid container rowSpacing={{ xs: 1, md: 1 }} columnSpacing={2} columns={{ xs: 12 }}>
                {shipmentsData?.map((shipment, idx) => (
                    <Grid item key={shipment?.id} xs={12}>
                        {/* <OrderCard key={idx} shipment={shipment} onAction={() => { }} /> */}
                        <ShipmentCard index={idx} onShipmentAction={onShipmentAction} shipment={shipment} order={order} metrics={metrics}></ShipmentCard>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default Shipment;