import baseUrl from "../../constants";
import authFetch from "../../utils/interceptor";

/**
 * All Rest call related to Shipments
 */
const shipmentRestService = {

    getShipmentsForStore: async (storeId, orderId) => {
        let url = `${baseUrl}stores/${storeId}/orders/${orderId}/shipments`;
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },

    acceptShipmentForStore: async (storeId, orderId, shipmentId, tag, data) => {
        let url = `${baseUrl}stores/${storeId}/orders/${orderId}/shipments/${shipmentId}:${tag}`;
        const options = {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        }
        catch (err) {
            throw new Error(err.message);
        }
    },

    actionOnShipmentByStore: async (storeId, orderId, shipmentId, tag) => {
        let url = `${baseUrl}stores/${storeId}/orders/${orderId}/shipments/${shipmentId}:${tag}`;
        const options = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        }
        catch (err) {
            throw new Error(err.message);
        }
    }
}

export default shipmentRestService;