import { Alert, Snackbar } from "@mui/material";

const Types = {
    'error': {
        severity: 'error',
        duration: 2000
    },
    'success': {
        severity: 'success',
        duration: 800
    },
    'warning': {
        severity: 'warning',
        duration: 2000
    }
};

const defaultAnchorOrigin = {
    vertical: 'top',
    horizontal: 'right'
};

const defaultStyles = {
    marginTop: '50px'
};

const SnackbarUI = ({ open, type, message, onClose, styles=defaultStyles }) => {
    
    const handleClose = () => {
        onClose();
    }

    return (
        <Snackbar open={open} autoHideDuration={Types[type].duration} onClose={handleClose} anchorOrigin={defaultAnchorOrigin} sx={styles}>
            <Alert onClose={handleClose} variant='filled' severity={Types[type].severity} sx={{ width: 'fit-content', minWidth: '200px'}}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarUI;