import baseUrl from "../../constants";
import axios from "axios";
import axiosCommons from "./AxiosCommons";
import authFetch from "../../utils/interceptor";

/**
 * All Rest call related to Pears
 */
const pearsRestService = {
    getPearsStats: async (storeId, body) => {
        const url = new URL(`${baseUrl}pears/stores/${storeId}/data`);
        const options = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    }
}

export default pearsRestService;