import { Box } from '@mui/material';
import React from 'react'
import { ScheduleType } from '../../../utils/enums';
import { PrimaryButton, SecondaryButton, SlotCard } from '@lifease/lifease-storeui-components';

const ReviewSchedule = (props) => {
    const { selectedDays, days, handleReset, handleConfirm, ScheduleType: type, schedulePresent } = props;
    const WeekTimings = () => {
        let data = [];
        for (const day of days) {
            if (selectedDays[day].slots[0].type === ScheduleType.RELATIVE) {
                let slots = [];
                for (const slot of selectedDays[day].slots) {
                    slots.push({ ...slot, duration: parseInt(slot.duration, 10) * 60 });
                }
                data.push({ ...selectedDays[day], slots: slots });
            } else {
                data.push(selectedDays[day]);
            }
        }
        return data;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, width: '100%' }}>
            {
                WeekTimings().map((item, idx) => {
                    return <SlotCard key={idx} daySchedule={{ dayOfWeek: item.day, timeSlots: item.slots }} />
                })
            }
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2, mb: 4, columnGap: 2 }}>
                <SecondaryButton onClick={handleReset}>Reset Schedule</SecondaryButton>
                <PrimaryButton onClick={handleConfirm}>Confirm Schedule</PrimaryButton>
            </Box>
        </Box>
    )
}

export default ReviewSchedule