import React from 'react';
import BackHeader from '../../components/commons/headers/BackHeader';
import { Box, Divider } from '@mui/material';
import {
  H2,
  H3,
  H4,
  H6,
  PrimaryButton,
} from '@lifease/lifease-storeui-components';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import { useNavigate } from 'react-router-dom';
import loginRestService from '../../services/rest/LoginRestService';
import { clearLoggedInStore, getToken } from '../../indexeddb/AppContext';
import HelpSupport from '../../components/commons/help-&-support/HelpSupport';

const MoreOptions = () => {
  const { store } = useStore();
  const navigate = useNavigate();
  const handleNavigate = (str) => {
    navigate(str);
  };
  const handleLogout = async () => {
    try {
      const token = await getToken();
      if (token) {
        await loginRestService.logout(token);
        await clearLoggedInStore();
        navigate('/login');
      }
    } catch (e) { }
  };
  return (
    <>
      <BackHeader headText={'More Options'} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 3,
          px: 2,
          mt: 2,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
          <H2>{store.storeName}</H2>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <H3>{store.mobile}</H3>
            <PrimaryButton
              onClick={() => {
                handleNavigate('/manage-users');
              }}
            >
              Manage users
            </PrimaryButton>
          </Box>
        </Box>
        <Divider orientation='horizontal' sx={{ width: '100%', my: 0.5 }} />
        <H2
          onClick={() => {
            handleNavigate('/store-settings');
          }}
        >
          Store Settings
        </H2>
        <H2
          onClick={() => {
            handleNavigate('/add-product-category');
          }}
        >
          Add Product Category
        </H2>
        <H2
          onClick={() => {
            handleNavigate('/manage-channels');
          }}
        >
          Manage your channels
        </H2>
        <H2
          onClick={() => {
            handleNavigate('/manage-referrals');
          }}
        >
          Manage Referrals
        </H2>
        <Divider orientation='horizontal' sx={{ width: '100%', my: 0.5 }} />
        <H6 onClick={handleLogout} style={{ color: 'gray' }}>
          Logout
        </H6>
        <HelpSupport />
      </Box>
    </>
  );
};

export default MoreOptions;
