import baseUrl from "../../constants";
import axios from "axios";
import authFetch from "../../utils/interceptor";

/**
 * All Rest call related to product
 */
const productRestService = {

    fetchProduct: async (storeId, productId) => {
        axios.defaults.withCredentials = true;
        let url = `${baseUrl}store/${storeId}/products/${productId}`;
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },

    productSearchSuggestion: async (searchWord, categoryIds = null) => {
        const url = new URL(`${baseUrl}product-master-search-auto-complete`);
        if (categoryIds) url.searchParams.set('categoryIds', categoryIds);
        url.searchParams.set('searchWord', searchWord);
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }

    },

    productSearch: async (searchWord, categoryIds = null) => {
        const url = new URL(`${baseUrl}product-master-search`);
        if (categoryIds) url.searchParams.set('categoryIds', categoryIds);
        url.searchParams.set('searchWord', searchWord);
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }

    },

    storeProductSearch: async (searchWord, storeId) => {
        const url = new URL(`${baseUrl}store/${storeId}/products-search`);
        url.searchParams.set('searchWord', searchWord);
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }

    },

    storeProductSearchSuggestion: async (searchWord, storeId) => {
        const url = new URL(`${baseUrl}product-master-search-auto-complete`);
        url.searchParams.set('searchWord', searchWord);
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {

            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }

    },

    getMetrics: async () => {
        const url = new URL(`${baseUrl}metrics`);
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }

    },

    fetchProductMasters: async (categoryId, minProductId) => {
        const url = new URL(`${baseUrl}mdm/product-masters`);
        url.searchParams.set('categoryId', categoryId);
        if (minProductId !== null) {
            url.searchParams.set('maxProductId', minProductId);
        }
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {

            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },

    fetchStoreProducts: async (storeId, categoryId, minProductId, isAvailable) => {
        const url = new URL(`${baseUrl}store/${storeId}/products`);
        url.searchParams.set('categoryId', categoryId);
        if (minProductId && isAvailable) {
            url.searchParams.set('maxProductId', minProductId);
        }

        url.searchParams.set('available', isAvailable);

        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }

    },

    fetchProductVariants: async (storeId, pmid, available) => {
        const url = new URL(`${baseUrl}store/${storeId}/products`);
        url.searchParams.set('productMasterId', pmid);
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },

    addProduct: async (storeId, data) => {
        const url = new URL(`${baseUrl}stores/${storeId}/products`);
        const options = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },

    setProductAvailability: async (storeId, productId, data) => {
        const url = new URL(`${baseUrl}stores/${storeId}/products/${productId}`);
        const options = {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const rawRes = await authFetch(url, options);
            return await rawRes.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    updateProduct: async (storeId, productId, data) => {
        const url = new URL(`${baseUrl}stores/${storeId}/products/${productId}`);
        const options = {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const rawRes = await authFetch(url, options);
            return await rawRes.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },

    getAllProducts: async (storeId, available, maxProductId) => {
        const url = new URL(`${baseUrl}store/${storeId}/products`);
        if (available) {
            url.searchParams.set('available', available);
        }
        if (maxProductId) {
            url.searchParams.set('maxProductId', maxProductId);
        }
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    }
}

export default productRestService;