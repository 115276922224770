import baseUrl from "../../constants"
import authFetch from "../../utils/interceptor";

const helpRestService = {
    postHelp: async (storeId, data) => {
        const url = new URL(`${baseUrl}stores/${storeId}/grievance`);
        const options = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    }
};

export default helpRestService;