import axios from "axios";
import baseUrl, { APP_NAME } from "../../constants";
import authFetch from "../../utils/interceptor";
import axiosCommons from "./AxiosCommons";

const loginRestService = {
    setRegisteredNumber: async (phoneNumber) => {
        const url = new URL(`${baseUrl}auth/login/otp/${phoneNumber}`);
        const options = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.text();
        }
        catch (err) {
            throw new Error(err.message);
        }
    },
    verifyOtp: async (formDetails, firebaseToken) => {
        const data = {
            mobile: formDetails.phone,
            otp: formDetails.otp,
            app: APP_NAME,
            firebaseToken: firebaseToken
        }
        const url = new URL(`${baseUrl}auth/login/customer/otp`);
        const axiosResponse = axios.post(url, data);
        return axiosCommons.resolveResponse(axiosResponse);
    },
    customerApi: async () => {
        axios.defaults.withCredentials = true;
        const url = new URL(`${baseUrl}customer`);
        const options = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    logout: async (token) => {
        const url = new URL(`${baseUrl}auth/logout`);
        const options = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({ token }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const rawRes = await authFetch(url, options);
        if (rawRes.ok) {
            return await rawRes.text();
        }

        throw new Error((await rawRes.json()).message);
    }
};

export default loginRestService;