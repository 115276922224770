import { Box, Typography } from '@mui/material'
import React from 'react'
import FilledHeader from '../../components/commons/headers/FilledHeader'
import { useNavigate } from 'react-router-dom'
import { BrandPrimaryButton, H2, H6, LoginHeader } from '@lifease/lifease-storeui-components';

const LoginRegister = () => {

    const navigate = useNavigate();

    const onLoginClicked = () => {
        navigate('/login');
    }

    const onRegisterClicked = () => {
        navigate('/register');
    }

    return (
        <>
            <LoginHeader />
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4, alignItems: 'center', mt: 6 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', rowGap: 3, alignItems: 'center', width: { xs: '90%', sm: 300 } }}>
                    <BrandPrimaryButton onClick={onLoginClicked}>Login to store</BrandPrimaryButton>
                    <H2 style={{ color: 'gray' }}>OR</H2>
                    <Box>
                        <BrandPrimaryButton onClick={onRegisterClicked}>Register your store</BrandPrimaryButton>
                        <H6><b>Not on Lifease yet? </b>Register and take your store online in minutes. Regsiter now.</H6>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default LoginRegister;