import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import logisticRestService from '../../services/rest/LogisticsRestService';

const LogisticsComponent = ({
  open,
  setOpen,
  estimatedCost,
  shipmentId,
  order,
  setBookingDetails,
  logisticId,
  setOpenModal
}) => {
  const { fromPartyId } = order;
  const [instructions, setInstructions] = useState([]);
  const [additionalComments, setAdditionalComments] = useState('');
  const [expectedFare, setExpectedFare] = useState(estimatedCost);

  function getTwoWheelerCostByLogisticId(logisticsId, data) {
    const quote = data.find((quote) => quote.logisticsId === logisticsId);

    if (!quote) {
      console.log('Logistics ID not found.');
      return null;
    }

    const twoWheelerInfo = quote.riderInfoData.find(
      (rider) => rider.vehicleType === 'TWO_WHEELER'
    );

    if (!twoWheelerInfo) {
      console.log('Two-wheeler info not found for this Logistics ID.');
      return null;
    }

    return twoWheelerInfo.estimatedCost;
  }

  const getQuote = async (fromPartyId, shipmentId) => {
    try {
      const res = await logisticRestService.getQuote(fromPartyId, shipmentId);
      const estimatedCost = getTwoWheelerCostByLogisticId(logisticId, res);
      setExpectedFare(estimatedCost);
    } catch (error) {}
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
  };

  const handleSave = async () => {
    try {
      const data = {
        logisticsId:logisticId,
        shipmentId: shipmentId,
        instructionList: instructions,
        additionalComments: additionalComments,
      };

      const res = await logisticRestService.bookLogistic(
        fromPartyId,
        shipmentId,
        data
      );
      if (res) {
        const result = await logisticRestService.trackLogistic(fromPartyId,shipmentId);
        setBookingDetails(result);
        handleClose();
      }
    } catch (error) {}
  };

  const handleInstructionChange = (event, index) => {
    const newInstructions = [...instructions];
    newInstructions[index] = event.target.value;
    setInstructions(newInstructions);
  };

  const addInstructionField = () => {
    setInstructions([...instructions, '']);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Logistic Details</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Typography variant='body1'>
              Expected Fare: ₹{expectedFare}
            </Typography>
            <IconButton
              onClick={() => getQuote(fromPartyId, shipmentId)}
              size='small'
            >
              {' '}
              <RefreshIcon />
            </IconButton>
          </Box>
          {instructions.map((instruction, index) => (
            <TextField
              key={index}
              margin='dense'
              id={`instruction-${index}`}
              label={`Instruction ${index + 1}`}
              type='text'
              fullWidth
              variant='standard'
              value={instruction}
              onChange={(event) => handleInstructionChange(event, index)}
            />
          ))}
          <Button onClick={addInstructionField} sx={{ my: 2 }}>
            Add Instruction
          </Button>
          <TextField
            autoFocus
            margin='dense'
            id='additional-comments'
            label='Additional Comments'
            type='text'
            fullWidth
            variant='standard'
            value={additionalComments}
            onChange={(event) => setAdditionalComments(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color='dark'
            size='small'
            variant='contained'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color='success'
            size='small'
            variant='contained'
            onClick={handleSave}
          >
            Book
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogisticsComponent;
