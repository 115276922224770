import { ProductStatsStack } from '@lifease/lifease-storeui-components'
import { Box } from '@mui/material'
import React from 'react'
import { useStore } from '../../contexts/store-context/StoreContextProvider'
import { useNavigate } from 'react-router-dom'

const FilterStateValues = {
    'PRODUCT_COUNTS': { navigateUrl: '/view-all-products' },
    'OUT_OF_STOCK': { navigateUrl: '/view-all-products?available=false' },
    'IN_STOCK': { navigateUrl: '/view-all-products?available=true' },
    'PRODUCT_CATEGORIES': { navigateUrl: '/view-product-categories' }
};

const ProductStatsList = () => {
    const { store } = useStore();
    const navigate = useNavigate();
    const onFilterState = (e) => {
        try {
            navigate(FilterStateValues[e].navigateUrl);
        } catch { }
    }
    return (
        <Box sx={{ mt: 2, px: 2 }}>
            <ProductStatsStack storeId={store.partyId} filterState={onFilterState} />
        </Box>
    )
}

export default ProductStatsList