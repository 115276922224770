import axios from "axios";
import baseUrl from "../../constants";
import axiosCommons from "./AxiosCommons";

const userPermissionRestService = {
    removeUser: (storeId, userId) => {
        axios.defaults.withCredentials = true;
        const url = new URL(`${baseUrl}stores/${storeId}/users/${userId}`);
        const axiosResponse = axios.delete(url);
        return axiosCommons.resolveResponse(axiosResponse);
    }
}

export default userPermissionRestService;