import React, { useEffect } from 'react'

const NewRelicComponent = () => {
    
    useEffect(()=>{
    if (process.env.REACT_APP_NODE_ENV === 'production'){
        const script = document.createElement('script');
        script.src = 'newRelicScript.js'; 
        script.defer = true;
        document.body.appendChild(script);
    }
    },[])
   return (
    <>
    </>
   )
}

export default NewRelicComponent