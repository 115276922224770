import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import FilledHeader from '../../components/commons/headers/FilledHeader'
import StoreRegistrationForm from '../../components/register-store/StoreRegistrationForm'

const RegisterStore = () => {
    useEffect(() => {
        document.title = 'Register Store';
    }, []);
    return (
        <Box>
            <FilledHeader headText={'Register Store'} backUrl={'/login-register'} />
            <StoreRegistrationForm />
        </Box>
    )
}

export default RegisterStore