import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react'
import SlotsComponent from './SlotsComponent';
import { AddCircle, H2, H4 } from '@lifease/lifease-storeui-components';
import { convertTimeToDateObject } from '../../../utils/luxonUtils';

const DaySlotComponent = (props) => {
    const { selectedDay, handleTimeChange, addSlot, dayOfWeek, autoTiming, setAutoTiming, ScheduleType } = props;
    const handleChange = (e, type, idx) => {
        let val;
        if (type !== 'duration') {
            val = convertTimeToDateObject(e.target.value);
        } else {
            val = e.target.value;
        }
        handleTimeChange(selectedDay.day, type, val, idx);
    }
    const handleAddSlot = () => {
        addSlot(selectedDay.day, 'add')
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <H2>{selectedDay.day}</H2>
            </Box>
            {
                selectedDay.slots.map((slot, idx) => (
                    <SlotsComponent ScheduleType={ScheduleType} key={idx} index={idx} selectedDay={selectedDay} onChange={handleChange} />
                ))
            }
            <AddCircle sx={{ cursor: 'pointer' }} onClick={handleAddSlot} />
            {
                dayOfWeek === 0 &&
                <FormGroup onChange={(e) => { setAutoTiming(e.target.checked) }}>
                    <FormControlLabel value={autoTiming} label={<H4>Apply timings to all selected days</H4>} control={<Checkbox color='secondary' />} />
                </FormGroup>
            }
        </Box>
    )
}

export default DaySlotComponent