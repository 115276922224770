import React, { useEffect, useState } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
  Typography,
  Box,
} from '@mui/material';
import imageRestService from '../../services/rest/ImageRestService';

const FileRenderer = ({ file, storeId }) => {
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getFile = async (fileId) => {
    try {
      const res = await imageRestService.getStoreImage(storeId, fileId);
      setFileData(res);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching file:', error);
      setLoading(false); // Stop loading if there's an error
    }
  };

  useEffect(() => {
    if (file && file.fileId) {
      getFile(file.fileId);
    }
  }, [file]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!fileData) {
    return <Typography color='error'>Failed to load image</Typography>;
  }

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        border: '1px solid #ddd',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardMedia
        component='img'
        image={fileData.url}
        alt={fileData.identifier}
        sx={{
          width: '100%',
          height: '90%',
          objectFit: 'cover',
        }}
      />
      <CardContent sx={{ height: '10%', padding: '8px' }}>
        <Typography variant='body2' color='textSecondary' noWrap>
          {file.displayName}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default FileRenderer;
