export const OrderStatus = ["CREATED", "PROCESSING", "CANCELLED", "COMPLETED"];
export const ShipmentStatus = ["CREATED", "ACCEPTED", "PACKED", "SHIPPED", "DELIVERED", "CANCELLED"];
export const ScheduleType = {
    ABSOLUTE: 'ABSOLUTE',
    RELATIVE: 'RELATIVE'
};
export const DeliveryOption = {
    NORMAL: 'NORMAL',
    EXPRESS: 'EXPRESS'
};
export const ScheduleLabel = {
    STORE: 'STORE',
    DELIVERY: 'DELIVERY'
}