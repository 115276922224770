import baseUrl from "../../constants";
import axios from "axios";
import authFetch from "../../utils/interceptor";

const productCategoryRestService = {
    addCategoryToStore: async (storeId, categoryId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/product-category/${categoryId}`);
        const options = {
            method: 'POST',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.text();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    getProductCategories: async (storeId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/product-category`);
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        }
        catch (err) {
            throw new Error(err.message);
        }
    },
    getAvailableProductInStock: async (storeId, data) => {
        const url = new URL(`${baseUrl}pears/stores/${storeId}/data`);
        const options = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        }
        catch (err) {
            throw new Error(err.message);
        }
    },
    deleteCategory: async (storeId, categoryId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/product-category/${categoryId}`);
        const options = {
            method: 'DELETE',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.text();
        } catch (err) {
            throw new Error(err.message);
        }
    }
}

export default productCategoryRestService;