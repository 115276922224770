import { createTheme } from "@mui/material";
import { teal, blueGrey } from "@mui/material/colors";

const storeTheme = createTheme({
    palette: {
        background: {
            default: "#F1F1F1"
        },
        primary: {
            main: "#008DE0FF",
        },
        secondary: {
            main: "#EEA47FFF"
        },
        dark: {
            main: "#212529",
            contrastText: '#FFFFFF'
        }
    }
});

export default storeTheme;