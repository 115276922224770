import { Card, CurrencyDisplayComponent, H2, H4, H6 } from '@lifease/lifease-storeui-components';
import { Box, IconButton } from '@mui/material';
import React from 'react'
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const VariantCard = (props) => {
    const { product, metrics, handleVariantClick, type } = props;
    const unitText = metrics.filter(metric => metric.id === product.metricsId);

    return (
        <Card>
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <H2>{product.productName}</H2>
                    <IconButton sx={{ height: 24, width: 24 }} onClick={() => { handleVariantClick(product) }} color='primary'>
                        <ModeEditIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', columnGap: 2 }}>
                    <H6>{product.brand}</H6>
                    <H6>MRP: ₹{product.mrp}</H6>
                    <H4>Selling Price: ₹{product.sellingPrice}</H4>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                    {
                        unitText.length > 0 ?
                            <Box sx={{ display: 'flex', columnGap: 1 }}>
                                <H6>{product.metricsValue}</H6>
                                <H6>{unitText[0].displayText ?? ''}</H6>
                            </Box> :
                            <H6>Units not set</H6>
                    }
                    {
                        product.available ?
                            <H6 style={{ color: 'green' }}>In Stock</H6> :
                            <H6 style={{ color: 'red' }}>Out of stock</H6>
                    }
                </Box>
            </Box>
        </Card>
    )
}

export default VariantCard