import React, { useCallback, useState } from 'react'
import ProductStatsList from '../../components/product-stats/ProductStatsList'
import SearchIconHeader from '../../components/commons/headers/SearchIconHeader'
import SearchSuggestionComponent from '../../components/commons/search-suggestion/SearchSuggestionComponent'
import productRestService from '../../services/rest/ProductRestService'
import { useStore } from '../../contexts/store-context/StoreContextProvider'
import loadash from 'lodash'
import { useNavigate } from 'react-router-dom'

const DebounceTime = 500;

const ProductStats = () => {
    const { store } = useStore();
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();
    const onAction = (e) => {
        setShowSearchIcon(prev => !prev);
    }

    const handleClearInput = () => {
        setSearchText('');
    }

    const searchSuggestion = async (searchWord) => {
        try {
            const suggestions = await productRestService.storeProductSearchSuggestion(searchWord, store.partyId);
            setSuggestions(suggestions);
        } catch { }
    }

    const handleDebounceFn = (searchWord) => {
        searchSuggestion(searchWord);
    }

    const debounceFn = useCallback(loadash.debounce(handleDebounceFn, DebounceTime), []);

    const onChange = (e) => {
        setSearchText(e.target.value);
        if (e.target.value.length > 2) {
            debounceFn(e.target.value);
        } else {
            setSuggestions([]);
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value?.length > 2) {
            navigate(`/search?searchWord=${e.target.value}&type=UPDATE`);
        }
    }

    const onSuggestionClick = (word) => {
        navigate(`/search?searchWord=${word}&type=UPDATE`)
    }

    return (
        <>
            <SearchIconHeader
                placeholder={"Search product to update"}
                showSearchIcon={showSearchIcon}
                onAction={onAction}
                headText={'Product stats'}
                handleClearInput={handleClearInput}
                value={searchText}
                onChange={onChange}
                onKeyDown={onKeyDown}
                autoFocus
            />
            <SearchSuggestionComponent suggestions={suggestions} onSuggestionClick={onSuggestionClick} />
            <ProductStatsList />
        </>
    )
}

export default ProductStats