import { PrimaryButton, SecondaryButton } from '@lifease/lifease-storeui-components'
import { Box, Button } from '@mui/material'
import React from 'react'

const PaginationNextPrevBar = ({ prevHandler, nextHandler, products, minProductId }) => {

    return (
        <Box sx={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: "white", width: '100vw', zIndex: 5, boxShadow: '0px -2px 6px rgba(0,0,0,0.3)', px: 2, py: 1.5 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mb: 1 }}>
                <SecondaryButton disabled={!minProductId} onClick={prevHandler}>Prev</SecondaryButton>
                <PrimaryButton disabled={products?.data?.length === 0} onClick={nextHandler}>Next</PrimaryButton>
            </Box>
        </Box>
    )
}

export default PaginationNextPrevBar