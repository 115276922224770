import { Box, CircularProgress, Typography } from '@mui/material';

const ConfirmPaymentProgressBar = () => {
    return (
        <Box
            sx={{ textAlign: 'center', position: 'absolute', left: '50%', top: '50%', backgroundColor: 'transparent' }}>
            <Box sx={{ transform: 'translate(-50%, -50%)' }}>
                <Typography color={'primary'}>Processing payment mode</Typography>
                <Typography color={'secondary'} fontSize={'0.8rem'} mb={2}>
                    Please do not refresh the page
                </Typography>
                <CircularProgress color="primary" />
            </Box>
        </Box>
    )
}

export default ConfirmPaymentProgressBar