import { Alert, Box, Button, IconButton, Paper, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { convertObjectToUtcTime } from '../utils/luxonUtils';
import TimeSlot from '../components/store-schedule/TimeSlot';
import BackHeader from '../components/commons/headers/BackHeader';


const EditTimeSlots = () => {
  const { state: { timeSlots, redirectTo, day, type, deliveryOption, action } } = useLocation();
  const [slots, setSlots] = useState({ day: day, timeSlots: timeSlots?.slots });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const addSchedule = () => {
    setSlots(prev => ({
      ...prev,
      timeSlots: [
        ...prev.timeSlots,
        {
          type,
          startTime: null,
          endTime: null,
        }
      ]
    }))
  }

  const onSaveSchedule = () => {
    navigate(redirectTo, { state: { slots, deliveryOption, action }, replace: true });
  }

  const onSlotChange = (val, type, index) => {
    const newSlots = slots.timeSlots;
    newSlots[index] = { ...newSlots[index], [type]: convertObjectToUtcTime(val, 'HH:mmZZ') };
    setSlots((prevSlot) => ({
      ...prevSlot,
      timeSlots: newSlots
    }));
  }

  useEffect(() => {
    document.title = 'edit time slots';
  }, [])

  return (
    <>
      <BackHeader headText={'Edit Time Slots'} />
      <Paper elevation={1} sx={{ display: 'flex', flexDirection: 'column', m: 1, my: 2, p: 1, alignItems: 'center', mb: 14 }}>
        <Typography mb={1} fontWeight={600} sx={{ display: 'flex', justifyContent: 'center' }}>Edit Schedule</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
          <Typography fontWeight={600}>{day}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, my: 1 }}>
          {
            slots.timeSlots.map((slot, index) => {
              return <TimeSlot onSlotChange={onSlotChange} key={index} index={index} type={slot.type} startTime={slot.startTime} endTime={slot.endTime} />
            })
          }
        </Box>
        {
          error.length > 0 &&
          <Alert sx={{ mb: 2, width: '100%' }} severity='error'>{error}</Alert>
        }
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, width: '100%' }}>
          <Button variant='contained' startIcon={<AddCircleIcon />} sx={{ width: 'fit-content', color: 'white', alignSelf: 'center' }} onClick={addSchedule} color='secondary'>
            Add slot
          </Button>
          <Button variant='contained' onClick={onSaveSchedule}>Go back</Button>
        </Box>
      </Paper>
    </>
  )
}

export default EditTimeSlots