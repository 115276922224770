import { Box } from '@mui/material';
import React from 'react';
import { ScheduleType } from '../../../utils/enums';
import { TextInput, TimePicker } from '@lifease/lifease-storeui-components';
import { DateObjectToString, convertDateObjectToString } from '../../../utils/luxonUtils';

const SlotsComponent = (props) => {
    const { onChange, selectedDay, index, ScheduleType: type } = props;
    let startTime = '';
    if (selectedDay?.slots[index]?.startTime) {
        startTime = DateObjectToString(selectedDay?.slots[index]?.startTime);
    }
    let endTime = '';
    if (selectedDay?.slots[index]?.endTime) {
        endTime = DateObjectToString(selectedDay?.slots[index]?.endTime);
    }
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', columnGap: 2, px: 2 }}>
                <TimePicker value={startTime} onChange={(e) => { onChange(e, 'startTime', index) }} label='Start Time' />
                <TimePicker value={endTime} onChange={(e) => { onChange(e, 'endTime', index) }} label='End Time' />
            </Box>
            {
                ScheduleType.RELATIVE === type &&
                <TextInput value={selectedDay?.slots[index]?.duration ?? ''} label={'Duration in minutes'} onChange={(e) => { onChange(e, 'duration', index) }} />
            }
        </>
    )
}

export default SlotsComponent;