import { Box, Typography } from '@mui/material'
import React from 'react'
import { convertDateObjectToTimeString } from '../../../utils/luxonUtils';
import { SlotCard } from '@lifease/lifease-storeui-components';

const ViewSchedule = (props) => {
    const { schedule } = props;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            {
                schedule.daySchedules.map((item, idx) => {
                    return <SlotCard key={item.dayOfWeek} daySchedule={{ dayOfWeek: item.dayOfWeek, timeSlots: item.timeSlots }} />
                })
            }
        </Box>
    )
}

export default ViewSchedule