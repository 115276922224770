import { Box, CardMedia, Paper, Skeleton, Typography } from '@mui/material'
import React from 'react'
import ProductVariantForms from './ProductVariantForms'

const AddVariantComponent = (props) => {
    const { product, metrics, imageUrl, submitHandler, progress } = props;
    return (
        <Paper elevation={2} sx={{ p: 1, my: 1 }}>

            <Box sx={{display:'flex', columnGap:1.5}}>

            <CardMedia component="img" height="120" width="120" sx={{ maxWidth: 120 }} src={imageUrl} alt={product.categoryName} />

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography gutterBottom fontSize={'large'}>
                    {product.productName}
                </Typography>
                <Typography gutterBottom fontSize={'small'} color={'primary'}>
                    {product.brand}
                </Typography>
                <Typography gutterBottom fontSize={'small'} >
                    {product?.description}
                </Typography>
            </Box>
            </Box>
            <ProductVariantForms progress={progress} metrics={metrics.data} submitHandler={submitHandler}/>
        </Paper>
    )
}

export default AddVariantComponent