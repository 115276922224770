import { H3 } from '@lifease/lifease-storeui-components';
import { Box, Grow } from '@mui/material';
import React from 'react';

const SuggestionBox = (props) => {
  const { suggestion, onSuggestionClick } = props;

  return (
    <Box
      sx={{ py: 1, px: 2, cursor: 'pointer' }}
      onClick={() => onSuggestionClick(suggestion)}
    >
      <H3>{suggestion}</H3>
    </Box>
  );
};

export default SuggestionBox;
