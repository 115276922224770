import { Autocomplete, GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import React from "react";
import "./maps.css";
import { Grid, Typography } from "@mui/material";
import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
} from "react-geocode";

const google_api_key = process.env.REACT_APP_GOOGLE_MAP_API_KEY
const RenderMaps = (props) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: google_api_key,
        libraries: lib
    });

    const { setMarkerAddress, setMapLoading, latitude, longitude, suggestions } = props;
    const [searchResult, setSearchResults] = useState();
    const [markerPosition, setMarkerPosition] = useState({ lat: latitude, lng: longitude });
    const [mapCenter, setMapCenter] = useState({ lat: latitude, lng: longitude });
    const [map, setMap] = useState(null);

    useEffect(() => {
        setMarkerPosition({ lat: latitude, lng: longitude });
        setMapCenter({ lat: latitude, lng: longitude });
    }, [suggestions]);

    useEffect(() => {
        if (latitude && longitude) {
            updateAddress(latitude, longitude);
        }
    }, []);

    return (
        <Grid container className="App" pb={2}>
            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (
                <GoogleMap
                    onDragStart={onDragStart}
                    resetBoundsOnResize={true}
                    options={{
                        gestureHandling: "greedy",
                        mapTypeControl: false,
                        streetViewControl: false, fullscreenControl: false, zoomControl: false
                    }}
                    onLoad={(map) => setMap(map)}
                    onDrag={onDrag}
                    onDragEnd={onDragEnd}
                    mapContainerClassName="map-container"
                    center={mapCenter}
                    zoom={17}>
                    {/* <Autocomplete onLoad={onLoad} onPlaceChanged={placeSelected}>
            <input
              type="text"
              placeholder="Search for area, apt, street"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `48px`,
                padding: `2 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px"
              }}
            />
          </Autocomplete> */}
                    <Marker position={markerPosition} />
                    <Typography>
                        Address
                    </Typography>
                </GoogleMap>
            )}
        </Grid>
    );

    function placeSelected() {
        if (searchResult != null) {
            const latitude = searchResult?.getPlace().geometry.location.lat();
            const longitude = searchResult?.getPlace().geometry.location.lng();
            setMapCenter({ lat: latitude, lng: longitude });
            setMarkerPosition({ lat: latitude, lng: longitude });
            updateAddress(latitude, longitude);
        }
    }

    function onLoad(autoComplete) {
        setSearchResults(autoComplete);
    }

    function onDragStart() {
        setMapLoading(true);
    }

    function onDrag() {
        setMarkerPosition(map.getCenter())
    }

    function onDragEnd() {
        updateAddress(markerPosition.lat(), markerPosition.lng())
    }

    function updateAddress(lat, lng) {
        setKey(google_api_key);
        fromLatLng(lat, lng).then(
            response => {
                let addressToShow;
                for (let address of response.results) {
                    if (address.types.includes("street_address")) {
                        addressToShow = address;
                        break;
                    }
                }
                if (addressToShow === undefined) {
                    addressToShow = response.results[0];
                }
                setMarkerAddress(addressToShow, { lat, lng });
                setMapLoading(false);
            }
        );
    }
};

const lib = ["places"]

export default RenderMaps;