import React, { useState, useContext, useEffect } from 'react'
import NavbarContext from './NavbarContext'
import { NavbarTypes } from '../../constants'
import { useLocation } from 'react-router-dom';

const LocationNavbarMapping = {
    '/orders': NavbarTypes.Orders,
    '/': NavbarTypes.Orders,
    '/product-stats': NavbarTypes.Products,
    '/services': NavbarTypes.Finance,
    '/more-options': NavbarTypes.More,
}

const NavbarContextProvider = (props) => {
    const [page, setPage] = useState(NavbarTypes.Orders);
    const location = useLocation();
    useEffect(() => {
        if (LocationNavbarMapping[location.pathname]) {
            setPage(LocationNavbarMapping[location.pathname]);
        }
    }, [location.pathname]);

    return (
        <NavbarContext.Provider value={{ page, setPage }}>
            {props.children}
        </NavbarContext.Provider>
    )
};

export const useNavbar = () => useContext(NavbarContext);

export default NavbarContextProvider