import { Badge, Box, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NavbarElements, NavbarTypes } from '../../../constants'
import NavbarElement from './NavbarElement'
import { Outlet, useNavigate } from 'react-router-dom'
import { useNavbar } from '../../../contexts/navbar/NavbarContextProvider'
import CategoryIcon from '@mui/icons-material/Category';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { OrdersIcon, MoreVertIcon, H4 } from '@lifease/lifease-storeui-components'
import SlidingServiceComponent from '../../../common/SlidingServiceComponent'
import SavingsIcon from '@mui/icons-material/Savings';
import KhataRestService from '../../../services/rest/KhataRestService'
import { useStore } from '../../../contexts/store-context/StoreContextProvider'
const size = '24px';

const NavbarIcons = (name) => {
    switch (name) {
        case 'Orders': return <OrdersIcon height={size} width={size} />;
        case 'Products': return <CategoryIcon height={size} width={size} />;
        case 'Finance': return <CurrencyRupeeIcon height={size} width={size} />;
        case 'More': return <MoreVertIcon height={size} width={size} />;
    }
}

const NavbarLocationMapping = {
    [NavbarTypes.Orders]: '/',
    [NavbarTypes.Products]: '/product-stats',
    [NavbarTypes.Finance]: '/services',
    [NavbarTypes.More]: '/more-options',
}

const Navbar = () => {
    const { page } = useNavbar();
    const navigate = useNavigate();
    const { store } = useStore();
    const [balance, setBalance] = useState(0);

    const handleClick = (name) => {
        navigate(NavbarLocationMapping[name]);
    }

    useEffect(() => {
        if (store?.partyId) {
            KhataRestService.getBalance(store?.partyId).then(res => {
                setBalance(res.balance);
            })
        }
    }, []);

    return (
        <>
            <Outlet />
            <Box sx={{ position: 'fixed', bottom: 0, left: 0, width: '100%', height: 80, zIndex: 5 }}>
                <Box
                    sx={{
                        backgroundColor: 'white',
                        height: '100%',
                        boxShadow: '0px -2px 6px rgba(0,0,0,0.3)',
                        display: 'flex',
                        justifyContent: 'space-evenly'
                    }}
                >
                    {
                        NavbarElements.map(item => {
                            return <React.Fragment key={item}>
                                {
                                    item === 'Finance' ?
                                        <Box
                                            onClick={() => { handleClick(item) }}
                                            sx={{
                                                width: '80px',
                                                display: 'flex',
                                                p: 1,
                                                flexDirection: 'column',
                                                rowGap: 1,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                                borderTop: (page === item) ? '4px solid #1C1B1F' : '4px solid transparent'
                                            }}>
                                            <Badge
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                showZero
                                                badgeContent={balance}
                                                color='secondary'
                                                max={9999}>
                                                <SavingsIcon color='black' />
                                            </Badge>
                                            <H4>Finance</H4>
                                        </Box> :
                                        <NavbarElement handleClick={handleClick} Icon={NavbarIcons(item)} selected={page === item} name={item} />
                                }
                            </React.Fragment>
                        })
                    }
                </Box>
            </Box>
        </>
    )
}

export default Navbar;