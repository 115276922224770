import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import VariantList from './VariantList';
import { getLoggedInStore } from '../../indexeddb/AppContext';
import productRestService from '../../services/rest/ProductRestService';
import AddVariantComponent from './AddVariantComponent';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import ProductDetailsHideComponent from '../product-details-hide/ProductDetailsHideComponent';
import BackHeader from '../commons/headers/BackHeader';

const VariantComponent = () => {
  const location = useLocation();
  const { state } = location;
  const { product } = state;
  const params = useParams();
  const { showSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(false);
  const [variants, setVariants] = useState({ loading: true, data: [] });
  const [metrics, setMetrics] = useState({ loading: true, data: [] });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const submitHandler = (data) => {
    setProgress(true);
    const productData = {
      categoryId: product.categoryId,
      brand: product.brand,
      productName: product.productName,
      type: product.type,
      subCategory: product.subCategory,
      mrp: parseFloat(data.mrp),
      sellingPrice: parseFloat(data.sellingPrice),
      imageId: product.imageId,
      productMasterId: product.id,
      metricsId: parseInt(data.metricsId),
      metricsValue: parseFloat(data.metricsValue),
      description: product.description,
      leadTime: parseInt(data.leadTime * 60)
    };
    getLoggedInStore().then(res => {
      productRestService
        .addProduct(res.partyId, productData)
        .then((res) => {
          setVariants(prevVariant => ({
            ...prevVariant,
            data: [res, ...prevVariant.data]
          }));
          showSnackbar('success', 'Product added successfully');
          let urlParams = '';
          if (state.minProductId) {
            urlParams = `?minProductId=${state.minProductId}`;
          }
          navigate(`/add-variants/${params.cid}${urlParams}`, { state: { scroll: state.scrollY } })
        }).catch((err) => {
          showSnackbar('error', err.message);
        }).finally(() => {
          setProgress(false)
        });
    })
  }

  useEffect(() => {
    document.title = 'variant';
    fetchProduct();
  }, []);

  return (
    <>
      <BackHeader headText={'Variants'} />
      <Box sx={{ ...{ width: { xs: '95%', sm: '80%', md: '60%' } }, mx: 'auto', p: 1, mb: 14 }}>
        <ProductDetailsHideComponent />
        {
          !metrics.loading &&
          <AddVariantComponent progress={progress} product={state.product} metrics={metrics} imageUrl={state.imageUrl} submitHandler={submitHandler} />
        }
        {
          !variants.loading && !metrics.loading && variants.data.length > 0 &&
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            <Typography sx={{ display: 'flex', justifyContent: 'center' }} mb={1} mt={2} color={'primary'} fontSize={'large'}>
              Product variants added
            </Typography>
            <VariantList variants={variants.data} metrics={metrics.data} />
          </Box>
        }
        {
          (variants.loading || metrics.loading) &&
          <Typography color={'primary'} fontSize={'medium'} sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>Loading variants please wait</Typography>
        }
      </Box>
    </>
  );

  async function fetchProduct() {
    try {
      const store = await getLoggedInStore();
      const metrics = await productRestService.getMetrics();
      const variantRes = await productRestService.fetchProductVariants(store.partyId, params.pmid);
      setMetrics(prevMetric => ({
        loading: false,
        data: metrics
      }));
      setVariants(prevVariants => ({
        loading: false,
        data: variantRes
      }))
    } catch (err) {
      setError(err);
      setMetrics(prevMetric => ({
        loading: false,
        data: []
      }));
      setVariants(prevVariants => ({
        loading: false,
        data: []
      }))
    }
  }

}

export default VariantComponent