import { getToken } from '@firebase/messaging';
import { messaging } from '../utils/firebaseConfig';
import { putFirebaseToken } from '../indexeddb/AppContext';

async function requestPermission() {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey:
        `${process.env.REACT_APP_FIREBASE_VAP_ID_KEY}`,
    });
    if (token) {
      putFirebaseToken(token);
    }
  } else if (permission === 'denied') {
    alert('You denied for the notification');
    putFirebaseToken('');
  }
}

export default requestPermission;
