import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useNavigate } from 'react-router-dom';
import BackHeader from '../../components/commons/headers/BackHeader';
import { H2 } from '@lifease/lifease-storeui-components';

const StoreSettings = () => {

    const navigate = useNavigate();

    const handleSettingClick = (routePath) => {
        navigate(routePath);
    }

    useEffect(() => {
        document.title = 'store setting';
    }, [])

    return (
        <>
            <BackHeader headText={'Store Settings'} />
            <Paper sx={{ mx: 1, mt: 2 }}>
                <nav aria-label="Store settings list">
                    <List>
                        <ListItem disablePadding onClick={() => { handleSettingClick('store-schedule') }}>
                            <ListItemButton sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon>
                                    <CalendarMonthIcon fontSize='medium' />
                                </ListItemIcon>
                                <H2>Store Timings</H2>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem disablePadding onClick={() => { handleSettingClick('delivery-options') }}>
                            <ListItemButton sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon>
                                    <LocalShippingIcon fontSize='medium' />
                                </ListItemIcon>
                                <H2>Delivery Options</H2>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <List>
                        <ListItem disablePadding onClick={() => { handleSettingClick('account-settings') }}>
                            <ListItemButton sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemIcon>
                                    <AdminPanelSettingsIcon fontSize='medium' />
                                </ListItemIcon>
                                <H2>Account Settings</H2>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
                <Divider />
            </Paper>
        </>
    )
}

export default StoreSettings;