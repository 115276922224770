import React, { useEffect, useState } from 'react'
import IframeComponent from './IframeComponent';
import { Box } from '@mui/material';
import KhataRestService from '../../services/rest/KhataRestService';
import { refreshIntervals } from '../../constants';
import { useNavigate } from 'react-router-dom';

const PhonePeComponent = (props) => {
    const { paymentDetails, store } = props;
    const [paymentIndexInterval, setPaymentIndexInterval] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            pollToGetStatus();
        }, refreshIntervals[paymentIndexInterval] * 1000);
    }, [paymentIndexInterval]);

    return (
        <Box sx={{ width: { xs: '100%', sm: 400 }, height: '100%' }}>
            <IframeComponent url={paymentDetails.lineItems[0]?.paymentWebUrl} />
        </Box>
    );

    async function pollToGetStatus() {
        try {
            const res = await KhataRestService.pollRechargeDetails(store.partyId, paymentDetails.paymentOrderId);
            setPaymentStatus(res);
            if (['SUCCESS', 'FAIL'].includes(res.status)) {
                setTimeout(() => {
                    navigate('/services');
                }, 3000);
            } else {
                setPaymentIndexInterval(prev => Math.min(prev + 1, refreshIntervals.length - 1));
            }
        } catch {

        }
    }
}

export default PhonePeComponent