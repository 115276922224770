import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useProductDetailsHide } from '../../contexts/product-details-context/ProductDetailsHideProvider';

const ProductVariantForms = (props) => {
    const { metrics, submitHandler, progress = false } = props;
    const { productDetailsHide } = useProductDetailsHide();
    const [productForm, setProductForm] = useState({
        mrp: '',
        sellingPrice: '',
        metricsId: '',
        metricsValue: '',
        leadTime: ''
    });

    const formChangeHandler = (e) => {
        setProductForm(prevForm => ({
            ...prevForm,
            [e.target.name]: e.target.value
        }))
    }

    const formSubmitHandler = (e) => {
        e.preventDefault();
        submitHandler(productForm);
        setProductForm(prevForm => ({
            mrp: '',
            sellingPrice: '',
            metricsId: '',
            metricsValue: '',
            leadTime: ''
        }))
    }

    return (
        <form onSubmit={formSubmitHandler}>
            <Box sx={{ display: 'flex', width: '100%', columnGap: 1, mt: 1 }}>
                <TextField type='number' onChange={formChangeHandler} name="mrp" value={productForm.mrp} required size='small' label="MRP" variant="outlined" sx={{ width: '100%' }} />
                <TextField name="sellingPrice" type='number' onChange={formChangeHandler} value={productForm.sellingPrice} required size='small' label="Selling price" variant="outlined" sx={{ width: '100%' }} />
            </Box>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', columnGap: 1, mt: 1 }}>
                <FormControl required sx={{ width: '100%' }}>
                    <InputLabel size={'small'} id="variant-metrics">Metrics</InputLabel>
                    <Select
                        onChange={formChangeHandler}
                        name='metricsId'
                        labelId="variant-metrics"
                        id="variant-select-metrics"
                        size='small'
                        label="Age *"
                        value={productForm.metricsId}
                    >
                        {
                            metrics.map((metric) => (
                                <MenuItem key={metric.id} value={metric.id}>{metric.displayText}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <TextField type='number' onChange={formChangeHandler} value={productForm.metricsValue} name="metricsValue" sx={{ width: '100%' }} required size='small' label="metrics value" variant="outlined" />
            </Box>
            {
                productDetailsHide.leadTime &&
                <Box sx={{ display: 'flex', mt: 1 }}>
                    <TextField type='number' name="leadTime" onChange={formChangeHandler} value={productForm.leadTime} sx={{ width: '100%' }} size='small' variant="outlined" label="Time to prepare product in minutes" />
                </Box>
            }
            <Button disabled={progress} type='submit' variant='contained' color='secondary' sx={{ width: '100%', my: 1.5 }}>
                Save Product
            </Button>
        </form>

    )
}

export default ProductVariantForms