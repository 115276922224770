import axios from "axios";
import baseUrl from "../../constants";
import { getToken, putLoggedInStore, putToken } from "../../indexeddb/AppContext";

export function refreshAndCheckLogin() {
    return new Promise((resolve) => {
        axios.defaults.withCredentials = true;
        axios.get(baseUrl + "auth/status").then(response => {
            handleStatusSuccess(response).then(response => {
                resolve(response);
            }).catch(() => {
                resolve(false);
            })
        }).catch(error => {
            getToken().then(token => {
                if (token !== undefined) {
                    const data = {
                        token: token
                    }
                    axios.post(baseUrl + "auth/login/customer/token", data).then(response => {
                        handleLoginSuccess(response).then(() => {
                            resolve(true);
                        }).catch(() => {
                            resolve(false);
                        })
                    })
                } else {
                    resolve(false);
                }
            })
        });
    })
}

export function handleLoginSuccess(response) {
    putToken(response.data.token);
    return handleStatusSuccess();
}

function handleStatusSuccess() {
    return new Promise(resolve => {
        axios.defaults.withCredentials = true;
        axios.get(baseUrl + "auth/user-roles?roleType=STORE").then(response => {
            if (response.data.length === 0) {
                resolve(false);
            } else {
                axios.get(baseUrl + "stores/" + response.data[0].roleTypeIdentifier).then(response => {
                    putLoggedInStore(response.data);
                    resolve(true);
                })
                    .catch(err => {
                        throw new Error(err);
                    });
            }
        })
            .catch(err => {
                throw new Error(err);
            });
    })
}