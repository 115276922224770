import baseUrl from "../../constants";
import axios from 'axios';
import axiosCommons from "./AxiosCommons";
import authFetch from "../../utils/interceptor";

/**
 * All Rest call related to Schedules
 */
const storeScheduleRestService = {
    getStoreSchedule: async (storeId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/schedule`);
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.text();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    updateStoreSchedule: async (storeId, data) => {
        const url = new URL(`${baseUrl}stores/${storeId}/schedule`);
        const options = {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    getDeliveryOptions: async (storeId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/delivery-options`);
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    getWeekSchedule: async (weekScheduleId) => {
        const url = new URL(`${baseUrl}time-schedule/week/${weekScheduleId}`);
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    }
}

export default storeScheduleRestService;