import { TextInput } from '@lifease/lifease-storeui-components'
import { Box } from '@mui/material'
import React from 'react'

const LoginForm = (props) => {
    const { form, handleFormChange, handleClear } = props
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
            <TextInput type={'number'} handleClear={() => { handleClear('mobile') }} name='mobile' label={'Mobile'} value={form.mobile} onChange={handleFormChange} />
            {
                form.verify &&
                <TextInput type={'number'} handleClear={() => { handleClear('otp') }} name='otp' label={'otp'} value={form.otp} onChange={handleFormChange} />
            }
        </Box>
    )
}

export default LoginForm