import React, { useState, createRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Box, Input, FormControl } from '@mui/material';
import conversationRestService from '../../services/rest/conversationsRestService';
import { SecondaryButton } from '@lifease/lifease-storeui-components';

const ImageCropper = ({ setCropData, storeId, getAllFiles, setError, setOpenSnackbar }) => {
  const [image, setImage] = useState(null);
  const [displayName, setDisplayName] = useState('');  
  const cropperRef = createRef();

  const handleFileUpload = async (file) => {
    try {
      const response = await conversationRestService.fileUpload(file, storeId, displayName);
      return response.fileId;
    } catch (error) {
      setError(error.message);
      setOpenSnackbar(true);
    }
  };

  const sendfile = async (cropData) => {
    const imageId = await handleFileUpload(cropData);
    if(imageId){
      await getAllFiles(storeId);
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const onDisplayNameChange = (e) => {
    setDisplayName(e.target.value);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const canvas = cropperRef.current?.cropper.getCroppedCanvas({
        width: 512,
        height: 512,
      });

      const outputCanvas = document.createElement('canvas');
      outputCanvas.width = 512;
      outputCanvas.height = 512;
      const outputContext = outputCanvas.getContext('2d');
      outputContext.fillStyle = 'white';
      outputContext.fillRect(0, 0, 512, 512);

      const offsetX = Math.max((512 - canvas.width) / 2, 0);
      const offsetY = Math.max((512 - canvas.height) / 2, 0);

      outputContext.drawImage(canvas, offsetX, offsetY);

      outputCanvas.toBlob(
        (blob) => {
          const cropData = new File([blob], 'croppedImage.jpg', {
            type: 'image/jpeg',
          });
          setCropData(cropData);
          sendfile(cropData);
        },
        'image/jpeg',
        1.0
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FormControl fullWidth sx={{ mb: 2 }}>
        <input id='file-input' type='file' onChange={onChange} accept='image/*'/>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Input
          value={displayName}
          onChange={onDisplayNameChange}
          placeholder="Enter display name"
          fullWidth
        />
      </FormControl>

      {image && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Cropper
            ref={cropperRef}
            style={{ width: '80%', aspectRatio: 1 }}
            zoomTo={0.5}
            initialAspectRatio={1}
            preview='.img-preview'
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
          />
          <SecondaryButton onClick={getCropData}>Send</SecondaryButton>
        </Box>
      )}
    </Box>
  );
};

export default ImageCropper;
