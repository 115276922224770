import { H2, H4, PrimaryButton, SecondaryButton, UpdateProductCard } from '@lifease/lifease-storeui-components';
import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import productRestService from '../../services/rest/ProductRestService';
import PaginationNextPrevBar from '../add-variants/PaginationNextPrevBar';
import CircularLoading from '../commons/circular-progress/CircularLoading';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { useSearchParams } from 'react-router-dom';
import { useDrawer } from '../../contexts/drawer/DrawerContextProvider';
import { useDialog } from '../../contexts/dialog/DialogContextProvider';
import { getLoggedInStore } from '../../indexeddb/AppContext';
import { DateTimePicker } from '@mui/x-date-pickers';
import ProductModalComponent from '../products/ProductModalComponent';

const ViewAllProductsList = () => {
    const { store } = useStore();
    const { showSnackbar } = useSnackbar();
    const { openDrawer, closeDrawer } = useDrawer();
    const { showDialog, closeDialog } = useDialog();
    const [searchParams, setSearchParams] = useSearchParams();
    const [products, setProducts] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productLoad, setProductLoad] = useState(false);

    const fetchMetrics = async () => {
        try {
            const metricsRes = await productRestService.getMetrics();
            setMetrics(metricsRes);
        } catch { }
    }

    const fetchProductList = async () => {
        try {
            setLoading(true);
            const productResponse = await productRestService.getAllProducts(store.partyId, searchParams.get('available'), searchParams.get('maxProductId'));
            setProducts(productResponse);
        } catch (err) {
            showSnackbar('error', err.message);
        } finally {
            setLoading(false);
        }
    }

    const nextHandler = async () => {
        try {
            const productResponse = await productRestService.getAllProducts(store.partyId, searchParams.get('available'), products.slice(-1)[0]?.id + 1);
            searchParams.set('maxProductId', products.slice(-1)[0]?.id + 1);
            setSearchParams(searchParams);
            setProducts(productResponse);
        } catch (err) {
            showSnackbar('error', err.message);
        }
    }

    const handleConfirmDialog = async (stockTime, product) => {
        const data = {
            isAvailable: false,
            restockTime: stockTime
        };
        setProductLoad(true);
        try {
            const response = await productRestService.setProductAvailability(store.partyId, product.id, data);
            setProducts(prev => (prev.map(item => (item.id !== product.id) ? item : response)));
            closeDialog();
        } catch (err) {
            showSnackbar(err.message);
        } finally {
            setProductLoad(false);
        }
    }

    const handleCloseDialog = (product) => {
        closeDialog();
    }

    const DialogTitle = () => <H2 fontSize={'large'}>Product restock time</H2>

    const DialogComponent = (props) => {
        const { product } = props;
        const [stockTime, setStockTime] = useState(null);
        const handleStockChange = (e) => {
            setStockTime(e.toString());
        }
        return <Box sx={{ display: 'flex', flexDirection: 'column', width: 280, rowGap: 2 }}>
            <H4>Add time when product will be in stock</H4>
            <DateTimePicker onAccept={handleStockChange} label={'Restock time(optional)'} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: 2 }}>
                <PrimaryButton color="secondary" variant='contained' onClick={() => { handleConfirmDialog(stockTime, product) }}>Confirm</PrimaryButton>
                <SecondaryButton color="secondary" variant="contained" onClick={() => { handleCloseDialog(product) }}>Cancel</SecondaryButton>
            </Box>
        </Box>
    }

    const handleOnStockChange = async (product) => {
        if (!product.available) {
            try {
                setProductLoad(true);
                let obj = { isAvailable: !product.available };
                const newProduct = await productRestService.updateProduct(store.partyId, product.id, obj);
                setProducts(prev => (prev.map(item => (item.id !== product.id) ? item : newProduct)));
            } catch (err) {
                showSnackbar('error', err.message);
            } finally {
                setProductLoad(false);
            }
        } else {
            showDialog(() => <DialogTitle />, () => <DialogComponent product={product} />)
        }
    }

    const handleOnButtonClick = async (form, product) => {
        try {
            setProductLoad(true);
            const newProduct = { ...product, ...form };
            const data = await productRestService.updateProduct(store.partyId, product.id, newProduct);
            setProducts(prev => (prev.map(item => (newProduct.id === item.id) ? data : item)));
            closeDrawer();
        } catch (err) {
            showSnackbar('error', err.message);
        } finally { setProductLoad(false); }
    };

    const handleUpdate = (product) => {
        openDrawer(() => <></>, () => <ProductModalComponent product={product} metrics={metrics} store={store} handleOnButtonClick={handleOnButtonClick} type={"UPDATE"} loading={productLoad} />);
    }

    useEffect(() => {
        fetchMetrics();
        fetchProductList();
    }, []);

    return (
        <>
            <Container maxWidth="lg">
                {
                    (metrics.length > 0 || loading) ?
                        <>
                            {
                                <Grid container columnSpacing={2} rowSpacing={2} sx={{ mb: 14, mt: 2 }}>
                                    {
                                        products.map((product) => {
                                            return (
                                                <Grid item xs={12} sm={6} md={4} key={product.id}>
                                                    <UpdateProductCard
                                                        showButton={true}
                                                        product={product}
                                                        metrics={metrics}
                                                        handleStock={() => { handleOnStockChange(product) }}
                                                        handleUpdate={() => { handleUpdate(product) }}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            }
                        </> :
                        <CircularLoading />
                }
            </Container>
            <Box sx={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: 'rgba(0,0,0,0.2)', py: 1, pb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <PrimaryButton disabled={loading || products.length < 20} onClick={nextHandler}>Show More</PrimaryButton>
                </Box>
            </Box>

        </>
    );
}

export default ViewAllProductsList