import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  TextField,
  Box,
  InputAdornment,
  Typography,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Send as SendIcon } from '@mui/icons-material';
import debounce from 'lodash/debounce';
import { useLocation, useNavigate } from 'react-router-dom';
import conversationRestService from '../../services/rest/conversationsRestService';
import BackHeader from '../../components/commons/headers/BackHeader';
import { useDialog } from '../../contexts/dialog/DialogContextProvider';
import featureFlagRestService from '../../services/rest/featureFlagRestService';
import { H1, H2, LogoutIcon } from '@lifease/lifease-storeui-components';
import ImageCropper from './ImageCropper';
import {
  featureName,
  platform,
  service,
} from '../../constants/FeatureFlagConstant';

const ConversationsPage = () => {
  const location = useLocation();
  const params = location.state.orderDetails;
  const [conversationId, setConversationId] = useState(params.conversationId);
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const messagesContainerRef = useRef(null);
  const [cropData, setCropData] = useState(null);
  const [attachmentStatus, setAttachmentStatus] = useState(null);
  const orderId = params.id;
  const customerId = params.fromPartyId;
  const storeId = params.toPartyId;
  const type = params.type || 'ORDER';
  const { showDialog, closeDialog } = useDialog();
  const navigate = useNavigate();

  const fetchConversations = async () => {
    try {
      const res = await conversationRestService.getConversation(
        customerId,
        storeId,
        orderId,
        type
      );
      if (res.length > 0) {
        setConversationId(res[0].id);
      }
      if (res.length === 0) {
        const res = await conversationRestService.initialConversation(
          customerId,
          storeId,
          orderId,
          type
        );
        if (res) {
          setConversationId(res.id);
        }
      }
    } catch (error) {}
  };

  const fetchMessages = async (pageNo) => {
    try {
      const res = await conversationRestService.getAllMessages(
        conversationId,
        customerId,
        pageNo
      );
      setMessages(res);
    } catch (error) {}
  };

  const handleFileSelect = (event) => {
    navigate('/add-attachment', {
      state: { customerId: customerId, conversationId: conversationId },
    });
  };

  const sendMessage = async (imageId) => {
    try {
      const res = await conversationRestService.sendMessage(
        conversationId,
        customerId,
        text,
        imageId
      );
      if (res) {
        setText('');
        setPageNo(1);
        fetchMessages(1);
      }
    } catch (error) {}
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const handleScroll = debounce(async () => {
    const container = messagesContainerRef.current;

    if (container.scrollTop === 0 && !loading && hasMore) {
      setLoading(true);
      try {
        const moreMessages = await fetchMoreMessages();
        if (moreMessages.length > 0) {
          setMessages((prevMessages) => [...prevMessages, ...moreMessages]);
        } else {
          setHasMore(false);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  }, 1000);

  const fetchMoreMessages = async () => {
    const newPageNo = pageNo + 1;
    setPageNo(newPageNo);
    try {
      const res = await conversationRestService.getAllMessages(
        conversationId,
        customerId,
        newPageNo
      );
      return res;
    } catch (error) {
      return [];
    }
  };

  const getFeatureStatus = async () => {
    try {
      const data = {
        featureName: featureName.CUSTOMER_ATTACHMENT,
        service: service.LIFEASE_APP,
        platform: platform.WEB,
        versionCode: process.env.REACT_APP_VERSION,
      };
      const response = await featureFlagRestService.CheckFeatureStatus(data);
      setAttachmentStatus(response.status);
    } catch (error) {}
  };

  useEffect(() => {
    document.title = 'conversation';
    getFeatureStatus();
    if (params.conversationId) return;
    fetchConversations();
  }, []);

  useEffect(() => {
    if (!conversationId) return;
    fetchMessages(pageNo);

    const intervalID = setInterval(() => fetchMessages(pageNo), 5000);
    return () => clearInterval(intervalID);
  }, [conversationId]);

  useEffect(() => {
    const container = messagesContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    closeDialog();
  }, [cropData]);

  return (
    <>
      <BackHeader headText={'Chat with customer'} />
      <Container
        sx={{
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          ref={messagesContainerRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            borderRadius: '8px',
            border: ' 2px solid var(--Neutral-Grey-1, #F1F1F1)',
            background: 'var(--Neutral-White, #FFF)',
            overflowY: 'auto',
            height: '100%',
            padding: '5px',
            marginTop: '10px',
            backgroundImage: `url("home-background-image.png")`,
            backgroundRepeat: 'repeat',
            backgroundSize: 'contain',
          }}
        >
          {messages
            .slice(0)
            .reverse()
            .map((message) => (
              <Box
                key={message.id}
                alignSelf={
                  message.fromPartyId === Number(customerId)
                    ? 'flex-end'
                    : 'flex-start'
                }
                sx={{
                  backgroundColor:
                    message.fromPartyId === Number(customerId)
                      ? 'lightblue'
                      : 'gray',
                  borderRadius: '10px',
                  padding: '5px 10px',
                  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1)',
                  color:
                    message.fromPartyId === Number(customerId)
                      ? 'gray'
                      : 'lightblue',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {message.file && (
                  <img
                    src={message.file.url}
                    alt='Message Attachment'
                    style={{ maxWidth: '50%' }}
                  />
                )}
                {message.text && <H2>{message.text}</H2>}
              </Box>
            ))}
        </div>

        <TextField
          label='Message'
          fullWidth
          margin='normal'
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyPress={handleKeyPress}
          // disabled={!conversationId}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <label htmlFor='raised-button-file'>
                  {attachmentStatus === 'ENABLED' && (
                    <AttachFileIcon
                      sx={{
                        cursor: 'pointer',
                        color: 'orange',
                        marginRight: '8px',
                      }}
                      onClick={handleFileSelect}
                    />
                  )}
                </label>
                <div
                  onClick={() => sendMessage(null)}
                  style={{ cursor: 'pointer' }}
                >
                  <LogoutIcon fill={'orange'} />
                </div>
              </InputAdornment>
            ),
          }}
          sx={{
            position: 'sticky',
            bottom: 0,
            zIndex: 1000,
            backgroundColor: 'white',
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'lightblue',
            },
          }}
        />
      </Container>
    </>
  );
};

export default ConversationsPage;
