import React from 'react'
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { H4, H6 } from '@lifease/lifease-storeui-components';


const DaysSelectionComponent = (props) => {
    const { selectedDays, handleChange, DaysOfWeek } = props;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 1 }}>
            <H4>Which Day of week store is open?</H4>
            <FormGroup onChange={handleChange}>
                {
                    DaysOfWeek.map((day, index) => (
                        <FormControlLabel checked={selectedDays[day].present} name={day} key={day} label={<H4>{day}</H4>} control={<Checkbox color='secondary' />}></FormControlLabel>
                    ))
                }
            </FormGroup>
        </Box>
    )
}

export default DaysSelectionComponent