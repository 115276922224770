import React, { useState } from 'react'
import FilledHeader from '../../components/commons/headers/FilledHeader'
import { Box, Typography } from '@mui/material'
import WeekScheduleComponent from '../../components/commons/week-schedule/WeekScheduleComponent'
import { useSnackbar } from '../../contexts/SnackbarProvider'
import storeScheduleRestService from '../../services/rest/StoreScheduleRestService'
import { useStore } from '../../contexts/store-context/StoreContextProvider'
import { useNavigate } from 'react-router-dom'
import { convertScheduleToScheduleRequest } from '../../components/delivery-options/splitHoursUtil'
import { ScheduleType } from '../../utils/enums'
import { H6 } from '@lifease/lifease-storeui-components'
import CircularLoading from '../../components/commons/circular-progress/CircularLoading'

const StoreTimings = () => {
    const { showSnackbar } = useSnackbar();
    const { store } = useStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleScheduleSubmit = async (data) => {
        setLoading(true);
        const schedule = convertScheduleToScheduleRequest(data, ScheduleType.ABSOLUTE);
        try {
            await storeScheduleRestService.updateStoreSchedule(store.partyId, schedule);
            showSnackbar('success', 'Store timings updated successfully');
            navigate('/onboarding');
        } catch (err) {
            showSnackbar('error', err.message)
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <FilledHeader headText={'Open and close timings'} backUrl={'/onboarding'} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 2 }}>
                <H6 style={{ textAlign: 'center', marginTop: '24px', marginBottom: '24px' }}>
                    Define weekly open and close time of the store.
                    This helps customer know if the store is open or not.
                </H6>
                <WeekScheduleComponent handleScheduleSubmit={handleScheduleSubmit} ScheduleType={ScheduleType.ABSOLUTE} />
            </Box>
            {
                loading && <CircularLoading />
            }
        </>
    )
}

export default StoreTimings