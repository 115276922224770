import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import LogisticsComponent from './LogisticsComponent';

const DeliveryPartnerModal = ({
  open,
  setOpen,
  quoteDataList,
  shipmentId,
  order,
  setBookingDetails,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEstimatedCost, setSelectedEstimatedCost] = useState(0);
  const [selectedLogisticId, setSelectedLogisticId] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (estimatedCost, logisticId) => {
    setSelectedEstimatedCost(estimatedCost);
    setSelectedLogisticId(logisticId);
    setOpenModal(true);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6'>Select Delivery Partner</Typography>
          <IconButton
            edge='end'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <List>
        {quoteDataList?.map((quote, quoteIndex) =>
          quote.riderInfoData
            .filter((rider) => rider.vehicleType === 'TWO_WHEELER')
            .map((rider, riderIndex) => (
              <ListItem
                button
                key={`${quoteIndex}-${riderIndex}`}
                onClick={() =>
                  handleListItemClick(rider.estimatedCost, quote.logisticsId)
                }
                sx={{
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                  justifyContent: 'space-between',
                  paddingX: 2,
                }}
              >
                <Typography variant='body1' component='span'>
                  {quote.displayText}
                </Typography>
                <Typography
                  variant='body1'
                  component='span'
                  sx={{ fontWeight: 'bold' }}
                >
                  Est. Fare: ₹{rider.estimatedCost}
                </Typography>
              </ListItem>
            ))
        )}
      </List>
      {openModal && (
        <LogisticsComponent
          open={openModal}
          setOpen={setOpenModal}
          estimatedCost={selectedEstimatedCost}
          shipmentId={shipmentId}
          order={order}
          setBookingDetails={setBookingDetails}
          logisticId={selectedLogisticId}
          setOpenModal={setOpen}
        />
      )}
    </Dialog>
  );
};

export default DeliveryPartnerModal;
