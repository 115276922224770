import React, { useContext, useEffect, useState } from 'react'
import StoreContext from './StoreContext';
import { getLoggedInStore, getToken } from '../../indexeddb/AppContext';
import axios from 'axios';
import baseUrl from "../../constants";

const StoreContextProvider = (props) => {
    const [store, setStore] = useState(null);
    const [storeLoaded, setStoreLoaded] = useState(false);
    useEffect(() => {
        getLoggedInStore().then(async res => {
            if (res) {
                const token = await getToken();
                if (!token) {
                    throw new Error('User not logged in');
                }
                const tokenUrl = new URL(`${baseUrl}auth/login/customer/token`);
                const data = { token };
                axios.defaults.withCredentials = true;
                try {
                    // Todo: convert to Fetch, reason: giving cors error
                    await axios.post(tokenUrl, data);
                } catch (err) {
                }
                setStore(res);
            }
            setStoreLoaded(true);
        })
    }, []);
    return (
        <StoreContext.Provider value={{ store, storeLoaded, setStore }}>
            {
                props.children
            }
        </StoreContext.Provider>
    )
}

export const useStore = () => useContext(StoreContext);

export default StoreContextProvider;