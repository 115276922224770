import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { NoProductCategoryCard, ProductAddCategoryCard } from '@lifease/lifease-storeui-components';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import productCategoryRestService from '../../services/rest/ProductCategoryRestService';
import CircularLoading from '../commons/circular-progress/CircularLoading';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { useNavigate } from 'react-router-dom';
import productRestService from '../../services/rest/ProductRestService';

function ProductCategoriesGrid() {
    const [productCategories, setProductCategories] = useState({ categories: [] });
    const { store } = useStore();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const handleRemoveCategory = async (category) => {
        setIsLoading(true);
        try {
            await productCategoryRestService.deleteCategory(store.partyId, category.id);
            await fetchProductCategoriesForStore();
        } catch (err) {
            showSnackbar('error', err.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleAddProduct = (category) => {
        navigate(`/add-products/${category.id}?categoryName=${category.categoryName}`);
    };

    const handleUpdate = (category) => {
        navigate(`/view-products/${category.id}?categoryName=${category.categoryName}`);
    };

    useEffect(() => {
        fetchProductCategoriesForStore();
    }, []);

    return (
        <>
            {
                !isLoading ?
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        {
                            productCategories?.categories?.map((productCategory, idx) => {
                                return <Grid item key={productCategory.id} xs={12} sm={6} md={4}>
                                    <ProductAddCategoryCard
                                        produdctCategoryId={productCategory.id}
                                        storeId={store.partyId}
                                        key={productCategory.id}
                                        category={productCategory}
                                        handleAddMore={() => { handleAddProduct(productCategory) }}
                                        handleUpdate={() => { handleUpdate(productCategory) }}
                                        handleRemoveCategory={() => { handleRemoveCategory(productCategory) }}
                                    />
                                </Grid>
                            })
                        }
                    </Grid> :
                    <CircularLoading />
            }
        </>
    );
    async function fetchProductCategoriesForStore() {
        setIsLoading(true);
        try {
            let obj = {};
            const newCategories = await productCategoryRestService.getProductCategories(store?.partyId);
            obj.categories = newCategories;
            const metrics = await productRestService.getMetrics();
            obj.metrics = metrics;
            setProductCategories(obj);
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    }
}

export default ProductCategoriesGrid;
