import { FilterIcon, H2, H4, H6, PrimaryButton, SecondaryButton, SortIcon } from '@lifease/lifease-storeui-components'
import { Box, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { useDialog } from '../../contexts/dialog/DialogContextProvider';

const OrderFilters = {
    "CREATED": false,
    "ACCEPTED": false,
    "PACKED": false,
    "SHIPPED": false,
    "DELIVERED": false,
    "CANCELLED": false
}

const FilterList = [
    'CREATED',
    'ACCEPTED',
    'PACKED',
    'SHIPPED',
    'DELIVERED',
    'CANCELLED'
];

const Filters = (props) => {
    const { modalClosed, handleSort } = props;
    const { showDialog, closeDialog } = useDialog();
    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState(OrderFilters);
    const [shipBefore, setShipBefore] = useState(null)
    const ListOfAllFilters = (props) => {
        const { filters } = props;
        const [currentFilters, setCurrentFilters] = useState(filters);
        const handleChange = (e) => {
            setCurrentFilters(prev => ({ ...prev, [e.target.name]: e.target.checked }));
        }
        const handleClick = () => {
            let allFilters = "";
            let flag = true;
            for (const filter in currentFilters) {
                if (currentFilters[filter]) {
                    if (flag) {
                        allFilters += filter;
                        flag = false;
                    } else {
                        allFilters += `,${filter}`;
                    }
                }
            }
            searchParams.set('filter', allFilters);
            setSearchParams(searchParams);
            modalClosed(allFilters);
            closeDialog();

        }
        return <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormGroup onChange={handleChange}>
                <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5, mx: 'auto' }}>
                    {
                        FilterList.map((filter, index) => {
                            return <FormControlLabel
                                key={index}
                                name={filter}

                                label={<H4>{filter}</H4>}
                                control={<Checkbox color='secondary' checked={currentFilters[filter]} />}
                            ></FormControlLabel>;
                        })
                    }
                </Box>
            </FormGroup>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                <SecondaryButton onClick={() => { closeDialog(); }} >Cancel</SecondaryButton>
                <PrimaryButton onClick={handleClick}>Confirm</PrimaryButton>
            </Box>
        </Box>
    };

    const handleFilter = () => {
        showDialog(() => <H2 style={{ display: 'flex', justifyContent: 'center' }}>Order Filters</H2>, () => <ListOfAllFilters filters={filters} />);
    }

    const handleParamCheck = () => {
        const params = searchParams.get('filter');
        const filterArr = params.split(",");
        let obj = { ...OrderFilters };
        for (const filter of filterArr) {
            obj[filter] = true;
        }
        setShipBefore(searchParams.get('shipBefore'));
        setFilters(obj);
    }

    useEffect(() => {
        handleParamCheck();
    }, [searchParams.get('filter')]);

    return (
        <Box sx={{ position: 'sticky', top: 60, backgroundColor: 'white', zIndex: 5, boxShadow: '0px 4px 4px rgba(0,0,0,0.2)', p: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <H2>Shipments</H2>
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                    <Box onClick={handleSort} sx={{ border: '1px solid rgba(0,0,0,0.3)', py: 0.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '6px', columnGap: 0.5, px: 1, cursor: 'pointer' }}>
                        <H6>Sort</H6>
                        <SortIcon height={'16px'} width={'16px'} fill='orange' />
                    </Box>
                    <Box onClick={handleFilter} sx={{ border: '1px solid rgba(0,0,0,0.3)', py: 0.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '6px', columnGap: 0.5, px: 1, cursor: 'pointer' }}>
                        <H6>Filters</H6>
                        <FilterIcon height={'16px'} width={'16px'} fill='orange' />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Filters