import React, { useState } from 'react'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Box, IconButton } from '@mui/material';
import { useDialog } from '../../../contexts/dialog/DialogContextProvider';
import { H6, PrimaryButton } from '@lifease/lifease-storeui-components';
import helpRestService from '../../../services/rest/HelpRestService';
import { useStore } from '../../../contexts/store-context/StoreContextProvider';
import { useSnackbar } from '../../../contexts/SnackbarProvider';

const HelpSupport = () => {
    const { store } = useStore();
    const { showDialog, closeDialog } = useDialog();
    const { showSnackbar } = useSnackbar();
    const handleSubmit = async (form) => {
        closeDialog();
        try {
            await helpRestService.postHelp(store.partyId, { ...form });
            showSnackbar('success', "Successfully raised ticket");
        } catch (err) {
            showSnackbar('error', err.message);
        }
    }

    const SupportContent = ({ handleSubmit, orderId = 0 }) => {
        const [form, setForm] = useState({ message: '', orderId: orderId, grievanceType: "FEEDBACK" });
        const handleChange = (e) => {
            setForm(prev => ({ ...prev, [e.target.name]: e.target.value, posted: false }))
        }
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, mt: 2, px: 2 }}>
                <H6>Tell us what you need support with</H6>
                <textarea value={form.message} onChange={handleChange} placeholder='type here...' name="message" style={{ border: '1px solid rgba(0,0,0,0.1)', minHeight: '120px', padding: '4px 8px' }} />
                <PrimaryButton disabled={form.message.length === 0} onClick={() => { handleSubmit(form) }}>Raise a ticket</PrimaryButton>
                <H6 style={{ marginTop: "-8px" }}>We will get back to you within 24 hours of raising ticket</H6>
            </Box>
        )
    }

    const handleHelpSupportModal = () => {
        showDialog(() => <>Help & Support</>, () => <SupportContent handleSubmit={handleSubmit} />)
    }

    return (
        <IconButton onClick={handleHelpSupportModal} sx={{ position: 'absolute', top: 10, right: 20, boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset', zIndex: 10 }}>
            <SupportAgentIcon />
        </IconButton>
    )
}

export default HelpSupport