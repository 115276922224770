import { PrimaryButton, TextInput } from '@lifease/lifease-storeui-components';
import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

const AddressForm = (props) => {
    const { handleConfirmAddress, address } = props;
    const [form, setForm] = useState({ zip: address.zip, house: '' });
    const onChange = (e) => {
        setForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleClear = (name) => {
        setForm(prev => ({ ...prev, [name]: '' }));
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, px: 3, mt: 6 }}>
            <TextInput handleClear={() => { handleClear('house') }} onChange={onChange} value={form.house} name={'house'} required label={'House / Building Number'} />
            <TextInput type={'number'} handleClear={() => { handleClear('zip') }} onChange={onChange} value={form.zip} name={'zip'} required label={'Pincode'} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <PrimaryButton disabled={form.zip <= 0} onClick={() => { handleConfirmAddress(form) }}>Confirm Address</PrimaryButton>
            </Box>
        </Box>
    )
}

export default AddressForm