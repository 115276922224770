import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import React from "react";

function CustomerCard(props) {
    const {customer} = props;
    if (customer === undefined) {
        return <></>
    } else {
        return(
            <Card sx={{mb:1, px:1}}>
                <CardActions>
                    Customer contact:
                    <Button startIcon={<PhoneAndroidIcon />} variant='text' color='secondary' size="large" onClick={() => window.location.href = 'tel://'+customer.mobile}>{customer.mobile}</Button>
                </CardActions>
            </Card>
        );
    }

}

export default CustomerCard;