import { Alert, Button, Container, TextField } from "@mui/material";
import axios from "axios";
import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import baseUrl from "../../constants";

function RegisterRequest(props) {
    const [alertMessage, setAlertMessage] = useState("");
    const [inputs, setInputs] = useState({});
    const navigate = useNavigate();

    useEffect(()=>{
        document.title = 'register request';
    },[])
    
    return (
        <Container direction="column" maxWidth="md" sx={{ padding: 1 }}>
            <TextField sx={{ mt: 1 }} id="name-text-id" fullWidth
                label="Name" variant="outlined" onChange={(event) => handleNameChange(event.currentTarget.value)} spacing={2} />
            <TextField sx={{ mt: 1 }} id="outlined-basic" fullWidth
                label="Store Name" variant="outlined" onChange={(event) => handleStoreNameChange(event.currentTarget.value)} spacing={2} />
            <TextField sx={{ mt: 1 }} type="number" inputProps={{ inputMode: 'numeric' }} id="outlined-basic" fullWidth
                label="Store mobile number" variant="outlined" onChange={(event) => handleMobileChange(event.currentTarget.value)} spacing={2} />
            <TextField sx={{ mt: 1 }} id="outlined-basic" fullWidth
                label="Pincode" variant="outlined" onChange={(event) => handlePincodeChange(event.currentTarget.value)} spacing={2} />
            <TextField sx={{ mt: 1 }} id="outlined-basic" fullWidth
                label="Store address" multiline rows={3} variant="outlined" onChange={(event) => handleAddressChange(event.currentTarget.value)} spacing={2} />
            {alertMessage !== "" ? <Alert sx={{ mt: 1 }} severity='warning'>{alertMessage}</Alert> : <></>}
            <Button fullWidth sx={{ mt: 1 }} color="secondary" variant="contained" onClick={handleSubmit}>Submit</Button>
        </Container>
    );

    function handleNameChange(value) {
        setInputs({
            ...inputs,
            name: value
        });
    }

    function handleStoreNameChange(value) {
        setInputs({
            ...inputs,
            storeName: value
        });
    }

    function handleMobileChange(value) {
        setInputs({
            ...inputs,
            mobile: value
        });
    }

    function handlePincodeChange(value) {
        setInputs({
            ...inputs,
            pincode: value
        });
    }

    function handleAddressChange(value) {
        setInputs({
            ...inputs,
            address: value
        });
    }

    function handleSubmit() {
        axios.defaults.withCredentials = true;
        axios.post(baseUrl + "register-requests", inputs).then(response => {
            setAlertMessage("");
            navigate("/self-register-success");
        })
            .catch((error) => {
                setAlertMessage(error.response.data.message);
            })
    }

}

export default RegisterRequest;