import { Alert, Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import productRestService from '../../services/rest/ProductRestService';
import ProductMasterComponent from '../../components/add-variants/ProductMasterComponent';
import PaginationNextPrevBar from '../../components/add-variants/PaginationNextPrevBar';
import SearchProduct from '../../components/product-category/SearchProduct';
import BackHeader from '../../components/commons/headers/BackHeader';

const AddVariantsList = (props) => {
    const { state } = useLocation();
    const { id } = useParams();
    const [products, setProducts] = useState({ loading: true, data: [], minProductId: null, hasProduct: true });
    const [loading, setLoading] = useState(true);
    const { ref: progressRef, inView } = useInView();
    const [searchParams, setSearchParams] = useSearchParams();
    const minProductId = searchParams.get('minProductId');
    const [metrics, setMetrics] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        fetchProducts();
        getMetrics();
    }, [minProductId]);
    const getVariant = (product, imageUrl) => {
        navigate(`${product.id}`, { state: { imageUrl, product, scrollY: window.scrollY, minProductId } });
    }
    useEffect(() => {
        document.title = 'add variant';
    }, []);

    const prevHandler = () => {
        navigate(-1);
    }

    const nextHandler = () => {
        searchParams.set('minProductId', products.minProductId);
        setSearchParams(searchParams)
    }

    return (
        <>
            <BackHeader headText={'Add variants'} />
            <Box sx={{ ...{ width: { xs: '100%', sm: '90%', md: '70%' }, mx: 'auto', p: 1, mb: 14 } }}>
                {
                    metrics &&
                    <SearchProduct productCategories={[{ id: id }]} metrics={metrics} />
                }
                {
                    loading ?
                        <Typography color={'primary'} sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>Loading products please wait</Typography> :
                        <>
                            {
                                !products.loading &&
                                <PaginationNextPrevBar products={products} minProductId={minProductId} prevHandler={prevHandler} nextHandler={nextHandler} />
                            }
                            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ mb: 8 }}>
                                {
                                    products.data.map((product) => (
                                        <Grid item xs={4} sm={4} md={4} key={product.id} p={1}>
                                            <ProductMasterComponent product={product} getVariant={getVariant} />
                                        </Grid>
                                    ))
                                }
                                {
                                    products.data.length === 0 && !products.loading &&
                                    <Alert sx={{ width: '100%' }} severity='info'>No more products</Alert>
                                }
                                {/* {
                                !products.loading && products.hasProduct &&
                                <Box ref={progressRef} sx={{ display: 'flex', width: '100%', justifyContent: 'center' }} >
                                <CircularProgress size={25} />
                                </Box>
                            } */}
                            </Grid>
                        </>
                }
            </Box>
        </>
    );

    async function fetchProducts() {
        try {
            setLoading(true);
            const res = await productRestService.fetchProductMasters(id, minProductId);
            setProducts(prevProducts => ({
                ...prevProducts,
                loading: false,
                hasProduct: res.length !== 0 ? true : false,
                data: res,
                minProductId: res[res.length - 1]?.id - 1
            }));
            setTimeout(() => {
                window.scrollTo({ top: state?.scroll ?? 0, behavior: 'smooth' });
            }, 1000);
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }
    async function getMetrics() {
        try {
            const metrics = await productRestService.getMetrics();
            setMetrics(metrics);
            return metrics;
        } catch {

        }
    }
}

export default AddVariantsList;