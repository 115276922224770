import { Alert, Avatar, Box, Button, Container, CssBaseline, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBackdrop from "../../common/ProgressBackdropComponent";
import { getFirebaseToken, getLoggedInStore } from "../../indexeddb/AppContext";
import register from "../../capacitor/PushNotificationsContainer";
import loginRestService from "../../services/rest/LoginRestService";
import TimerComponent from "../../components/login/TimerComponent";
import { handleLoginSuccess } from "../../components/login/RefreshLogin";
import { setReactGA } from '../../utils/googleAnalytics';
import LoginForm from "../../components/commons/login-form/LoginForm";
import VerifySendToggleButtons from "../../components/commons/login-form/VerifySendToggleButtons";
import FilledHeader from "../../components/commons/headers/FilledHeader";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import requestPermission from '../../firebase/firebase';

const initialLoginState = {
    phone: '',
    isPhoneValidated: false,
    phoneValidationMessage: '',
    otp: '',
    isOtpValidated: false,
    optValidationMessage: null,
    loading: false
};


function StoreLogin() {

    const { showSnackbar } = useSnackbar();

    const { setStore } = useStore();

    const [loginForm, setLoginForm] = useState(initialLoginState);
    const navigate = useNavigate();
    const [err, setError] = useState("");
    useEffect(() => {
        document.title = "Store Login";
        register();
        requestPermission();
    }, []);

    const ResetPhone = () => {
        const formReset = () => {
            setLoginForm(prevForm => ({
                ...prevForm,
                isPhoneValidated: false,
                phone: '',
                otp: '',
                optValidationMessage: '',
                isOtpValidated: false,
                phoneValidationMessage: '',
                loading: false
            }))
        }

        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography color='GrayText' onClick={formReset} sx={{ cursor: 'pointer' }}>clear number</Typography>
                <TimerComponent timerFunction={() => { handlePhoneSubmit(loginForm.phone) }} />
            </Box>
        )
    }

    const helperText = "We will never call or share your number";

    const handleSubmit = async (e) => {
        setError('');
        e.preventDefault();
        setLoginForm(prevForm => ({
            ...prevForm,
            loading: true
        }));
        if (loginForm.otp.length === 4) {
            try {
                const firebaseToken = await getFirebaseToken();
                const res = await loginRestService.verifyOtp(loginForm, firebaseToken);
                const status = await handleLoginSuccess({ data: res });
                const store = await loginRestService.customerApi();
                setReactGA('userId', store.partyId);
                setLoginForm(prevForm => ({
                    ...prevForm,
                    isOtpValidated: true,
                    optValidationMessage: null,
                    loading: false
                }));
                const userMessage = !status ? 'Looks like you dont have access to any stores' : ''
                const savedStore = await getLoggedInStore();
                if (savedStore) {
                    setStore(savedStore);
                    if (savedStore.status === 'ONBOARDING') {
                        navigate('/onboarding');
                    } else {
                        navigateHome(userMessage);
                    }
                }

            } catch (err) {
                setError(err);
                setLoginForm(prevForm => ({
                    ...prevForm,
                    isOtpValidated: false,
                    optValidationMessage: err,
                    loading: false
                }));
            } finally {
                setLoginForm(prevForm => ({
                    ...prevForm,
                    loading: false
                }));
            }
        }
    }

    const handleChange = (e) => {
        setLoginForm(prevLogin => ({
            ...prevLogin,
            [e.target.name]: e.target.value
        }));
    }

    const sendOtpClick = () => {
        handlePhoneSubmit(loginForm.phone);
        setLoginForm(prevForm => ({
            ...prevForm,
            isPhoneValidated: true
        }));
    }

    return (
        <>
            <Container direction="column" maxWidth="md" sx={{ padding: 1 }}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, width: 56, height: 56 }} src='/favicon.ico' />
                        <Typography component="h1" variant="h5" color='primary'>
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="phoneNumber"
                                label="Phone Number"
                                name="phone"
                                type="number"
                                autoComplete="phone"
                                autoFocus
                                helperText={helperText}
                                value={loginForm.phone}
                                onChange={handleChange}
                                disabled={loginForm.isPhoneValidated}
                            />
                            {
                                loginForm.isPhoneValidated &&
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="otp"
                                    label="OTP"
                                    type="number"
                                    id="otp"
                                    autoComplete="otp"
                                    onChange={handleChange}
                                />
                            }
                            {
                                err.length > 0 &&
                                <Alert severity="error">{err}</Alert>
                            }
                            {
                                !loginForm.isPhoneValidated &&
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={loginForm.phone.length !== 10}
                                    onClick={sendOtpClick}
                                >
                                    Send OTP
                                </Button>
                            }
                            {
                                loginForm.isPhoneValidated &&
                                <>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        disabled={loginForm.otp.length !== 4 || loginForm.loading}
                                    >
                                        Verify OTP
                                    </Button>
                                    <ResetPhone />
                                </>
                            }
                        </Box>
                    </Box>
                </Container>
                <ProgressBackdrop progress={false}></ProgressBackdrop>
            </Container>
        </>
    );


    function navigateHome(message) {
        navigate("/", { replace: true, state: { loginMessage: message } });
    }

    async function handlePhoneSubmit(phoneNumber) {
        try {
            await loginRestService.setRegisteredNumber(phoneNumber);
        } catch (err) {
            setError(err);
        } finally {
            setLoginForm(prevForm => ({
                ...prevForm,
                loading: false
            }));
        }
    }
}

export default StoreLogin;