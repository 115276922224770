import baseUrl from '../../constants';
import authFetch from '../../utils/interceptor';


const featureFlagRestService = {
  CheckFeatureStatus: async (data) => {
    console.log(data);
    const url = new URL(`${baseUrl}config/feature-status`);
    const options = {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await authFetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    throw new Error((await response.json()).message);
  },
};

export default featureFlagRestService;
