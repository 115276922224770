import React from "react";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import { OrderStatus } from "../../utils/enums";

function ViewOrdersFilterComponent(props) {
    const orderStates = OrderStatus;
    const {orderState, setOrderState, fetchOrders} = props
    return (
        <Box>
        <Grid container direction="row" pb={2}>
            <TextField id="outlined-order-state" select label="Order Status" value={orderState} size="small">
                {orderStates.map((orderState) => (
                    <MenuItem key={orderState} value={orderState} onClick={() => setOrderState(orderState)}>
                    {orderState}
                    </MenuItem>
                ))}
            </TextField>
            <Button variant="contained" size="small" sx={{ml:2}} onClick={() => fetchOrders()} color="secondary">Refresh</Button>
        </Grid>
        </Box>
    );
}

export default ViewOrdersFilterComponent;