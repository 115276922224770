import { Tab, Tabs } from '@mui/material'
import React from 'react'

const DeliveryScheduleTabs = (props) => {
    const { option, setOption } = props;
    const handler = (event, newValue) => {
        setOption(newValue);
    }
    return (
        <Tabs
            sx={{ px: 1 }}
            value={option}
            onChange={handler}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="Delivery option tabs"
        >
            <Tab value="ABSOLUTE" label="Normal" />
            <Tab value="RELATIVE" label="Express" />
        </Tabs>
    )
}

export default DeliveryScheduleTabs