import { H2, H6, ImageContainer, ProductCard } from '@lifease/lifease-storeui-components'
import { Box, Divider, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProductFormComponent from './ProductFormComponent';
import productRestService from '../../services/rest/ProductRestService';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import VariantCard from './VariantCard';

const ProductModalComponent = (props) => {
    const { product, metrics, handleOnButtonClick, store, type = "ADD", loading = false } = props;
    const [variants, setVariants] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(product);
    const [methodType, setMethodType] = useState(type);

    const handleVariantClick = (product) => {
        setMethodType("UPDATE");
        setSelectedProduct(product);
        document.getElementById("modal-product").scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        let id = (type === "UPDATE") ? selectedProduct.productMasterId : selectedProduct.id;
        productRestService.fetchProductVariants(store.partyId, id).then(res => {
            setVariants(res);
        }).catch((err) => { });
    }, [selectedProduct]);

    return (
        <Box sx={{ mb: 4, px: 2, mt: 1, overflow: 'scroll', flexWrap: 'wrap', display: 'flex' }} id="modal-product">
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, width: { xs: '100%', md: '50%' } }}>
                <Box sx={{ display: 'flex', columnGap: 2 }}>
                    <ImageContainer imageId={selectedProduct.imageId} className='w-[100px] h-[100px] rounded-lg' />
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5 }}>
                        <H2 className='text-[charcoalGray]'>{selectedProduct.productName}</H2>
                        <H6 style={{ color: 'gray' }}>{selectedProduct.brand}</H6>
                    </Box>
                </Box>
                <ProductFormComponent type={methodType} handleOnButtonClick={handleOnButtonClick} metrics={metrics} product={selectedProduct} loading={loading} />
            </Box>
            {
                variants.length > 0 &&
                <Box sx={{ width: { xs: '100%', md: '50%' }, mt: 2, mb: 2 }}>
                    <H2>Variants Added</H2>
                    <Divider orientation='horizontal' sx={{ my: 0.5, color: 'gray' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, mt: 2 }}>
                        {
                            variants.map((variant, idx) => {
                                return <VariantCard type={methodType} handleVariantClick={handleVariantClick} key={idx} product={variant} metrics={metrics} />
                            })
                        }
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default ProductModalComponent