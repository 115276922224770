export const featureName = {
    CUSTOMER_ATTACHMENT: 'CUSTOMER_ATTACHMENT'
}

export const service = {
    LIFEASE_APP: 'LIFEASE_APP'
}

export const platform = {
    WEB: 'WEB'
}
