import baseUrl from '../../constants';
import authFetch from '../../utils/interceptor';

const referralCodeRestService = {
  getReferral: async (id) => {
    const url = `${baseUrl}stores/${id}/referral-code`;
    const options = {
      method: 'POST',
      credentials: 'include',
    };
    try {
      const res = await authFetch(url, options);
      return await res.json();
    } catch (err) {
      throw new Error(err.message);
    }
  },
};

export default referralCodeRestService;
