import { H1, H6 } from '@lifease/lifease-storeui-components';
import { Box, TextField } from '@mui/material';
import React, { useState } from 'react';

const ReferralCodeForm = (props) => {
  const { registerForm, handleOnChange } = props;
  const [hasReferralCode, setHasReferralCode] = useState(false);
  const handeChange = () => {
    setHasReferralCode(!hasReferralCode);
  };
  return (
    <Box>
      {hasReferralCode ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <TextField
            type='text'
            onChange={handleOnChange}
            name='referralCode'
            value={registerForm.referralCode}
            required
            size='small'
            label='Referral Code'
            variant='standard'
            fullWidth
          />
          <H6 onClick={handeChange} style={{ color: 'red' }}>
            No referral Code ?.
          </H6>
        </Box>
      ) : (
        <H6 onClick={handeChange} style={{ color: 'gray' }}>
          Have referral Code ?
        </H6>
      )}
    </Box>
  );
};

export default ReferralCodeForm;
