import React, { useContext, useState } from 'react'
import DrawerContext from './DrawerContext'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Box } from '@mui/material';
import { Global } from '@emotion/react';
import CancelIcon from '@mui/icons-material/Cancel';

const DrawerSize = 36;

const DrawerContextProvider = (props) => {
    const [drawer, setDrawer] = useState({ open: false, header: () => <></>, content: () => <></> });
    const closeDrawer = () => { setDrawer({ open: false, header: () => <></>, content: () => <></> }) };
    const openDrawer = (Header, Content) => {
        setDrawer({ open: true, header: () => <Header />, content: () => <Content id={'drawer-context'} /> })
    }
    return (
        <DrawerContext.Provider value={{ openDrawer, closeDrawer }}>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        overflow: 'visible',
                        maxHeight: '80%'
                    },
                }}
            />
            <SwipeableDrawer
                transitionDuration={400}
                anchor='bottom'
                open={drawer.open}
                onOpen={openDrawer}
                onClose={closeDrawer}
            >
                <Box
                    position={'absolute'}
                    sx={{ width: '100%', backgroundColor: 'white', borderTopLeftRadius: 20, borderTopRightRadius: 20, pb: 2, px: 1, py: 1, display: drawer.open ? '' : 'none' }}
                    top={-DrawerSize}
                >
                    <CancelIcon onClick={closeDrawer} sx={{ float: 'right', color: 'red' }} />
                    {drawer.header()}
                </Box>
                {drawer.content()}
            </SwipeableDrawer>
            {props.children}
        </DrawerContext.Provider>
    )
}

export const useDrawer = () => useContext(DrawerContext);

export default DrawerContextProvider