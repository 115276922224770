import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@lifease/lifease-storeui-components/dist/cjs/index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import ViewDeliveryOptions from './pages/delivery-option/ViewDeliveryOptionsComponent';
import AddDeliveryOption from './pages/delivery-option/AddDeliveryOptionComponent';
import ViewOrderDetails from './pages/orders/ViewOrderDetailsComponent';
import ViewProductCategories from './pages/product-category/ViewProductCategoriesComponent';
import AddProductCategory from './pages/product-category/AddProductCategoryComponent';
import AddProducts from './pages/products/AddProductsComponent';
import ViewProducts from './pages/products/ViewProductsComponent';
import ViewOrders from './pages/orders/ViewOrdersComponent';
import RegisterRequest from './pages/register/RegisterRequestComponent';
import RegisterRequestSuccess from './pages/register/RegisterRequestSuccessComponent';
import StoreLogin from './pages/login/StoreLoginComponent';
import ManageUsers from './pages/users/ManageUsers';
import { ThemeProvider } from '@emotion/react';
import storeTheme from './common/StoreTheme';
import AddVariantsList from './pages/add-variants/AddVariants';
import VariantComponent from './components/add-variants/VariantComponent';
import { initializeReactGA } from './utils/googleAnalytics';
import StoreSettings from './pages/store-settings/StoreSettings';
import StoreSchedule from './pages/store-schedule/StoreSchedule';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import DeliverySchedule from './pages/delivery-schedule/DeliverySchedule';
import AccountSettings from './pages/account-settings/AccountSettings';
import StoreCatalogue from './pages/StoreCatalogue/StoreCatalogue';
import StatusList from './pages/catalogue-status/StatusList';
import { StoreScheduleProvider } from './services/context/StoreScheduleContext';
import EditTimeSlots from './common/EditTimeSlots';
import AddDeliverySchedule from './components/add-delivery-schedule/AddDeliverySchedule';
import NewRelicComponent from './common/NewRelicComponent';
import SnackbarProvider from './contexts/SnackbarProvider';
import AdminPage from './pages/admin-page/AdminPage';
import ProductDetailsHideProvider from './contexts/product-details-context/ProductDetailsHideProvider';
import DialogContextProvider from './contexts/dialog/DialogContextProvider';
import BillingComponent from './pages/billing/BillingComponent';
import ServicesComponent from './pages/services/ServicesComponent';
import ConfirmPayment from './pages/confirm-payment/ConfirmPayment';
import ConversationsPage from './pages/conversation/ConversationsPage';
import LoginRegister from './pages/login-register/LoginRegister';
import RegisterStore from './pages/register-store/RegisterStore';
import StoreContextProvider from './contexts/store-context/StoreContextProvider';
import CheckLoggedInStatus from './components/commons/check-login-status/CheckLoggedInStatus';
import Onboarding from './pages/onboarding/Onboarding';
import StoreAddress from './pages/add-address/StoreAddress';
import StoreTimings from './pages/store-timings/StoreTimings';
import DeliveryOptions from './pages/delivery-options/DeliveryOptions';
import NavbarContextProvider from './contexts/navbar/NavbarContextProvider';
import Navbar from './components/commons/navbar/Navbar';
import OrderStats from './pages/order-stats/OrderStats';
import MoreOptions from './pages/more/MoreOptions';
import { setBaseUrl } from '@lifease/lifease-storeui-components';
import ProductStats from './pages/product-stats/ProductStats';
import DrawerContextProvider from './contexts/drawer/DrawerContextProvider';
import SearchComponent from './pages/search/SearchComponent';
import ViewAllProducts from './pages/view-all-products/ViewAllProducts';
import ManageChannels from './pages/manage-channels/ManageChannels';
import ManageReferrals from './pages/manage-referrals/ManageReferrals';
import StoreConversation from './pages/store-conversation/StoreConversation';
import AddAttachment from './pages/add-attachment/AddAttachment';
import AddUserPage from './pages/users/AddUserPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
initializeReactGA(process.env.REACT_APP_ANALYTICS_KEY);
setBaseUrl(process.env.REACT_APP_API_BASE_URL);
root.render(
  <BrowserRouter>
    <ThemeProvider theme={storeTheme}>
      <StoreScheduleProvider>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DialogContextProvider>
              <DrawerContextProvider>
                <StoreContextProvider>
                  <ProductDetailsHideProvider>
                    <NewRelicComponent />
                    <NavbarContextProvider>
                      <DialogContextProvider>
                        <Routes>
                          <Route element={<CheckLoggedInStatus />}>
                            {/* <Route element={<ResponsiveDrawer />}> */}
                            <Route element={<Navbar />}>
                              <Route path='/' element={<OrderStats />} />
                              <Route
                                path='/view-orders'
                                element={<ViewOrders />}
                              />
                              <Route
                                path='add-delivery-option/:deliveryOptionId'
                                element={<AddDeliveryOption />}
                              />
                              <Route
                                path='order-details/:orderId'
                                element={<ViewOrderDetails />}
                              />
                              <Route
                                path='/view-product-categories'
                                element={<ViewProductCategories />}
                              />
                              <Route
                                path='view-product-categories/store-catalogue'
                                element={<StoreCatalogue />}
                              />
                              <Route
                                path='view-product-categories/status'
                                element={<StatusList />}
                              />
                              <Route
                                path='/add-product-category'
                                element={<AddProductCategory />}
                              />
                              <Route
                                path='/add-products/:categoryId'
                                element={<AddProducts />}
                              />
                              <Route
                                path='/view-products/:categoryId'
                                element={<ViewProducts />}
                              />
                              <Route
                                path='self-register'
                                element={<RegisterRequest />}
                              />
                              <Route
                                path='self-register-success'
                                element={<RegisterRequestSuccess />}
                              />
                              <Route
                                path='manage-users'
                                element={<ManageUsers />}
                              />
                              <Route
                                path='add-user'
                                element={<AddUserPage />}
                              />
                              <Route
                                path='store-conversation'
                                element={<StoreConversation />}
                              />
                              <Route
                                path='add-variants/:id'
                                element={<AddVariantsList />}
                              />
                              <Route
                                path='add-variants/:cid/:pmid'
                                element={<VariantComponent />}
                              />
                              <Route
                              path='add-attachment'
                              element={<AddAttachment />}
                            />
                            <Route
                                path='store-settings'
                                element={<StoreSettings />}
                              />
                              <Route
                                path='store-settings/store-schedule'
                                element={<StoreSchedule />}
                              />
                              <Route
                                path='store-settings/delivery-options'
                                element={<ViewDeliveryOptions />}
                              />
                              <Route
                                path='store-settings/delivery-options/:deliveryOptionId'
                                element={<DeliverySchedule />}
                              />
                              <Route
                                path='store-settings/account-settings'
                                element={<AccountSettings />}
                              />
                              <Route
                                path='add-delivery-option/schedule'
                                element={<AddDeliverySchedule />}
                              />
                              <Route path='edit' element={<EditTimeSlots />} />
                              <Route path='/admin' element={<AdminPage />} />
                              <Route
                                path='/services'
                                element={<ServicesComponent />}
                              />
                              <Route
                                path='/more-options'
                                element={<MoreOptions />}
                              />
                              <Route
                                path='/manage-channels'
                                element={<ManageChannels />}
                              />
                              <Route
                                path='/manage-referrals'
                                element={<ManageReferrals />}
                              />
                              <Route
                                path='/product-stats'
                                element={<ProductStats />}
                              />
                              <Route
                                path='/search'
                                element={<SearchComponent />}
                              />
                            </Route>
                            <Route
                              path='/view-all-products'
                              element={<ViewAllProducts />}
                            />
                            <Route
                              path='/billing'
                              element={<BillingComponent />}
                            />
                            <Route
                              path='/conversation'
                              element={<ConversationsPage />}
                            />
                            {/* </Route> */}
                            <Route
                              path='/login-register'
                              element={<LoginRegister />}
                            />
                            <Route
                              path='/register'
                              element={<RegisterStore />}
                            />
                            <Route
                              path='/onboarding'
                              element={<Onboarding />}
                            ></Route>
                            <Route
                              path='/add-address'
                              element={<StoreAddress />}
                            ></Route>
                            <Route path='/login' element={<StoreLogin />} />
                            <Route
                              path='/store-timings'
                              element={<StoreTimings />}
                            ></Route>
                            <Route
                              path='/delivery-options'
                              element={<DeliveryOptions />}
                            />
                          </Route>
                          <Route
                            path='/confirm-payment/:providerId'
                            element={<ConfirmPayment />}
                          />
                        </Routes>
                      </DialogContextProvider>
                    </NavbarContextProvider>
                  </ProductDetailsHideProvider>
                </StoreContextProvider>
              </DrawerContextProvider>
            </DialogContextProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </StoreScheduleProvider>
    </ThemeProvider>
  </BrowserRouter>
);

reportWebVitals();
