import baseUrl from "../../constants";

const googleMapsProxyRestService = {
    getSuggestions: async (searchWord) => {
        const url = new URL(`${baseUrl}maps/autocomplete`);
        url.searchParams.set('input', searchWord);
        const rawRes = await fetch(url);
        if (rawRes.status !== 200) throw new Error(rawRes.statusText)
        const jsonRes = await rawRes.json();
        return jsonRes;
    },
    getGeoCodeAddress: async (lat, lng) => {
        const url = new URL(`${baseUrl}maps/geocode`);
        url.searchParams.set('lat', lat);
        url.searchParams.set('lng', lng);
        const rawRes = await fetch(url);
        if (rawRes.status !== 200) throw new Error(rawRes.statusText)
        const jsonRes = await rawRes.json();
        return jsonRes;
    },
    getSearch: async (placeId) => {
        const url = new URL(`${baseUrl}maps/place-details`);
        url.searchParams.set('placeId', placeId);
        const rawRes = await fetch(url);
        if (rawRes.status !== 200) throw new Error(rawRes.statusText)
        const jsonRes = await rawRes.json();
        return jsonRes;
    }
}

export default googleMapsProxyRestService;