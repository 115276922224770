import { Box } from '@mui/material'
import React from 'react'
import { Dropdown, TextInput } from '@lifease/lifease-storeui-components';

const StoreTypeForm = (props) => {
    const { registerForm, handleOnChange, handleClear, storeTypes } = props;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, mt: 1 }}>
            <TextInput handleClear={() => { handleClear('storeName') }} name={'storeName'} label={'Store Name'} value={registerForm.storeName} onChange={handleOnChange} />
            <Dropdown label={'Select store type'} value={registerForm.storeType} items={storeTypes} onSelect={(e) => { handleOnChange({ target: { value: e, name: 'storeType' } }) }} />
        </Box>
    )
}

export default StoreTypeForm