import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react'
import { AVAILABLE_PROVIDERS } from '../../constants';

const ProviderDisplayComponent = (props) => {
    const { providers, onChangeProvider } = props;
    return (
        <FormControl>
            <RadioGroup onChange={onChangeProvider}>
                {
                    providers.map((res) => {
                        return (
                            AVAILABLE_PROVIDERS[res.providerName] ?
                                <FormControlLabel key={res.id} value={res.id} label={res.displayText} control={<Radio />}></FormControlLabel> :
                                <></>
                        )
                    })
                }
            </RadioGroup>
        </FormControl>
    )
}

export default ProviderDisplayComponent