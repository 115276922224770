import React, { createContext, useContext, useState } from 'react';

const StoreScheduleContext = createContext({
  timeslots: [],
  day: '',
  type: '',
  label: '',
  slots: [],
  setScheduleData: () => {},
});

export const StoreScheduleProvider = ({ children }) => {
  const [storeSchedule, setStoreSchedule] = useState({ loading: true, data: null });
  const [scheduleData, setScheduleData] = useState({
    timeSlots: [],
    day: '',
    type: '',
    label: '',
    slots: [],
    nav:false,
  });

  const updateScheduleData = (newData) => {
    setScheduleData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <StoreScheduleContext.Provider value={{ ...scheduleData, setScheduleData: updateScheduleData , storeSchedule, setStoreSchedule}}>
      {children}
    </StoreScheduleContext.Provider>
  );
};

export const useStoreSchedule = () => {
  const context = useContext(StoreScheduleContext);
  if (!context) {
    throw new Error('useStoreSchedule must be used within a StoreScheduleProvider');
  }
  return context;
};
