import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ImageCropper from "../../components/store-catalogue/ImageCropper";
import ProductDetails from "../../components/store-catalogue/ProductDetails";
import BackHeader from "../../components/commons/headers/BackHeader";
const StoreCatalogue = () => {
  const [cropData, setCropData] = useState(null);
  useEffect(() => {
    document.title = 'Store Catalogue';
  }, [])
  return (
    <>
      <BackHeader headText={'Store Catalogue'} />
      <Box sx={{ mb: 14, mx: 2, mt: 2 }}>
        <Typography component='h2' color='gray' fontWeight={600} sx={{ mb: 2 }}>
          Product Details
        </Typography>
        <Typography component='h5' color='gray' fontWeight={400} sx={{ mb: 2 }}>
          Catalogue Image
        </Typography>
        <ImageCropper setCropData={setCropData} cropData={cropData} />
        <ProductDetails cropData={cropData} />
      </Box>
    </>
  );
};

export default StoreCatalogue;
