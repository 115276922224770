import { Grid } from "@mui/material";
import React from "react";
import DeliveryOptionCard from "./DeliveryOptionCardComponent";

function DeliveryOptionsGrid(props) {
    const { deliveryOptions, onScheduleClick, handleDelete, onEditClick } = props;

    return (
        <Grid container rowSpacing={{ xs: 1, md: 1 }} columnSpacing={2} columns={{ xs: 12 }}>
            {deliveryOptions.map((deliveryOption, idx) => (
                <Grid key={"grid-item-" + deliveryOption.id} item xs={12}>
                    <DeliveryOptionCard onEditClick={onEditClick} index={idx} handleDelete={handleDelete} onScheduleClick={onScheduleClick} deliveryOption={deliveryOption}></DeliveryOptionCard>
                </Grid>
            ))}
        </Grid>
    );
}

export default DeliveryOptionsGrid;