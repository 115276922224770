import { Alert, Snackbar } from '@mui/material';
import React, { useContext, useState } from 'react'
import { SnackbarContext } from './SnackbarContext';

const MessageProps = {
    'success': {
        duration: 2000,
    },
    'error': {
        duration: 3000
    },
}

const SnackbarProvider = (props) => {
    const [open, setOpen] = useState({ open: false, severity: 'success', message: '' });
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(prev => ({ open: false, severity: prev.severity, message: prev.message }));
    }
    const showSnackbar = (severity, message) => {
        setOpen({ open: true, severity: severity, message: message });
    }
    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ width: 'fit-content' }}
                open={open.open}
                autoHideDuration={MessageProps[open.severity]?.duration}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={open.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {open.message}
                </Alert>
            </Snackbar>
            {
                props.children
            }
        </SnackbarContext.Provider>
    )
}

export const useSnackbar = () => useContext(SnackbarContext);

export default SnackbarProvider;