import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import shipmentMovements from '../../utils/shipmentMovements';
import { getActionFromState, getStateColor } from '../../utils/commons';
import shipmentRestService from '../../services/rest/ShipmentRestService';
import { H2, SecondaryButton } from '@lifease/lifease-storeui-components';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import productRestService from '../../services/rest/ProductRestService';

function ShipmentAction(props) {
  const { shipment, onShipmentAction, index, shipState } = props;
  const { showSnackbar } = useSnackbar();
  const { store } = useStore();
  const [loading, setLoading] = useState(false);
  const [checks, setChecks] = useState(true);
  const totalQuantity = () => {
    const count = shipment.shipmentItems.reduce(
      (total, item) => total + item.quantity,
      0
    );
    return count;
  };
  const [actionConfirmDialog, setActionConfirmDialog] = useState(false);
  const [eligibleShipmentAction, setEligibleShipmentAction] = useState([]);
  let [presentActionState, setPresentActionState] = useState('');
  useEffect(() => {
    function fetchShipmentActions(shipmentStatus) {
      if (shipmentMovements[shipState]) {
        setEligibleShipmentAction(shipmentMovements[shipState]);
      } else {
        setEligibleShipmentAction([]);
      }
    }
    fetchShipmentActions(shipment?.state);
  }, [shipment, shipState]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Dialog
        open={actionConfirmDialog}
        fullWidth
      >
        <DialogTitle id='alert-dialog-title'><H2>{'Are you sure?'}</H2></DialogTitle>
        <DialogContent>
          {shipment.netQuantity !== 0 && presentActionState !== "CANCELLED" && (
            <H2>You are only accepting {totalQuantity()} out of{' '}
              {totalQuantity() - shipment.netQuantity} items.</H2>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, mt: 2 }}>
            <H2>
              {`Confirm to ${getActionFromState(presentActionState)} the order`}
            </H2>
            {
              ["ACCEPTED", "CANCELLED"].includes(presentActionState) &&
              <FormGroup>
                <FormControlLabel checked={checks} onChange={(e) => { setChecks(prev => e.target.checked) }} control={<Checkbox />} label="Are you cancelling because items are out of stock?" />
              </FormGroup>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color='dark'
            size='small'
            variant='contained'
            onClick={handleDialogClose}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            color='success'
            size='small'
            variant='contained'
            onClick={handleShipmentAction}
            autoFocus
            disabled={loading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {eligibleShipmentAction?.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', width: '100%' }}>
          {eligibleShipmentAction?.map((state) => {
            return (<SecondaryButton
              variant={getStateColor(state)}
              key={state}
              disabled={loading}
              onClick={() => handleActionClick(state)}
            >
              {getActionFromState(state)}
            </SecondaryButton>
            );
          })}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );

  async function handleShipmentAction() {
    setLoading(true);
    try {
      let nonAcceptFlag = true;
      let tag = getActionFromState(presentActionState);
      tag = tag.toLowerCase();
      if (checks) {
        const productIds = await getProductsOutOfStock()
        for (const ids of productIds) {
          handleProductOutOfStock(ids)
        }
      }
      if (presentActionState === "ACCEPTED") {
        let data = [];
        shipment?.shipmentItems.forEach((item) => {
          if (item?.quantity > 0) nonAcceptFlag = false;
          data.push({
            productId: item?.productId,
            quantity: item?.quantity,
          });
        });
        if (nonAcceptFlag) {
          showSnackbar('error', "Total item accepted is 0, Do you want to cancel order?");
          return;
        }
        const newData = await shipmentRestService.acceptShipmentForStore(
          store.partyId,
          shipment.orderId,
          shipment.id,
          tag,
          data
        );
        setPresentActionState('');
        setActionConfirmDialog(false);
        onShipmentAction(newData, index);
      } else {
        const newData = await shipmentRestService.acceptShipmentForStore(
          store?.partyId,
          shipment?.orderId,
          shipment?.id,
          tag
        );
        setPresentActionState('');
        setActionConfirmDialog(false);
        onShipmentAction(newData, index);
      }

    } catch (err) {
      showSnackbar('err', err.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleProductOutOfStock(productId) {
    try {
      await productRestService.updateProduct(store.partyId, productId, { isAvailable: false });
    } catch (err) {
      showSnackbar('err', err.message);
    }
  }

  async function getProductsOutOfStock() {
    console.log(1);
    try {
      let productIdList = [];
      if (presentActionState === "ACCEPTED") {
        const originalShipment = await shipmentRestService.getShipmentsForStore(store.partyId, shipment.orderId);
        const originalShipmentItems = originalShipment[0].shipmentItems;
        const newShipmentItems = shipment.shipmentItems;
        console.log(originalShipmentItems, newShipmentItems);
        let ChangeObj = {};
        for (const item of originalShipmentItems) {
          ChangeObj[item.productId] = item.quantity;
        }
        for (const item of newShipmentItems) {
          if (ChangeObj[item.productId] !== item.quantity) {
            productIdList.push(item.productId);
          }
        }
      } else if (presentActionState === "CANCELLED") {
        for (const item of shipment.shipmentItems) {
          productIdList.push(item.productId);
        }
      }
      console.log(productIdList);
      return productIdList;
    } catch (err) { console.log('err') }
  }

  function handleActionClick(state) {
    setPresentActionState(state);
    setActionConfirmDialog(true);
  }

  function handleDialogClose() {
    setActionConfirmDialog(false);
    setPresentActionState('');
  }
}

export default ShipmentAction;
