import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { useProductDetailsHide } from '../../contexts/product-details-context/ProductDetailsHideProvider';

const ProductDetailsHideComponent = () => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { productDetailsHide, setProductDetailsHide } = useProductDetailsHide();
    const handleMenuClick = () => {
        setAnchorEl(null);
        setOpen(prev => !prev);
        setProductDetailsHide(prev => ({ ...prev, leadTime: !prev.leadTime }));
    }
    return (
        <Box sx={{ display: 'flex', float: 'right' }}>
            <IconButton onClick={(e) => { setOpen(prev => !prev); setAnchorEl(e.currentTarget) }}>
                <TuneIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
            >
                <MenuItem onClick={handleMenuClick}>
                    {
                        productDetailsHide.leadTime ?
                            'Hide lead time details' :
                            'Show lead time details'
                    }
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default ProductDetailsHideComponent