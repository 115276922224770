import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import googleMapsProxyRestService from '../services/rest/GoogleMapsRestServices';
/**
 * Method to check if geolocation permission is granted in native.
 * @returns if permission is there
 */
export const requestGeolocationPermission = async () => {
    try {
        const permissionStatus = await Geolocation.checkPermissions();
        if (permissionStatus.location === 'granted') {
            return true;
        } else if (permissionStatus.location === 'prompt') {
            const requestStatus = await Geolocation.requestPermissions();
            return requestStatus.location === 'granted';
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error in checking/requesting permission', error);
        return false;
    }
};

/**
 * Methos to check if geolocation permission is granted in web
 * @returns if permission is there
 */
export const requestGeolocationPermissionWebapp = async () => {
    try {
        if ('geolocation' in navigator) {
            const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
            return permissionStatus.state === 'granted' || permissionStatus.state === 'prompt';
        } else {
            console.error('Geolocation API not supported in this browser.');
            return false;
        }
    } catch (error) {
        console.error('Error checking or requesting permission', error);
        return false;
    }
};

export const getDeviceLocationAccessPermission = async () => {
    try {
        let hasPermission;
        if (Capacitor.isNativePlatform()) {
            hasPermission = await requestGeolocationPermission();
        } else {
            hasPermission = await requestGeolocationPermissionWebapp();
        }
        return hasPermission;
    } catch (err) {
        return false
    }
}

/**
 * Method to get user current location.
 * @returns 
 */
export const getCurrentLocation = async () => {
    try {
        let hasPermission;
        if (Capacitor.isNativePlatform()) {
            hasPermission = await requestGeolocationPermission();
        } else {
            hasPermission = await requestGeolocationPermissionWebapp();
        }
        if (hasPermission) {
            if (Capacitor.isNativePlatform()) {
                const coordinates = await Geolocation.getCurrentPosition();
                const { latitude, longitude } = coordinates.coords;
                return [latitude, longitude];
            } else {
                const { latitude, longitude } = await getCurrentCoordinatesForWebapp();
                return [latitude, longitude];
            }
        } else {
            console.error('Permission denied');
            return false;
        }
    } catch (error) {
        console.error('Error checking or requesting permission', error);
        return false;
    }
};

/**
 * Method to get current coordinates for webapp
 * @returns current coordinates for webapp
 */
const getCurrentCoordinatesForWebapp = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    resolve({ latitude, longitude });
                },
                (error) => {
                    reject(error);
                }
            );
        } else {
            reject(new Error('Geolocation is not supported in this browser.'));
        }
    });
};

/**
 * Get address from Latitude, Logitude
 * @param {*} latitude 
 * @param {*} longitude 
 * @returns 
 */
export const getGeocodeAddress = async (latitude, longitude) => {
    try {
        // const API_KEY = configurations?.GOOGLE_MAP_API_KEY;
        // const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`;

        const response = await googleMapsProxyRestService.getGeoCodeAddress(latitude, longitude)

        if (response) {
            const address = response.data.results[0].formatted_address;
            return address;
        } else {
            console.error('Error in reverse geocoding:', response.data.status);
            return null;
        }
    } catch (error) {
        console.error('Error in reverse geocoding:', error);
        return null;
    }
};
