import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grow, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import ViewOrdersGrid from "../../components/orders/ViewOrdersGridComponent";
import ProgressBackdrop from "../../common/ProgressBackdropComponent";
import LoginToContinue from "../../components/login/LoginToContinueComponent";
import ViewOrdersFilterComponent from "../../components/orders/ViewOrdersFilterComponent";
import { getLoggedInStore } from "../../indexeddb/AppContext";
import orderRestService from "../../services/rest/OrderRestService";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import StoreNameRating from "../../components/storeName-ratings/StoreNameRating";
import StatsRenderer from "../../components/orders/StatsRenderer";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import FilledHeader from "../../components/commons/headers/FilledHeader";
import Filters from "../../components/orders/Filters";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import { OrderCard, OrderStateCard, PrimaryButton } from "@lifease/lifease-storeui-components";
import CircularLoading from "../../components/commons/circular-progress/CircularLoading";
import { DateObjectCompFn, DecCompFn, IncCompFn } from "../../utils/luxonUtils";

const PaymentStatus = {
    "SUCCESS": { text: "Paid", color: "green" },
    "PENDING": { text: "Pending", color: "orange" },
    "FAIL": { text: "Pending", color: "orange" },
    "ACTIVE": { text: "Pending", color: 'orange' }
};

// CREATED, ACCEPTED, PACKED, SHIPPED, DELIVERED, CANCELLED

const OrderFilters = {
    "CREATED": { text: "CREATED", color: "green" },
    "ACCEPTED": { text: "ACCEPTED", color: "orange" },
    "PACKED": { text: "PACKED", color: "orange" },
    "SHIPPED": { text: "SHIPPED", color: "orange" },
    "DELIVERED": { text: "DELIVERED", color: "orange" },
    "CANCELLED": { text: "CANCELLED", color: "red" }
}

function ViewOrders() {
    // const location = useLocation();
    // const { state } = location;
    // const [hasMore, setHasMore] = useState(false);
    // const [loggedIn, setLoggedIn] = useState(true);
    // const [orders, setOrders] = useState({ load: false, orders: [] });
    // const [orderState, setOrderState] = useState("CREATED");
    // const [progress, setProgress] = useState(true);
    // const [storeName, setStoreName] = useState("");
    // const [storeId, setStoreId] = useState(null);
    // const [count, setCount] = useState({
    //     orderAmountSum: 0,
    //     homeVisitCount: 0
    // });
    const [pageNo, setPageNo] = useState(1);
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchparams] = useSearchParams();
    const { showSnackbar } = useSnackbar();
    const { store } = useStore();
    const [orders, setOrders] = useState([]);
    const [sort, setSort] = useState(-1);
    const navigate = useNavigate();

    const onSorting = () => {
        let data = [...orders];
        if (sort > 0) {
            data.sort(DecCompFn);
        } else {
            data.sort(IncCompFn);
        }
        setSort(prev => prev * -1);
        setOrders(data);
    }

    const onModalCloseConfirm = async (filters) => {
        handleOrderFetch([filters, null], 1);
        setPageNo(1);
    };

    const handleOrderFetch = async (allFilters, pageNo, loadMore) => {
        setLoading(true);
        try {
            const orders = await orderRestService.getOrdersByFilters(store.partyId, allFilters[0], allFilters[1], pageNo);
            if (loadMore) {
                setOrders(prev => [...prev, ...orders]);
            } else {
                setOrders(orders);
            }
        } catch (err) {
            showSnackbar('error', err?.message);
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        handleOrderFetch([searchParams.get('filter'), searchParams.get('shipBefore')], pageNo + 1, true);
        setPageNo(prev => prev + 1);
    };

    const handleOrderClick = (orderId) => {
        navigate(`/order-details/${orderId}`)
    };

    useEffect(() => {
        document.title = "View Orders";
        handleOrderFetch([searchParams.get('filter'), searchParams.get('shipBefore')]);
    }, []);

    return (
        <>
            <FilledHeader headText={'Shipments'} backUrl={'/'} />
            <Box sx={{ px: 2, mt: 1 }}>
                <Filters modalClosed={onModalCloseConfirm} handleSort={onSorting} />
                <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, mt: 2, mb: 18 }}>
                    {
                        orders.map((shipment, idx) => {
                            return <OrderCard key={idx} shipment={shipment} onAction={handleOrderClick} />
                        })
                    }
                </Box>
            </Box>
            <Box sx={{ position: 'fixed', bottom: 90, width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <PrimaryButton disabled={loading} onClick={handleLoadMore}>Load more</PrimaryButton>
                </Box>
            </Box>
            {
                loading && <CircularLoading />
            }
        </>
        // <Container direction="column" maxWidth="md" sx={{ padding: 1 }}>
        //     {
        //         storeId && storeName.length > 0 && <StoreNameRating storeName={storeName} id={storeId} />
        //     }
        //     {
        //         storeId &&
        //         <Box
        //             sx={{
        //                 display: "flex",
        //                 justifyContent: "space-between",
        //                 pb: '1rem'
        //             }}>
        //             <StatsRenderer type={"amount"} storeId={storeId} />
        //             <StatsRenderer type={"visits"} storeId={storeId} />
        //         </Box>
        //     }
        //     {
        //         orders.load &&
        //         <>
        //             {
        //                 loggedIn ?
        //                     <>
        //                         <ViewOrdersFilterComponent orderState={orderState} setOrderState={setOrderState} fetchOrders={fetchOrders}></ViewOrdersFilterComponent>
        //                         <ViewOrdersGrid PaymentStatus={PaymentStatus} orders={orders.orders} progress={progress}></ViewOrdersGrid>
        //                         {
        //                             hasMore &&
        //                             <Button fullWidth sx={{ mt: 1 }} variant="text" xs={12} color="secondary" onClick={() => fetchOrders(true)}>Show More</Button>
        //                         }
        //                     </> :
        //                     <LoginToContinue loginMessage={state?.loginMessage}></LoginToContinue>
        //             }
        //         </>
        //     }
        //     <ProgressBackdrop progress={progress}></ProgressBackdrop>
        // </Container>
    );

    // async function fetchOrders(showMore = false) {
    //     setProgress(true);
    //     try {
    //         let minOrderId;
    //         if (showMore && orders.orders.length !== 0) {
    //             minOrderId = orders.orders[orders.orders.length - 1].id - 1;
    //         }
    //         const store = await getLoggedInStore();
    //         setStoreId(store?.partyId);
    //         setStoreName(store?.storeName);
    //         if (store) {
    //             const orders = await orderRestService.getOrderByState(store.partyId, orderState, minOrderId);
    //             setOrders({ load: true, orders: orders });
    //             setLoggedIn(true);
    //             setHasMore(orders.length > 0);
    //         } else {
    //             setOrders({ load: true, orders: [] });
    //             setLoggedIn(false);
    //             setHasMore(false);
    //         }
    //     } catch (err) {
    //         setOrders({ load: true, orders: [] });
    //         setHasMore(false);
    //     } finally {
    //         setProgress(false);
    //     }
    // }

}

export default ViewOrders;