import authFetch from "../../utils/interceptor";

const baseUrl = process.env.REACT_APP_KHATA_BASE_URL;
const KhataRestService = {
    getBalance: async (storeId) => {
        const url = new URL(`${baseUrl}lifease-khata/partyId/${storeId}`);
        const options = {
            method: 'GET',
            credentials: 'include',
        };
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    getProviders: async () => {
        const url = new URL(`${baseUrl}lifease-khata/providers`);
        const options = {
            method: 'GET',
            credentials: 'include'
        };
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        };
    },
    getRechargeDetails: async (partyId, data) => {
        try {
            const url = new URL(`${baseUrl}lifease-khata/partyId/${partyId}/recharge`);
            const options = {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    pollRechargeDetails: async (partyId, paymentId) => {
        const url = new URL(`${baseUrl}lifease-khata/partyId/${partyId}/payment/${paymentId}`);
        const options = {
            method: 'PUT',
            credentials: 'include',
        };
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    }
}
export default KhataRestService;