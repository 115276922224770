import { Alert, Button, Container, TextField } from "@mui/material";
import axios from "axios";
import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router";
import baseUrl from "../../constants";
import { getLoggedInStore } from "../../indexeddb/AppContext";

function AddUserPage() {
    const [alertMessage, setAlertMessage] = useState("");
    const [inputs, setInputs] = useState({});
    const navigate = useNavigate();

    useEffect(()=>{
    document.title = 'add user';
    },[])
    return (
        <Container direction="column" maxWidth="md" sx={{ padding: 1 }}>
            <TextField type="number" onFocus={e => e.target.select()} sx={{ mt: 1 }} id="mobile-text-id" fullWidth
                label="Mobile" variant="outlined" onChange={(event) => handleMobileChange(event.currentTarget.value)} spacing={2} />
            {alertMessage !== "" ? <Alert sx={{ mt: 1 }} severity='warning'>{alertMessage}</Alert> : <></>}
            <Button fullWidth sx={{ mt: 1 }} color="secondary" variant="contained" onClick={handleSubmit}>Submit</Button>
        </Container>
    );

    function handleMobileChange(value) {
        setInputs({
            ...inputs,
            mobile: value
        });
    }

    function handleSubmit() {
        inputs.role = "STORE_USER";
        inputs.roleType = "STORE";
        getLoggedInStore().then(store => {
            axios.put(baseUrl + "stores/" + store.partyId + "/user", inputs).then(response => {
                setAlertMessage("");
                navigate("/manage-users");
            })
                .catch((error) => {
                    setAlertMessage(error.response.data.message);
                })
        });
    }
}

export default AddUserPage;