import { Box, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getLoggedInStore } from '../../indexeddb/AppContext';
import storeScheduleRestService from '../../services/rest/StoreScheduleRestService';
import { convertObjectToUtcTime, weekdays } from '../../utils/luxonUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import ScheduleComponent from '../../common/ScheduleComponent';
import { ScheduleLabel, ScheduleType } from '../../utils/enums';
import { useStoreSchedule } from '../../services/context/StoreScheduleContext';
import SchedulePage from '../../common/SchedulePage';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import BackHeader from '../../components/commons/headers/BackHeader';
import WeekScheduleComponent from '../../components/commons/week-schedule/WeekScheduleComponent';
import ReviewSchedule from '../../components/commons/week-schedule/ReviewSchedule';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import CircularLoading from '../../components/commons/circular-progress/CircularLoading';
import { convertScheduleToScheduleRequest } from '../../components/delivery-options/splitHoursUtil';

function getInitialScheduleList(weekdays) {
    const schedule = {};
    weekdays.forEach(day => {
        schedule[day] = { slots: [], present: false };
    })
    return schedule;
}

function convertStateToResponseSchedule(storeSchedule) {
    let requestBody = { daySchedules: [] };
    for (const key in storeSchedule.data) {
        if (storeSchedule.data[key].present) {
            let daySchedule = {};
            daySchedule.dayOfWeek = key;
            let slots = [];
            for (const slot of storeSchedule.data[key].slots) {
                slots.push({ type: slot.type, startTime: slot.startTime, endTime: slot.endTime });
            }
            daySchedule.timeSlots = slots;
            requestBody.daySchedules.push(daySchedule);
        }
    }
    return requestBody;

}

function transformStoreSchedule(responseSchedule, slots) {
    if (!responseSchedule) return null;
    const daySchedules = responseSchedule.daySchedules;
    const localSchedule = getInitialScheduleList(weekdays);
    daySchedules.forEach(daySchedule => {
        const day = daySchedule.dayOfWeek;
        localSchedule[day] = { slots: daySchedule.timeSlots, present: true };
    });
    for (const key in localSchedule) {
        if (slots && key === slots?.day) {
            localSchedule[key] = { slots: slots.timeSlots, present: true };
        }
    }
    return localSchedule;
}

const StoreSchedule = () => {
    const { store } = useStore();
    const { showSnackbar } = useSnackbar();
    const [storeSchedule, setStoreSchedule] = useState(null);
    const [loading, setLoading] = useState(true);
    const handleScheduleSubmit = async (data) => {
        setLoading(true);
        const schedule = convertScheduleToScheduleRequest(data, ScheduleType.ABSOLUTE);
        try {
            const res = await storeScheduleRestService.updateStoreSchedule(store.partyId, schedule);
            setStoreSchedule(res);
            showSnackbar('success', 'Store timings updated successfully');
        } catch (err) {
            showSnackbar('error', err.message)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        document.title = "Store Timings"
        storeScheduleRestService.getStoreSchedule(store.partyId).then(res => {
            if (res) {
                setStoreSchedule(JSON.parse(res));
            } else {
                showSnackbar('error', "Store schedule dont exist");
            }
        }).catch(err => { showSnackbar('error', err.message) }).finally(() => { setLoading(false) });
    }, []);

    return (
        <>
            <BackHeader headText={'Store Schedule'} />
            {
                !loading ?
                    <Box sx={{ mt: 2, px: 2, mb: 14 }}>
                        <WeekScheduleComponent schedule={storeSchedule} handleScheduleSubmit={handleScheduleSubmit} ScheduleType={ScheduleType.ABSOLUTE} />
                    </Box> :
                    <CircularLoading />
            }
        </>
    )
}

export default StoreSchedule