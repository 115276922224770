import { Alert, Box, Card, CardMedia, Fade, Grid, Skeleton, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../constants";
import UpdateProductAction from "./UpdateProductActionComponent";

function ViewProductCard(props) {
  const { product, metrics } = props;

  const [productImage, setProductImage] = useState({});
  const [alertMessage, setAlertMessage] = useState(undefined);
  const [alertSeverity, setAlertSeverity] = useState("error");

  useEffect(() => { fetchImage(); }, []);


  return (
    <Card sx={{ p: 1 }} elevation={2}>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
        <Box sx={{ display: 'flex', columnGap: 1 }}>
          {
            productImage.url ?
              <CardMedia component="img" height="120px" width='120px' sx={{ maxWidth: '120px' }} image={productImage.url} alt={product.productName} /> :
              <Skeleton variant="rectangular" height={120} width={120} />
          }
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontSize={'medium'}>
              {product.productName}
            </Typography>
            <Typography variant="body1" color={"primary"}>
              Brand - {product.brand}
            </Typography>
            {alertMessage === undefined ? <></> : <Fade in={alertMessage !== undefined} addEndListener={() => { setTimeout(() => setAlertMessage(undefined), 4000) }} timeout={{ exit: 2000 }}><Alert sx={{ ml: "auto", mr: "auto", position: "absolute" }} size="small" severity={alertSeverity}>{alertMessage}</Alert></Fade>}
          </Box>
        </Box>
        <UpdateProductAction product={product} setAlertMessage={setAlertMessage} setAlertSeverity={setAlertSeverity} metrics={metrics} />
      </Box>
    </Card>
  );

  function fetchImage() {
    axios.defaults.withCredentials = true;
    axios.get(baseUrl + "files/" + product.imageId).then(response => {
      setProductImage(response.data);
    })
  }


}

export default ViewProductCard;