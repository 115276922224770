import { Box, Checkbox, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../constants";
import AddProductCategoryCard from "./AddProductCategoryCardComponent";
import { getLoggedInStore } from "../../indexeddb/AppContext";
import { PrimaryButton, ProductCategoryCard } from "@lifease/lifease-storeui-components";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import productCategoryRestService from "../../services/rest/ProductCategoryRestService";
import { useNavigate } from "react-router-dom";
import CircularLoading from "../commons/circular-progress/CircularLoading";
import { useSnackbar } from "../../contexts/SnackbarProvider";

function AddProductCategoriesGrid() {
    const [productCategories, setProductCategories] = useState([]);
    const [productCategoryForm, setProductCategoryForm] = useState({});
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { store } = useStore();
    const handleProductCategoryChange = (e) => {
        setCount(prev => {
            if (e.target.checked) return prev + 1;
            return prev - 1;
        })
        setProductCategoryForm(prev => ({ ...prev, [e.target.name]: (prev[e.target.name]) ? !prev[e.target.name] : true }));
    }
    const saveCategories = async () => {
        try {
            setLoading(true);
            for (const categoryId in productCategoryForm) {
                if (productCategoryForm[categoryId]) {
                    await productCategoryRestService.addCategoryToStore(store.partyId, parseInt(categoryId, 10));
                }
            }
        } catch (err) {
            let Error = err.message;
            if (err.message === 'null') Error = "Something went wrong";
            showSnackbar('error', Error);
        }
        finally {
            setLoading(false);
            navigate('/view-product-categories');
        }
    }
    useEffect(() => { fetchProductCategories(); }, []);
    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
                {productCategories.map((productCategory) => (
                    <Grid xs={12} sm={6} md={4} item key={productCategory.id} >
                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pinter' }}>
                            <Checkbox color="secondary" name={productCategory.id.toString()} onChange={handleProductCategoryChange} checked={productCategoryForm[productCategory.id] ?? false} />
                            <ProductCategoryCard key={productCategory.id} category={productCategory} description={productCategory.description ?? ''} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 14, mt: 2 }}>
                <PrimaryButton disabled={loading || count == 0} onClick={saveCategories}>Add selected categories</PrimaryButton>
            </Box>
            {
                loading &&
                <CircularLoading />
            }
        </>
    );

    function fetchProductCategories() {
        axios.defaults.withCredentials = true;
        getLoggedInStore().then(store => {
            if (store !== undefined) {
                axios.get(baseUrl + "product-category?notInStoreId=" + store.partyId).then(response => {
                    setProductCategories(response.data);
                }).finally(() => { setLoading(false) });
            }
        })
    }
}

export default AddProductCategoriesGrid;