import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid, CardMedia, Typography, Skeleton, Divider } from '@mui/material';
import CurrencyDisplay from "../../common/CurrencyDisplayComponent";
import { getLoggedInStore } from "../../indexeddb/AppContext";
import productRestService from "../../services/rest/ProductRestService";
import imageRestService from "../../services/rest/ImageRestService";
import ShipmentQuantity from "./ShipmentQuantity";
import { ProductCard } from "@lifease/lifease-storeui-components";

function ShipmentItem(props) {
  const { item, handleMinusClick, handlePlusClick, index, quantityChanged, created, orderedQuantity, metrics } = props;
  const [loading, setLoading] = useState(false);
  const [productMetric, setProductMetric] = useState([]);
  useEffect(() => {
    function fetchProduct() {
      setLoading(true);
      getLoggedInStore().then(store => {
        productRestService?.fetchProduct(store?.partyId, item?.productId).then(productData => {
          setProductMetric(metrics.filter((metric) => metric.id === productData.metricsId))
          setProduct(productData);
          setLoading(false);
        }).catch(error => {
          console.error("error getting product", error);
        });
      });

    }
    if (!quantityChanged) {
      fetchProduct();
    }
  }, [item, quantityChanged]);
  const [product, setProduct] = useState({});
  useEffect(() => {
    function fetchImage() {
      imageRestService?.fetchImage(product?.imageId).then(data => {
        setProductImage(data);
      }).catch(error => {
        console.error("error getting product image", error);
      });
    }
    if (product?.imageId && !quantityChanged) {
      fetchImage();
    }
  }, [product, quantityChanged]);
  const [productImage, setProductImage] = useState({});
  return (
    <>
      {
        product?.id &&
        <>
          <ProductCard
            created={created}
            item={item}
            handleMinusButton={handleMinusClick}
            handlePlusButton={handlePlusClick}
            index={index}
            metrics={metrics}
            product={product}
            orderedQuantity={orderedQuantity}
          />
        </>
      }
    </>
    // item, handleMinusClick, handlePlusClick, index, quantityChanged, created, orderedQuantity, metrics
    // <Card sx={{ boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.1)", mb: 0 }}>
    //   <Grid container sx={{ flexDirection: 'row' }}>
    //     <Grid item xs={3}>
    //       <CardMedia
    //         component="img"
    //         height="100"
    //         image={productImage.url}
    //         alt={product.productName}
    //         sx={{ p: 1, objectFit: 'contain' }} />
    //     </Grid>
    //     <Grid item xs={9}>
    //       <CardContent>
    //         <Typography variant="body1" component="div" wrap="wrap">
    //           {(loading && !quantityChanged) ? <Skeleton animation="wave" /> : product.productName}
    //           {
    //             productMetric.length > 0 &&
    //             <>
    //               &#40;{product.metricsValue}&nbsp;{productMetric[0].displayText}&#41;
    //             </>
    //           }
    //         </Typography>
    //         {
    //           created ?
    //             (loading && !quantityChanged) ? <Skeleton animation="wave" /> : <ShipmentQuantity item={item} orderedQuantity={orderedQuantity} handleMinusClick={handleMinusClick} handlePlusClick={handlePlusClick} index={index} /> :
    //             <Typography variant="caption" component="div" wrap="wrap">
    //               {loading ? <Skeleton animation="wave" /> : "Quantity: " + item?.quantity}
    //             </Typography>
    //         }

    //         <Typography variant="caption" component="div" wrap="wrap">
    //           {(loading && !quantityChanged) ? <Skeleton animation="wave" /> : <CurrencyDisplay value={item?.quantity * product?.sellingPrice} label={'Amount'} />}
    //         </Typography>
    //       </CardContent>
    //     </Grid>
    //   </Grid>
    // </Card>
  )
}

export default ShipmentItem;