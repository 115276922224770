import { Box, Button, Container, Grid, TextField, withStyles } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductCategoriesGrid from "../../components/product-category/ProductCategoriesGridComponent";
import SearchIconHeader from "../../components/commons/headers/SearchIconHeader";
import BackHeader from "../../components/commons/headers/BackHeader";
import SearchSuggestionComponent from "../../components/commons/search-suggestion/SearchSuggestionComponent";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import productRestService from "../../services/rest/ProductRestService";
import loadash from 'lodash';
import styled from "@emotion/styled";
import { ArrowButton } from "@lifease/lifease-storeui-components";
import productCategoryRestService from "../../services/rest/ProductCategoryRestService";

//declare the const and add the material UI style

const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: red;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: red;
    }
  }
`;

const DebounceTime = 500;
function ViewProductCategories() {
    const { store } = useStore();
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [categories, setCategories] = useState([]);

    const onAction = (e) => {
        setShowSearchIcon(prev => !prev);
    }

    const handleClearInput = () => {
        setSearchText('');
    }

    const searchSuggestion = async (searchWord, categories) => {
        try {
            const categoryList = categories.map(item => item.id);
            const suggestions = await productRestService.productSearchSuggestion(searchWord, categoryList);
            setSuggestions(suggestions);
        } catch { }
    }

    const handleDebounceFn = (searchWord, categories) => {
        searchSuggestion(searchWord, categories);
    }

    const debounceFn = useCallback(loadash.debounce(handleDebounceFn, DebounceTime), []);

    const onChange = (e) => {
        setSearchText(e.target.value);
        if (e.target.value.length > 2) {
            debounceFn(e.target.value, categories);
        } else {
            setSuggestions([]);
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.value?.length > 0) {
            navigate(`/search?searchWord=${e.target.value}&type=ADD`);
        }
    }

    const onSuggestionClick = (word) => {
        navigate(`/search?searchWord=${word}&type=ADD`);
    }

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'View Product Category';
    }, []);

    return (
        <>
            <SearchIconHeader
                placeholder={"Search product to add"}
                showSearchIcon={showSearchIcon}
                onAction={onAction}
                headText={'View Product Categories'}
                handleClearInput={handleClearInput}
                value={searchText}
                onChange={onChange}
                onKeyDown={onKeyDown}
                autoFocus
            />
            <SearchSuggestionComponent suggestions={suggestions} onSuggestionClick={onSuggestionClick} />
            <Container maxWidth="lg" sx={{ mt: 2, mb: 14 }}>
                <ProductCategoriesGrid />
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: { xs: '100%', md: '30%' } }}>
                    <ArrowButton onClick={() => { navigate('/add-product-category') }}>Add Categories</ArrowButton>
                </Box>
            </Container >
        </>
    );
}

export default ViewProductCategories;