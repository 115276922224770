import React, { useState } from 'react';
import BackHeader from '../../components/commons/headers/BackHeader';
import { Box, InputAdornment, TextField } from '@mui/material';
import { H6, SecondaryButton } from '@lifease/lifease-storeui-components';
import referralCodeRestService from '../../services/rest/ReferralRestService';
import { useStore } from '../../contexts/store-context/StoreContextProvider';

const ManageReferrals = () => {
  const { store } = useStore();
  const [referralCode, setReferralCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getReferralCode = async (storeId) => {
    try {
      setIsLoading(true);
      const res = await referralCodeRestService.getReferral(storeId);
      if (res && res.referralCode) {
        setReferralCode(res.referralCode);
      } else {
        setReferralCode('');
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <BackHeader headText={'Manage Referrals'} />

      <Box sx={{ p: 3 }}>
        {isLoading && <H6>Please wait ... , Generating Code</H6>}
        <TextField
          label='Referral Code'
          fullWidth
          margin='normal'
          value={referralCode}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <div
                  onClick={() => getReferralCode(store.partyId)}
                  style={{ cursor: 'pointer' }}
                >
                  <SecondaryButton>Generate</SecondaryButton>
                </div>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default ManageReferrals;
