import baseUrl from "../../constants";
import authFetch from "../../utils/interceptor";

const ratingRestService = {
    getRating: async (id) => {
        const url = `${baseUrl}stores/${id}/aggregate-review`;
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.text();
        } catch (err) {
            throw new Error(err.message);
        }
    }
}

export default ratingRestService;