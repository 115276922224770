import React, { useState } from 'react'
import DaysSelectionComponent from './DaysSelectionComponent'
import { Box, Button } from '@mui/material';
import SlotsComponent from './SlotsComponent';
import DayScheduleComponent from './DayScheduleComponent';
import ReviewSchedule from './ReviewSchedule';
import { ScheduleType as Type } from '../../../utils/enums';
import { PrimaryButton } from '@lifease/lifease-storeui-components';
import { convertTimeToDateObject } from '../../../utils/luxonUtils';

const DaysOfWeek = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY'
];

const WeekScheduleComponent = (props) => {
    const { ScheduleType, handleScheduleSubmit, schedule = null } = props;
    let timeObj = {};
    timeObj.startTime = null;
    timeObj.endTime = null;
    timeObj.type = ScheduleType;
    if (ScheduleType === 'RELATIVE') {
        timeObj.duration = '';
    }
    let initialTimingState = {
        'SUNDAY': { present: false, day: 'SUNDAY', slots: [timeObj] },
        'MONDAY': { present: false, day: 'MONDAY', slots: [timeObj] },
        'TUESDAY': { present: false, day: 'TUESDAY', slots: [timeObj] },
        'WEDNESDAY': { present: false, day: 'WEDNESDAY', slots: [timeObj] },
        'THURSDAY': { present: false, day: 'THURSDAY', slots: [timeObj] },
        'FRIDAY': { present: false, day: 'FRIDAY', slots: [timeObj] },
        'SATURDAY': { present: false, day: 'SATURDAY', slots: [timeObj] },
    };
    let initialSteps = 0;
    let data = [];
    if (schedule) {
        for (const daySchedule of schedule?.daySchedules) {
            const tempSlots = [];
            for (const slot of daySchedule.timeSlots) {
                let tempObj = {
                    type: ScheduleType,
                    startTime: convertTimeToDateObject(slot.startTime),
                    endTime: convertTimeToDateObject(slot.endTime)
                }
                if (ScheduleType === "RELATIVE") {
                    tempObj.duration = slot.duration / 60;
                }
                tempSlots.push(tempObj);
            }
            initialTimingState[daySchedule.dayOfWeek] = {
                present: true, day: daySchedule.dayOfWeek, slots: tempSlots
            };
        }
        for (const day in initialTimingState) {
            if (initialTimingState[day].present) {
                data.push(day);
            }
        }
        initialSteps = 2;
    } else {
        initialTimingState = {
            'SUNDAY': { present: true, day: 'SUNDAY', slots: [timeObj] },
            'MONDAY': { present: true, day: 'MONDAY', slots: [timeObj] },
            'TUESDAY': { present: true, day: 'TUESDAY', slots: [timeObj] },
            'WEDNESDAY': { present: true, day: 'WEDNESDAY', slots: [timeObj] },
            'THURSDAY': { present: true, day: 'THURSDAY', slots: [timeObj] },
            'FRIDAY': { present: true, day: 'FRIDAY', slots: [timeObj] },
            'SATURDAY': { present: true, day: 'SATURDAY', slots: [timeObj] },
        };
    }
    const [selectedDays, setSelectedDays] = useState(initialTimingState);
    const [days, setDays] = useState(data);
    const [steps, setSteps] = useState(initialSteps);

    const handleDaysChange = (e) => {
        setSelectedDays(prev => ({ ...prev, [e.target.name]: { ...prev[e.target.name], present: e.target.checked } }))
    }

    const handleStepClick = (num) => {
        setSteps(prev => prev + num);
        let data = [];
        for (const day in selectedDays) {
            if (selectedDays[day].present) {
                data.push(day);
            }
        }
        setDays(data);
    }

    const handleTimeChnage = (day, type, val, idx) => {
        setSelectedDays(prev => ({
            ...prev,
            [day]: {
                ...prev[day], slots: prev[day].slots.map((item, index) => {
                    if (index === idx) {
                        return { ...item, [type]: val }
                    }
                    return item;
                })
            }
        }));
    }

    const addSlot = (day, type, idx) => {
        if (type === 'add') {
            let newObj = {};
            newObj.startTime = null;
            newObj.endTime = null;
            newObj.type = ScheduleType;
            if (ScheduleType === Type.RELATIVE) {
                newObj.duration = '';
            }
            setSelectedDays(prev => ({
                ...prev,
                [day]: {
                    ...prev[day],
                    slots: [...prev[day].slots, newObj]
                }
            }));
        } else {
            setSelectedDays(prev => ({
                ...prev,
                [day]: {
                    ...prev[day],
                    slots: prev[day].slots.filter((slot, i) => i !== idx)
                }
            }));
        }
    }

    const handleAutoDatePopulate = (data) => {
        setSelectedDays(data);
        setSteps(prev => prev + 1);
    }

    const handleReset = () => {
        setSteps(0);
        setSelectedDays(initialTimingState);
    }

    const handleConfirm = () => {
        handleScheduleSubmit(selectedDays)
    }


    return (
        <>
            {
                steps === 0 &&
                <DaysSelectionComponent DaysOfWeek={DaysOfWeek} selectedDays={selectedDays} handleChange={handleDaysChange} />
            }
            {
                steps === 1 &&
                <DayScheduleComponent schedule={schedule} ScheduleType={ScheduleType} handleAutoDatePopulate={handleAutoDatePopulate} setSteps={setSteps} addSlot={addSlot} days={days} DaysOfWeek={DaysOfWeek} selectedDays={selectedDays} onChange={handleTimeChnage} />
            }
            {
                steps === 2 &&
                <ReviewSchedule schedulePresent={schedule !== null} ScheduleType={ScheduleType} handleReset={handleReset} handleConfirm={handleConfirm} selectedDays={selectedDays} days={days} />
            }
            {
                steps === 0 &&
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', mt: 2 }}>
                    <PrimaryButton onClick={() => { handleStepClick(1) }}>Next</PrimaryButton>
                </Box>
            }
        </>
    )
}

export default WeekScheduleComponent