import baseUrl from "../../constants";
import axios from "axios";
import axiosCommons from "./AxiosCommons";
import authFetch from "../../utils/interceptor";

/**
 * All Rest call related to image
 */
const imageRestService = {

    fetchImage: async (imageId) => {
        axios.defaults.withCredentials = true;
        let url = `${baseUrl}files/${imageId}`;
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    getStoreImage: async (storeId, imageId) => {
        const url = new URL(
          baseUrl + 'stores/' + storeId + '/files/' + imageId
        );
        const options = {
          credentials: 'include',
        };
        const response = await authFetch(url, options);
        if (!response.ok) {
          throw new Error('Failed to fetch image');
        }
        const jsonRes = await response.json();
        return jsonRes;
      },
}

export default imageRestService;