import { Box, Container, Grid, Switch, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import ViewProductsGrid from "../../components/products/ViewProductsGridCeomponent";
import SearchProduct from "../../components/product-category/SearchProduct";
import ProductDetailsHideComponent from "../../components/product-details-hide/ProductDetailsHideComponent";
import BackHeader from "../../components/commons/headers/BackHeader";
import SearchIconHeader from "../../components/commons/headers/SearchIconHeader";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import productRestService from "../../services/rest/ProductRestService";
import loadash from 'lodash';
import UpdateProductsGrid from "./UpdateProductsGrid";
import { useNavigate } from 'react-router-dom';
import SearchSuggestionComponent from "../../components/commons/search-suggestion/SearchSuggestionComponent";

const DebounceTime = 500;
function ViewProducts() {
    const { store } = useStore();
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const onAction = (e) => {
        setShowSearchIcon(prev => !prev);
    }

    const handleClearInput = () => {
        setSearchText('');
    }

    const searchSuggestion = async (searchWord) => {
        try {
            const suggestions = await productRestService.storeProductSearchSuggestion(searchWord, store.partyId);
            setSuggestions(suggestions);
        } catch { }
    }

    const handleDebounceFn = (searchWord) => {
        searchSuggestion(searchWord);
    }

    const debounceFn = useCallback(loadash.debounce(handleDebounceFn, DebounceTime), []);

    const onChange = (e) => {
        setSearchText(e.target.value);
        if (e.target.value.length > 2) {
            debounceFn(e.target.value);
        } else {
            setSuggestions([]);
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.length > 2) {
            navigate(`/search?searchWord=${e.target.value}&type=UPDATE`);
        }
    }

    const onSuggestionClick = (word) => {
        navigate(`/search?searchWord=${word}&type=UPDATE`)
    }
    // const { state } = useLocation();
    // const [available, setAvailable] = useState(true);
    // const productCategory = state.productCategory;
    // const search = state?.search;
    // const metrics = state.metrics;

    // const handleAvailabilityChange = (event) => {
    //     setAvailable(event.target.checked)
    // }

    // useEffect(() => {
    //     document.title = 'View Product';
    // }, []);

    return (
        <>
            <SearchIconHeader
                placeholder={"Search product to update"}
                showSearchIcon={showSearchIcon}
                onAction={onAction}
                supTitle={'Update products'}
                subTitle={searchParams.get('categoryName')}
                handleClearInput={handleClearInput}
                value={searchText}
                onChange={onChange}
                onKeyDown={onKeyDown}
                autoFocus
            />
            <SearchSuggestionComponent suggestions={suggestions} onSuggestionClick={onSuggestionClick} />
            <Container maxWidth="lg" sx={{ mt: 2, mb: 14 }}>
                <UpdateProductsGrid categoryId={params.categoryId} />
            </Container>
            {/* <BackHeader headText={'View Product'} />
            <Container direction="column" maxWidth="lg" sx={{ padding: 1, mt: 2, mb: 14 }}>
                <SearchProduct productCategories={[{ id: productCategory.id }]} metrics={metrics} />
                {
                    !search &&
                    <Box sx={{ p: 1, display: 'flex', backgroundColor: 'white', mb: 1, alignItems: 'center', justifyContent: 'space-between' }} key="product-available-switch-key" >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {available ? <Typography fontSize={'large'}>Showing Available Products </Typography> : <Typography fontSize={'large'}> Showing UnAvailable Products</Typography>}
                            <Switch color="secondary" checked={available} onChange={(event) => handleAvailabilityChange(event)} />
                        </Box>
                        <ProductDetailsHideComponent />
                    </Box>
                }
                <ViewProductsGrid productCategory={productCategory} search={search} metrics={metrics} isAvailable={available}></ViewProductsGrid>
            </Container> */}
        </>
    );
}

export default ViewProducts;