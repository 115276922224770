import { PrimaryButton } from '@lifease/lifease-storeui-components';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSnackbar } from '../../contexts/SnackbarProvider';

const ProductFormComponent = (props) => {
    const { metrics, product, handleOnButtonClick, type } = props;

    const { showSnackbar } = useSnackbar();


    const [form, setForm] = useState({ mrp: product?.mrp ?? '', sellingPrice: product?.sellingPrice ?? '', metricsId: product?.metricsId ?? '', metricsValue: product?.metricsValue ?? '' });
    const [loading, setLoading] = useState(false);
    const onChange = (e) => {
        setForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleValidation = () => {
        if (form.sellingPrice > form.mrp) {
            showSnackbar('error', "Selling price can't be bigger than MRP");
            return false;
        } else if (form.metricsId?.length === 0 || form.metricsValue?.length === 0 || form.mrp?.length === 0 || form.sellingPrice?.length === 0) {
            showSnackbar('error', "All fields are required");
            return false;
        } else if (form.sellingPrice < 0 || form.mrp < 0 || form.metricsValue < 0) {
            showSnackbar('error', "All fields should be positive");
            return false;
        }

        return true;
    }

    const handleAdd = () => {
        if (handleValidation()) {
            setLoading(true);
            handleOnButtonClick(form, product, type);
            setTimeout(() => {
                setLoading(false);
            }, 4000);
        }
    }

    useEffect(() => {
        setForm({ mrp: product?.mrp ?? '', sellingPrice: product?.sellingPrice ?? '', metricsId: product?.metricsId === 0 ? '' : product.metricsId ?? '', metricsValue: product?.metricsValue ?? '' });
    }, [product]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pr: { xs: 0, md: 2 }, rowGap: 2 }}>
            <Box sx={{ display: 'flex', columnGap: 2 }}>
                <TextField name={'mrp'} onChange={onChange} value={form.mrp} fullWidth variant='standard' label="MRP" type='number' />
                <TextField name={'sellingPrice'} onChange={onChange} value={form.sellingPrice} fullWidth variant='standard' label="Selling Price" type='number' />
            </Box>
            <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center' }} >
                {/* <TextField variant='standard' label="Unit" type='number' /> */}
                {/* <Box sx={{ mt: 2 }}>
                    <Dropdown style={{ width: '200px' }} items={metrics.map(item => ({ value: item.id, label: item.displayText }))} />
                </Box> */}
                <FormControl variant='standard' fullWidth sx={{ mt: 0 }}>
                    <InputLabel>Unit</InputLabel>
                    <Select name={'metricsId'} onChange={onChange} value={form.metricsId} fullWidth>
                        {
                            metrics.map(item => {
                                return <MenuItem key={item.id} value={item.id}>{item.displayText}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <TextField name={'metricsValue'} value={form.metricsValue} onChange={onChange} fullWidth variant='standard' label="Unit Value" type='number' />
            </Box>
            <Box sx={{ mt: 2 }}>
                <PrimaryButton disabled={loading} onClick={handleAdd}>
                    {type === "UPDATE" ? 'Update' : 'Add'}
                </PrimaryButton>
            </Box>
        </Box>
    );
}

export default ProductFormComponent