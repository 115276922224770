
import { Box, Grow, Slide } from '@mui/material';
import React from 'react';
import SuggestionBox from './SuggestionBox';

const SearchSuggestionComponent = (props) => {
  const { suggestions, onSuggestionClick } = props;

  return (
    <Grow in={true} timeout={500}>
      <Box
        sx={{
          position: 'sticky',
          width: '100%',
          backgroundColor: 'white',
          boxShadow: '0px 4px 4px rgba(0,0,0,0.3)',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
          {suggestions.map((suggestion, idx) => {
            return (
              <SuggestionBox
                suggestion={suggestion}
                key={idx}
                onSuggestionClick={onSuggestionClick}
              />
            );
          })}
        </Box>
      </Box>
    </Grow>
  );
};

export default SearchSuggestionComponent;
