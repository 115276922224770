

import { Alert, Box, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { putLoggedInStore } from '../../indexeddb/AppContext';
import axios from 'axios';
import baseUrl from '../../constants';
import BackHeader from '../../components/commons/headers/BackHeader';
const AdminPage = () => {
    const router = useNavigate();
    const [adminForm, setAdminForm] = useState({ customerId: '' });
    const [error, setError] = useState('');
    const handleAdminForm = (e) => {
        setAdminForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }
    const handleSendCustomerId = () => {
        axios.defaults.withCredentials = true;
        axios.get(`${baseUrl}stores/${adminForm.customerId}`).then(res => {
            putLoggedInStore(res.data);
            router("/");
        })
            .catch(res => { });
    }

    return (
        <>
            <BackHeader headText={'Admin(Only for company people)'} />
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, px: 2, alignItems: 'center', rowGap: 3 }}>
                <Typography>Admin section</Typography>
                <TextField onChange={handleAdminForm} name='customerId' fullWidth label="Store Id" variant="outlined" />
                <button onClick={handleSendCustomerId} style={{ backgroundColor: 'orange', color: 'white', padding: '8px', borderRadius: '8px' }}>get store</button>
                {
                    error.length > 0 &&
                    <Alert sx={{ width: '100%' }} severity="error">{error}</Alert>
                }
            </Box>
        </>
    );
}

export default AdminPage