import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import productRestService from '../../services/rest/ProductRestService';
import { H2, H4, PrimaryButton, SecondaryButton, UpdateProductCard } from '@lifease/lifease-storeui-components';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { useDialog } from '../../contexts/dialog/DialogContextProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getLoggedInStore } from '../../indexeddb/AppContext';
import ProductModalComponent from '../../components/products/ProductModalComponent';
import { useDrawer } from '../../contexts/drawer/DrawerContextProvider';

const SearchToUpdate = (props) => {

    const { searchWord, store, metrics } = props;
    const { showSnackbar } = useSnackbar();
    const { showDialog, closeDialog } = useDialog();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { openDrawer, closeDrawer } = useDrawer();

    const handleConfirmDialog = async (stockTime, product) => {
        const data = {
            isAvailable: false,
            restockTime: stockTime
        };
        try {
            const store = await getLoggedInStore();
            const response = await productRestService.setProductAvailability(store.partyId, product.id, data);
            setProducts(prev => prev.map(item => (item.id !== product.id) ? item : response));
            closeDialog();
        } catch (err) {
            showSnackbar(err.message);
        }
    }

    const handleCloseDialog = (product) => {
        // getLoggedInStore().then(store => {
        //     const data = {
        //         isAvailable: false
        //     };
        //     productRestService.setProductAvailability(store.partyId, product.id, data).then(response => {
        //         setProducts(prev => prev.map(item => (item.id !== product.id) ? item : response))
        closeDialog();
        //     }).catch(() => { });
        // });
    }

    const DialogTitle = () => <H2 fontSize={'large'}>Product restock time</H2>

    const DialogComponent = (props) => {
        const { product } = props;
        const [stockTime, setStockTime] = useState(null);
        const handleStockChange = (e) => {
            setStockTime(e.toString());
        }
        return <Box sx={{ display: 'flex', flexDirection: 'column', width: 280, rowGap: 2 }}>
            <H4>Add time when product will be in stock</H4>
            <DateTimePicker onAccept={handleStockChange} label={'Restock time(optional)'} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: 2 }}>
                <PrimaryButton color="secondary" variant='contained' onClick={() => { handleConfirmDialog(stockTime, product) }}>Confirm</PrimaryButton>
                <SecondaryButton color="secondary" variant="contained" onClick={() => { handleCloseDialog(product) }}>Cancel</SecondaryButton>
            </Box>
        </Box>
    }

    const handleOnStockChange = async (product, inStock) => {
        if (inStock) {
            try {
                let obj = { isAvailable: inStock };
                const temp = await productRestService.updateProduct(store.partyId, product.id, obj);
                setProducts(prev => prev.map(item => (item.id !== product.id) ? item : temp));
            } catch (err) {
                showSnackbar('error', err.message);
            }
        } else {
            showDialog(() => <DialogTitle />, () => <DialogComponent product={product} />)
        }
    }

    const handleOnButtonClick = async (form, product) => {
        try {
            const newProduct = { ...product, ...form };
            const data = await productRestService.updateProduct(store.partyId, product.id, newProduct);
            setProducts(prev => prev.map(item => (newProduct.id === item.id) ? data : item));
            closeDrawer();
        } catch (err) {
            showSnackbar('error', err.message);
        }
    };

    const handleUpdate = (product) => {
        openDrawer(() => <></>, () => <ProductModalComponent product={product} metrics={metrics} store={store} handleOnButtonClick={handleOnButtonClick} type={"UPDATE"} />);
    }

    useEffect(() => {
        productRestService.storeProductSearch(searchWord, store.partyId).then(res => {
            setProducts(res);
            setLoading(false);
        })
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 2, mb: 14 }}>
            <Grid container columnSpacing={2} rowSpacing={2}>
                {
                    products.map((product, idx) => {
                        return <Grid key={idx} item xs={12} sm={6} md={4}>
                            <UpdateProductCard metrics={metrics} showButton={true} product={product} handleStock={() => { handleOnStockChange(product, !product.available) }} handleUpdate={(product) => { handleUpdate(product) }} />
                        </Grid>
                    })
                }
            </Grid>
        </Container>
    )
}

export default SearchToUpdate