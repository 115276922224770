import { Box, Button, Switch, Typography } from '@mui/material'
import React from 'react'
import { convertUtcTimeToFormat } from '../../utils/luxonUtils';
import { ScheduleType } from '../../utils/enums';

const ScheduleForm = (props) => {
    const { timeSlots, day, editable, onSwitchChange, handleAddEditClick } = props;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', columnGap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 0.5 }}>
                    <Typography fontWeight={600}>{day}</Typography>
                    <Typography sx={{ color: 'gray' }}>|</Typography>
                    <Typography sx={{ color: (timeSlots?.present) ? 'green' : 'red' }}>
                        {(timeSlots.present) ? 'Open' : 'Close'}
                    </Typography>
                </Box>
                {
                    timeSlots.slots.length > 0 &&
                    <Switch checked={timeSlots.present} color='secondary' onChange={(e) => { onSwitchChange(e, day) }} />
                }
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    {
                        timeSlots.slots.length > 0 ?

                            <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: 2 }}>
                                {
                                    timeSlots.slots.map((slot, idx) => {
                                        return <Box key={idx} sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography sx={{ whiteSpace: 'nowrap' }} >
                                                {convertUtcTimeToFormat(slot.startTime, 'hh:mm a')} - {convertUtcTimeToFormat(slot.endTime, 'hh:mm a')}

                                            </Typography>
                                            {slot.type === ScheduleType.RELATIVE &&
                                                <Typography sx={{ whiteSpace: 'nowrap' }}>
                                                    Delivery Time: {parseInt(slot.duration / 60, 10)} minutes
                                                </Typography>
                                            }
                                        </Box>
                                    })
                                }
                            </Box> :
                            <Typography>Timings not available</Typography>
                    }
                </Box>
                <Button
                    variant={'contained'}
                    color='secondary'
                    sx={{ alignSelf: 'flex-end' }}
                    onClick={() => { handleAddEditClick(timeSlots, day) }}
                >
                    {timeSlots.slots.length > 0 ? 'Edit' : 'Add'}
                </Button>

            </Box>
        </Box>
    )
}

export default ScheduleForm