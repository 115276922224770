import { Box, Button, Container, Divider, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getLoggedInStore } from '../../indexeddb/AppContext';
import AddressCard from '../../components/address/AddressCardComponent';
import CustomerCard from '../../components/customer/CustomerDetailsCardComponent';
import shipmentRestService from '../../services/rest/ShipmentRestService';
import addressRestService from '../../services/rest/AddressRestService';
import orderRestService from '../../services/rest/OrderRestService';
import Shipment from '../../components/shipment/ShipmentComponent';
import productRestService from '../../services/rest/ProductRestService';
import paymentRestService from '../../services/rest/PaymentRestService';
import FilledHeader from '../../components/commons/headers/FilledHeader';
import { Card, DeliveryToCard, H4, MessageIcon, OrderCard, OrderFromCard, OrderSummary, ProductCard, ProductQuantity } from '@lifease/lifease-storeui-components';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import CircularLoading from '../../components/commons/circular-progress/CircularLoading';
import OrderSummaryCard from '../../components/orders/OrderSummaryCardComponent';
import getCoordinates from '../../utils/LocationUtil';
import { Device } from '@capacitor/device';
import { AppLauncher } from '@capacitor/app-launcher';
import BackHeader from '../../components/commons/headers/BackHeader';

function ViewOrderDetails() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { store } = useStore();
  const { showSnackbar } = useSnackbar();
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true)
  // const [acceptedShipment, setAcceptedShipment] = useState({
  //   ,
  //   changed: false,
  //   netQuantity: 0,
  // });
  // const {store}
  // const navigate = useNavigate();
  // const { order } = state;
  // const [orderDetails, setOrderDetails] = useState({});
  const [shipmentsData, setShipmentsData] = useState([]);
  // const [deliveryAddress, setDeliveryAddress] = useState({});
  // const [loaded, setLoaded] = useState(false);
  // const [metrics, setMetrics] = useState([]);
  // const [paymentDetails, setPaymentDetails] = useState(null);

  const onShipmentAction = (shipmentData, idx) => {
    const data = [...shipmentsData];
    data[idx] = shipmentData;
    setShipmentsData(data);
  };

  const handleChat = () => {
    navigate('/conversation', { state: { orderDetails: orderData?.orderDetails } });
  };

  const handleError = (err) => {
    setError(true);
    let error = (err?.message === 'null' || !err.message) ? 'Something went wrong' : err.message;
    showSnackbar('error', error);
  }

  const fetchOrders = async () => {
    let obj = {};
    let errorMessage = [];
    // try {
    try {
      const orderDetails = await orderRestService.getOrderDetails(store.partyId, orderId);
      obj.orderDetails = orderDetails;
    } catch (err) {
      errorMessage.push(err);
      // handleError(err);
    }

    try {
      const shipments = await shipmentRestService.getShipmentsForStore(store.partyId, orderId);
      obj.shipments = shipments;
      setShipmentsData(shipments);
    } catch (err) {
      errorMessage.push(err);
      // handleError(err);
    }

    try {
      const metrics = await productRestService.getMetrics();
      obj.metrics = metrics;
    } catch (err) {
      errorMessage.push(err);
      // handleError(err); 
    }

    try {
      if (obj.orderDetails.deliveryType === 'HOME_DELIVERY') {
        const address = await addressRestService.getDeliveyAddress(obj.orderDetails.toPartyId, obj.orderDetails.deliveryAddressId);
        obj.address = address;
      }
    } catch (err) {
      errorMessage.push(err);
      // handleError(err) 
    }

    try {
      const paymentDetails = await paymentRestService.getPaymentDetails(store.partyId, orderId);
      obj.paymentDetails = paymentDetails;
    } catch (err) {
      errorMessage.push(err);
      // handleError(err); 
    }

    try {
      if (obj?.paymentDetails) {
        const provider = await paymentRestService.getProvider(obj.paymentDetails.lineItems[0].providerInfoId);
        obj.provider = provider;
      }
    } catch (err) {
      errorMessage.push(err);
      // handleError(err) 
    }
    setOrderData(obj);
    if (errorMessage.length > 0) {
      handleError(errorMessage[0]);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  // useEffect(() => {
  //   document.title = 'View Order Details';
  //   fetchOrderDetails();
  //   getMetrics();
  // }, []);

  // useEffect(() => fetchDeliveryAddress(), []);
  // useEffect(() => {
  //   function fetchOrderShipments() {
  //     getLoggedInStore().then((store) => {
  //       if (store) {
  //         shipmentRestService
  //           ?.getShipmentsForStore(store.partyId, order?.id)
  //           .then((shipmentsData) => {
  //             setShipmentsData(shipmentsData);
  //           })
  //           .catch((error) => {});
  //       }
  //     });
  //   }
  //   if (order?.id) {
  //     fetchOrderShipments();
  //   }
  // }, [order]);

  function handleNavigateClick() {
    const coordinates = [orderData.address.lat, orderData.address.lng];
    const latlng = coordinates[0] + "," + coordinates[1];
    Device.getInfo()
      .then(info => {
        if (info.operatingSystem === 'android') {
          window.open("google.navigation:q=" + latlng + "&mode=d");
        } else {
          AppLauncher.openUrl({ url: 'https://maps.google.com/?q=' + latlng })
        }

      })
  }

  return (
    <>
      <BackHeader headText={'Order Details'} />
      {
        orderData ?
          <Box>
            <Box sx={{ px: 1, mt: 2, display: 'flex', flexDirection: 'column', rowGap: 1, mb: 14 }}>
              {
                orderData.orderDetails && shipmentsData && orderData.metrics ?
                  <Shipment
                    onShipmentAction={onShipmentAction}
                    shipmentsData={shipmentsData}
                    order={orderData.orderDetails}
                    metrics={orderData.metrics}
                  ></Shipment> :
                  <Skeleton variant='rectangular' height={'60px'} width={'100%'} />
              }
              <Box onClick={handleChat}>
                <Card>
                  <Box sx={{ display: 'flex', columnGap: 1, alignItems: 'center' }}>
                    <MessageIcon fill='orange' height={'20px'} width={'20px'} />
                    <H4>Chat with customer</H4>
                  </Box>
                </Card>
              </Box>
              {
                orderData.orderDetails ?
                  <OrderSummary
                    paymentStatus={orderData.orderDetails.paymentStatus}
                    orderAmount={orderData.orderDetails.totalAmount}
                    deliveryFee={orderData.orderDetails.deliveryFee}
                    discount={orderData.orderDetails?.discount}
                    totalAmount={orderData.orderDetails.totalAmount + orderData.orderDetails.deliveryFee - orderData.orderDetails.discount}
                  /> :
                  <Skeleton variant='rectangular' height={'60px'} width={'100%'} />
              }
              {
                orderData.address ?
                  <DeliveryToCard
                    userName={orderData.orderDetails.customer.customerName ?? ''}
                    address={orderData.address}
                    mobile={orderData.orderDetails.customer.mobile}
                    handleChange={handleNavigateClick}
                  /> :
                  <Skeleton variant='rectangular' height={'60px'} width={'100%'} />
              }
            </Box>
          </Box> :
          <CircularLoading />

      }
    </>
    //   <Container direction='column' maxWidth='md' sx={{ padding: 1 }}>
    //     {loaded ? (
    //       <OrderSummaryCard
    //         paymentDetails={paymentDetails}
    //         key={'summary-' + orderDetails.id}
    //         orderDetails={orderDetails}
    //       ></OrderSummaryCard>
    //     ) : (
    //       <></>
    //     )}
    //     {deliveryAddress.id === undefined ? (
    //       <></>
    //     ) : (
    //       <AddressCard
    //         key={'delivery-address-' + deliveryAddress.id}
    //         address={deliveryAddress}
    //         caption='Customer Address'
    //       ></AddressCard>
    //     )}
    //     <CustomerCard
    //       key={'customer-' + orderDetails.id}
    //       customer={orderDetails.customer}
    //     ></CustomerCard>

    //     { Object.keys(orderDetails).length > 0 && <Button
    //         variant='contained'
    //         sx={{ width: '100%', cursor: 'pointer' }}
    //         onClick={handleChat}
    //       >
    //         Chat with Customer
    //       </Button>}

    //     <Divider />
    //     <Shipment
    //       onShipmentAction={onShipmentAction}
    //       shipmentsData={shipmentsData}
    //       order={order}
    //       metrics={metrics}
    //     ></Shipment>
    //   </Container>
    // );

    // async function getMetrics() {
    //   const metrics = await productRestService.getMetrics();
    //   setMetrics(metrics);
    // }
    // async function fetchOrderDetails() {
    //   try {
    //     const store = await getLoggedInStore();
    //     const orderRes = await orderRestService.getOrderDetails(
    //       store.partyId,
    //       order.id
    //     );
    //     const paymentDetails = await paymentRestService.getPaymentDetails(
    //       store.partyId,
    //       order.id
    //     );
    //     const provider = await paymentRestService.getProvider(
    //       paymentDetails.lineItems[0].providerInfoId
    //     );
    //     setOrderDetails(orderRes);
    //     setPaymentDetails(provider);
    //     setLoaded(true);
    //   } catch {}
    // axios.defaults.withCredentials = true;
    // getLoggedInStore().then(store => {
    //     if (store) {
    //         orderRestService?.getOrderDetails(store.partyId, order.id).then(response => {
    //             setOrderDetails(response);
    //             setLoaded(true);
    //         }).catch(error => {
    //         });
    //     }
    // });
    // }

    // function fetchDeliveryAddress() {
    //   axios.defaults.withCredentials = true;
    //   if (order.deliveryType === 'HOME_DELIVERY') {
    //     addressRestService
    //       ?.getDeliveyAddress(order?.toPartyId, order?.deliveryAddressId)
    //       .then((response) => {
    //         setDeliveryAddress(response);
    //       })
    //       .catch((error) => {});
    //   }
    // }
  )
}

export default ViewOrderDetails;
