import React, { useEffect } from "react";
import BackHeader from "../../components/commons/headers/BackHeader";
import { Box } from "@mui/material";
import AddProductCategoriesGrid from "../../components/product-category/AddProductCategoriesGridComponent";

function AddProductCategory() {

    useEffect(() => {
        document.title = 'add product category';
    }, [])
    return (
        <>
            <BackHeader headText={'Add product categories'} />
            <Box sx={{ mt: 2, px: 2 }}>
                <AddProductCategoriesGrid />
            </Box>
        </>
    );
}

export default AddProductCategory;