import { DateTime } from 'luxon'

export const weekdays = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY'
];

export function getCurrentTime() {
    let currentTime = DateTime.now();
    return currentTime;
}

export function getDateType() {
    return DateTime;
}

export function convertToLuxonDateTime(dateTimeString) {
    return DateTime.fromISO(dateTimeString);
}

export function compareDates(date1, date2) {
    return date1.diff(date2).toObject();
}

export function convertUtcTimeToFormat(time, toFormatString) {
    return DateTime.fromISO(time).toFormat(toFormatString);
}

export function convertTimeToDateObject(time) {
    return DateTime.fromISO(time);
}

export function DateObjectToString(obj) {
    return obj.toFormat('HH:mm').toString();
}

export function convertObjectToUtcTime(timeObject, toFormat) {
    return timeObject.toFormat(toFormat);
}

export function convertDateObjectToString(DateObject) {
    return DateObject.toString();
}

export function convertDateTimeStringToReadeableFormat(dateTimeString) {
    return DateTime.fromISO(dateTimeString).toFormat('hh:mm a EEE yyyy-MM-dd').toString();
}

export function convertToTransactionDate(dateString) {
    return DateTime.fromISO(dateString).toFormat('dd-MM-yy HH:mm');
}

export function convertDateObjectToTimeString(dateObj) {
    return DateTime.fromISO(dateObj).toFormat('hh:mm a');
}

export function getDateObjectFromTime(time) {
    return DateTime.fromFormat(time, 'hh:mma').toISO();
}

export function addHours(time, interval) {
    return time.plus({ hours: interval })
}

export function defaultExportTime(time) {
    return time.toFormat('HH:mmZZ');
}

export function DateObjectCompFn(obj1, obj2) {
    if (!obj1?.shipBefore || !obj2?.shipBefore) return 0;
    const d1 = convertToLuxonDateTime(obj1.shipBefore);
    const d2 = convertToLuxonDateTime(obj2.shipBefore);
    if (d1 === d2) return 0;
    return (d1 > d2) ? 1 : -1;
}

export function DecCompFn(obj1, obj2) {
    return DateObjectCompFn(obj1, obj2);
}

export function IncCompFn(obj1, obj2) {
    return DateObjectCompFn(obj2, obj1);
}
