import { useEffect, useState } from 'react'
import { getLoggedInStore } from '../../indexeddb/AppContext';
import KhataRestService from '../../services/rest/KhataRestService';
import { Box, Button, Card, Skeleton, Typography } from '@mui/material';
import KhataBalanceComponent from '../../components/khata/KhataBalanceComponent';
import RechargeProviders from '../../components/khata/RechargeProviders';
import BackHeader from '../../components/commons/headers/BackHeader';

const ServicesComponent = () => {
    const [balance, setBalance] = useState(null);
    useEffect(() => {
        document.title = 'Services';
        getBalance();
    }, []);


    return (
        <>
            <BackHeader headText={'Balance'} />
            <Box sx={{ mx: 1, mt: 2 }}>
                {
                    balance ?
                        <>
                            <KhataBalanceComponent balance={balance} />
                            <RechargeProviders />
                        </> :
                        <Skeleton height={100} width={'100%'} variant='rectangular' />
                }
            </Box>
        </>
    );

    async function getBalance() {
        try {
            const store = await getLoggedInStore();
            if (store) {
                const res = await KhataRestService.getBalance(store.partyId);
                setBalance(res);
            }
        } catch {

        }
    }
}

export default ServicesComponent