import { Box } from '@mui/material';
import React from 'react'

const IframeComponent = (props) => {
    const { url } = props;
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            {
                url &&
                <iframe src={url} style={{ width: "100%", height: '100%', border: 'none' }}></iframe>
            }
        </Box>
    )
};

export default IframeComponent;