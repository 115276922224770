import { Container, Typography } from "@mui/material";
import React, { useEffect } from "react";

function RegisterRequestSuccess(props) {
  useEffect(() => {
    document.title = "Register Request Success";
  }, []);

  return (
    <Container direction='column' maxWidth='md' sx={{ padding: 1 }}>
      <Typography variant='h4' align='center' component='div' color='secondary'>
        Thank you!!!
      </Typography>
      <Typography
        variant='subtitle1'
        align='center'
        component='div'
        color='primary'
      >
        We have received your request. Your request will be processed within 48
        business hours.
      </Typography>
    </Container>
  );
}

export default RegisterRequestSuccess;
