import { Box, Button, Card, CardMedia, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import productRestService from '../../services/rest/ProductRestService';
import imageRestService from '../../services/rest/ImageRestService';

const ProductMasterComponent = (props) => {
    const { product, getVariant } = props;
    const [image, setImage] = useState({});
    useEffect(() => {
        imageRestService
            .fetchImage(product.imageId)
            .then(res => {
                setImage(res);
            })
    }, []);

    return (
        <Card sx={{ p: 1, display: 'flex', flexDirection: 'column', rowGap: 1, borderRadius:'12px' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: 2, rowGap: 1 }}>
                {
                    image?.url ?
                        <CardMedia component="img" height="120" width="120" sx={{ maxWidth: 120 }} image={image.url} alt={product.categoryName} /> :
                        <Skeleton variant="rectangular" height={120} width={120} />
                }
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography gutterBottom fontSize={'large'}>
                        {product.productName}
                    </Typography>
                    <Typography gutterBottom fontSize={'small'} color={'primary'}>
                        {product.brand}
                    </Typography>
                    <Typography gutterBottom fontSize={'small'} >
                        {product?.description}
                    </Typography>
                </Box>

            </Box>
            {
                image.url &&
                <Button onClick={()=>{getVariant(product, image.url);}} color='secondary' sx={{ width: '100%' }} variant='contained'>Add product</Button>
            }
        </Card>
    )
}

export default ProductMasterComponent