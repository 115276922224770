import React, { useEffect, useState } from 'react'
import ConfirmPaymentProgressBar from '../../components/confirm-payment/ConfirmPaymentProgressBar'
import { useParams, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import paymentRestService from '../../services/rest/PaymentRestService';
import KhataRestService from '../../services/rest/KhataRestService';
import { getLoggedInStore } from '../../indexeddb/AppContext';
import PhonePeComponent from '../../components/confirm-payment/PhonePeComponent';

const ConfirmPayment = () => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [provider, setProvider] = useState(null);
    const [paymentDetails, SetPaymentDetails] = useState(null);
    const [store, setStore] = useState(null);

    useEffect(() => {
        getProviderInfo();
    }, []);

    return (
        <Box sx={{ width: '100vw', height: '100vh', mx: 'auto' }}>
            {
                paymentDetails && store ?
                    <Box sx={{ width: '100%', height: '100%' }}>
                        {
                            provider.providerName === 'PHONE_PE' &&
                            <PhonePeComponent store={store} paymentDetails={paymentDetails} />
                        }
                    </Box> :
                    <ConfirmPaymentProgressBar />
            }
        </Box>
    );

    async function getProviderInfo() {
        try {
            const provider = await paymentRestService.getProviderInfoById(params.providerId);
            const store = await getLoggedInStore();
            const data = {
                amount: searchParams.get('amount'),
                providerId: params.providerId,
                fromPartyId: store.partyId,
                mobileNumber: store.mobile
            }
            const paymentDetails = await KhataRestService.getRechargeDetails(store.partyId, data);
            setStore(store);
            setProvider(provider);
            SetPaymentDetails(paymentDetails);
        } catch {

        }
    }
}

export default ConfirmPayment