import { BrandPrimaryButton, Dropdown, H2, PrimaryButton, TextInput } from '@lifease/lifease-storeui-components'
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React, { useState } from 'react'
import { ScheduleType } from '../../utils/enums'
import WeekScheduleComponent from '../../components/commons/week-schedule/WeekScheduleComponent';
import ViewSchedule from '../../components/commons/week-schedule/ViewSchedule';
import { useDialog } from '../../contexts/dialog/DialogContextProvider';
import { convertScheduleToScheduleRequest } from '../../components/delivery-options/splitHoursUtil';

const Data = [
    { value: 'SELF_PICKUP', label: 'Self pickup' },
    { value: 'HOME_DELIVERY', label: 'Home delivery' }
];

const DeliveryOptionForm = (props) => {
    const { deliveryOption = null, schedule = null, handleDeliveryOptionChange } = props;
    const { showDialog, closeDialog } = useDialog();
    const [customSchedule, setCustomSchedule] = useState(schedule);
    let initialForm = { orderAmount: '', deliveryFee: '', distance: '', deliveryType: 'HOME_DELIVERY' }
    if (deliveryOption) {
        initialForm.orderAmount = deliveryOption.minOrderAmount;
        initialForm.deliveryFee = deliveryOption.deliveryFee;
        initialForm.distance = deliveryOption.distance;
        initialForm.deliveryType = deliveryOption.deliveryType;
    }

    const [form, setForm] = useState(initialForm);
    const [deliveryType, setDeliveryType] = useState(customSchedule?.type ?? ScheduleType.RELATIVE);

    const handleChange = (e) => {
        setForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleClear = (name) => {
        setForm(prev => ({ ...prev, [name]: '' }));
    };

    const onScheduleAdd = (data) => {
        const schedule = convertScheduleToScheduleRequest(data, deliveryType);
        setCustomSchedule(schedule);
        closeDialog();
    }

    const handleAddSchedule = () => {
        showDialog(() => <span>Add Schedule</span>, () => <WeekScheduleComponent schedule={customSchedule} ScheduleType={deliveryType} handleScheduleSubmit={onScheduleAdd} />)
    }

    const handleViewSchedule = () => {
        showDialog(() => <span>Schedule</span>, () => <ViewSchedule schedule={customSchedule} />)
    }
    const disableConfirm = () => {
        if (!customSchedule || form.deliveryFee?.length === 0 || form.deliveryType?.length === 0 || form.orderAmount?.length === 0) return true;
        if (parseInt(form.orderAmount) < 0) return true;
        if (parseInt(form.deliveryFee) < 0) return true;
        if (parseInt(form.distance) < 0) return true;
        return false;
    }

    const handleConfirm = () => {
        handleDeliveryOptionChange({ ...form, weekSchedule: customSchedule, minOrderAmount: form.orderAmount })
    }





    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1.5, px: 2, mt: 4 }}>
            <Dropdown items={Data} name={'deliveryType'} onSelect={(e) => { setForm(prev => ({ ...prev, deliveryType: e })) }} value={form.deliveryType} label={'Delivery Type'} />
            <TextInput handleClear={() => { handleClear('orderAmount') }} value={form.orderAmount} label='Order amount' name='orderAmount' onChange={handleChange} />
            <TextInput handleClear={() => { handleClear('deliveryFee') }} value={form.deliveryFee} label={'Delivery fee'} name='deliveryFee' onChange={handleChange} />
            <TextInput handleClear={() => { handleClear('distance') }} value={form.distance} label={'Distance(in metre)'} name='distance' onChange={handleChange} />
            <RadioGroup onChange={(e) => { setDeliveryType(e.target.value) }} value={deliveryType}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <FormControlLabel value={ScheduleType.RELATIVE} control={<Radio />} label={<H2>Express delivery</H2>} />
                    <FormControlLabel value={ScheduleType.ABSOLUTE} control={<Radio />} label={<H2>Absolute delivery</H2>} />
                </Box>
            </RadioGroup>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
                <PrimaryButton onClick={handleAddSchedule}>{schedule ? 'Edit' : 'Add'} Schedule</PrimaryButton>
                {
                    schedule &&
                    <PrimaryButton onClick={handleViewSchedule}>View Schedule</PrimaryButton>
                }
            </Box>
            <BrandPrimaryButton disabled={disableConfirm()} onClick={() => { handleConfirm(form) }}>Confirm</BrandPrimaryButton>
            {/* <TextInput handleClear={() => { handleClear('orderAmount') }} value={form.orderAmount} label={'Min order amount'} name='orderAmount' onChange={handleChange} />
            <TextInput handleClear={() => { handleClear('deliveryFee') }} value={form.deliveryFee} label={'Delivery fee'} name='deliveryFee' onChange={handleChange} />
            <TextInput handleClear={() => { handleClear('distance') }} value={form.distance} label={'Distance(in metre)'} name='distance' onChange={handleChange} />
            <RadioGroup onChange={(e) => { setDeliveryType(e.target.value) }} value={deliveryType}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <FormControlLabel value={ScheduleType.RELATIVE} control={<Radio />} label="Express Delivery" />
                    <FormControlLabel value={ScheduleType.ABSOLUTE} control={<Radio />} label="Scheduled Delivery" />
                </Box>
            </RadioGroup>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
                <PrimaryButton onClick={handleAddSchedule}>Add Schedule</PrimaryButton>
                {
                    schedule &&
                    <PrimaryButton onClick={handleViewSchedule}>View Schedule</PrimaryButton>
                }
            </Box>
            <BrandPrimaryButton disabled={disableConfirm()} onClick={() => { handleConfirm(form) }}>Confirm</BrandPrimaryButton> */}
        </Box>
    )
}

export default DeliveryOptionForm