import { Alert, Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react'
import CreateSchedule from './ScheduleCommon/CreateSchedule';
import { ScheduleLabel, ScheduleType } from '../utils/enums';
import DeliveryScheduleTabs from './ScheduleCommon/DeliveryTabs';
import ScheduleList from './ScheduleCommon/ScheduleList';


const SchedulePage = (props) => {
    const { schedule, editable = false, scheduleOption, InitializeSchedule, onSwitchChange, handleAddEditClick, handleSave, loading = false } = props;
    const [option, setOption] = useState(ScheduleType.ABSOLUTE);

    return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}>
            <Typography ml={1} fontSize={'large'} fontWeight={600}>Schedule</Typography>
            {
                scheduleOption === ScheduleLabel.DELIVERY && !schedule &&
                <DeliveryScheduleTabs option={option} setOption={setOption} />
            }
            {
                schedule ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                        <ScheduleList editable={editable} schedule={schedule} onSwitchChange={onSwitchChange} handleAddEditClick={handleAddEditClick} />
                        <Box sx={{
                            display: 'block', position: 'sticky', bottom: 16, left: 0, backgroundColor: 'white', m: 0, mb: 2,
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                        }}>
                            <Button disabled={loading} variant='contained' size='small' sx={{ width: '100%' }} onClick={() => { handleSave() }}>
                                Save Schedule
                            </Button>
                        </Box>
                    </Box> :
                    <CreateSchedule scheduleType={option} InitializeSchedule={InitializeSchedule} error={' '} />
            }
        </Box>
    )
}


export default SchedulePage