import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { convertToTransactionDate } from '../../utils/luxonUtils';

const ServiceTypeText = {
    'ORDER': 'Order',
    'KHATA_RECHARGE': 'Recharge',
    'LOGISTICS_BOOKING': 'Delivery'
};

const BillingTableComponent = (props) => {
    const { data, loading } = props;
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Transaction date</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Mode</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        data.map((item, idx) => {
                            return (
                                loading ?
                                    <TableRow key={idx}>
                                        <TableCell size='small'>
                                            <Typography>
                                                Loading
                                            </Typography>
                                        </TableCell>
                                        <TableCell size='small'>
                                            Loading
                                        </TableCell>
                                        <TableCell size='small'>
                                            Loading
                                        </TableCell>
                                    </TableRow> :
                                    <TableRow key={idx}>
                                        <TableCell size='small'>
                                            <Typography>
                                                {
                                                    convertToTransactionDate(item.transactionDate)
                                                }
                                            </Typography>
                                        </TableCell>
                                        <TableCell size='small'>
                                            {
                                                item.operationType === 'CREDIT' ?
                                                    <Typography sx={{ color: 'green' }}>+{item.amount}</Typography> :
                                                    <Typography sx={{ color: 'red' }}>-{item.amount}</Typography>
                                            }
                                        </TableCell>
                                        <TableCell size='small'>
                                            {ServiceTypeText[item.serviceEntity]}
                                        </TableCell>
                                    </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default BillingTableComponent