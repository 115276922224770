import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Grid, Button, Container, Card, CardActionArea, Alert, CircularProgress, Snackbar,
} from '@mui/material';
import { Upload } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

import FileRenderer from './FileRenderer';
import { H1 } from '@lifease/lifease-storeui-components';
import { useDialog } from '../../contexts/dialog/DialogContextProvider';
import conversationRestService from '../../services/rest/conversationsRestService';
import ImageCropper from '../conversation/ImageCropper';
import BackHeader from '../../components/commons/headers/BackHeader';

const AddAttachment = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cropData, setCropData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(''); 
  const [openSnackbar, setOpenSnackbar] = useState(false); 
  const { showDialog, closeDialog } = useDialog();
  const location = useLocation();
  const params = location.state;
  const storeId = params.customerId;
  const conversationId = params.conversationId;
  const navigate = useNavigate();

  const fetchAllFiles = async (storeId) => {
    setLoading(true);
    try {
      const res = await conversationRestService.getfiles(storeId);
      setFiles(res);
    } catch (error) {
      setError(error.message);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async (imageId) => {
    try {
      const res = await conversationRestService.sendMessage(conversationId, storeId, '', imageId);
      if (res) {
        setSelectedFile(null);
        navigate(-1);
      }
    } catch (error) {
      
      setError(error.message);
      setOpenSnackbar(true);
    }
  };

  const handleUpload = () => {
    showDialog(
      () => <H1>Edit</H1>,
      () => (
        <ImageCropper
          setCropData={setCropData}
          storeId={storeId}
          getAllFiles={fetchAllFiles}
          setError={setError} 
          setOpenSnackbar={setOpenSnackbar} 
        />
      ),
    );
  };

  const handleImageSelect = (file) => {
    setSelectedFile(file);
  };

  const handleSendSelectedImage = () => {
    if (selectedFile) {
      sendMessage(selectedFile.fileId);
    }
  };

  useEffect(() => {
    if (storeId) {
      fetchAllFiles(storeId);
    }
  }, [storeId]);

  useEffect(() => {
    if (cropData) {
      closeDialog();
    }
  }, [cropData]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <BackHeader headText="Your Files" />
      <Box sx={{ pt: 1, p: 2 }}>
        <Alert severity="info">
          Select the image to send
        </Alert>
      </Box>
      <Container maxWidth="lg" sx={{ mb: 8, p: 4, pb: 14 }}> 
        <Typography variant="h5" gutterBottom>
          Uploaded Images
        </Typography>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              {files.length > 0 ? (
                files.map((file) => (
                  <Grid item key={file.fileId} xs={6} sm={6} md={4} lg={3} sx={{ display: 'flex' }}>
                    <Card
                      sx={{
                        border: selectedFile?.fileId === file.fileId ? '2px solid blue' : 'none',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      onClick={() => handleImageSelect(file)}
                    >
                      <CardActionArea sx={{ flex: 1 }}>
                        <FileRenderer file={file} storeId={storeId}/>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Typography sx={{ p: 4 }} variant="body1" color="textSecondary">
                  No files uploaded yet.
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Container>
      <Box sx={{
        position: 'fixed',
        bottom: 80,
        left: 0,
        right: 0,
        px: 4,
        py: 2,
        bgcolor: 'background.paper',
        boxShadow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Upload />}
          onClick={handleUpload}
        >
          Upload File
        </Button>
        {selectedFile && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSendSelectedImage}
          >
            Send
          </Button>
        )}
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={error}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
        sx={{
          top: 6, 
        }}
      />
    </>
  );
};

export default AddAttachment;
