import { ArrowButton, PrimaryButton } from '@lifease/lifease-storeui-components';
import { Box } from '@mui/material';
import React from 'react';
import TimerComponent from '../../login/TimerComponent';

const VerifySendToggleButtons = (props) => {
    const { onButtonClick, verify, disabled, timerFunction } = props;
    return (
        <>
            {
                verify ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
                        <ArrowButton disabled={disabled} onClick={onButtonClick}>Verify OTP</ArrowButton>
                        <Box sx={{ alignSelf: 'flex-end' }}>
                            <TimerComponent timerFunction={timerFunction} />
                        </Box>
                    </Box> :
                    <ArrowButton disabled={disabled} onClick={onButtonClick}>Send OTP</ArrowButton>
            }
        </>
    )
}

export default VerifySendToggleButtons