import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Avatar,
  Stack,
  Alert,
  Typography,
  Box
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { getLoggedInStore } from '../../indexeddb/AppContext';
import catalogue from '../../services/rest/storeCatalogueRestService';
import BackHeader from '../../components/commons/headers/BackHeader';

const StatusList = () => {
  const [products, setProducts] = useState(null);
  const [Status, setStatus] = useState('CREATED');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const handleFilterChange = (event) => {
    setStatus(event.target.value);
  };

  const fetchProducts = async (Status) => {
    try {
      setLoading(true);
      const res = await getLoggedInStore();
      const response = await catalogue.GetCatalogueStatue(res.partyId, Status);

      const productsWithImages = await Promise.all(
        response.map(async (product) => {
          const imageResponse = await catalogue.fetchImage(product.imageId);
          return { ...product, image: imageResponse.url };
        })
      );

      setProducts(productsWithImages);

      if (response.length === 0) {
        setError('No Products Catalogue Found');
        setTimeout(() => {
          setError('');
        }, 5000)
        return;
      }
      setError('');
    } catch (error) {
      setError(error);
      setTimeout(() => {
        setError('');
      }, 5000)
    }
    finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    document.title = 'Status List';
    fetchProducts(Status);
  }, [Status])

  return (
    <>
      <BackHeader headText={'Status List'} />
      <Box sx={{ mt: 2, mb: 14 }}>
        <Stack direction="row" justifyContent="end" mr={1}>
          <FilterAltIcon fontSize='large' />
          <FormControl >
            <Select
              value={Status}
              onChange={handleFilterChange}
              sx={{ height: 40 }}
            >
              <MenuItem value="CREATED">Created</MenuItem>
              <MenuItem value="APPROVED">Approved</MenuItem>
              <MenuItem value="REJECTED">Rejected</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {error && <Alert severity="error">{error}</Alert>}
        {loading ? <Typography textAlign={'center'}>
          Loading...
        </Typography> : <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image ID</TableCell>
                <TableCell>Sub-Category</TableCell>
                <TableCell>Product Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((product) => (
                <TableRow key={product?.id}>
                  <TableCell>
                    <Avatar alt={product.productName} src={product.image} />
                  </TableCell>
                  <TableCell>{product?.subCategory}</TableCell>
                  <TableCell>{product?.productName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
      </Box>
    </>
  );
};

export default StatusList;
