import { Box, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ScheduleLabel, ScheduleType } from '../../utils/enums'
import { useLocation, useNavigate } from 'react-router-dom'
import { convertObjectToUtcTime, weekdays } from '../../utils/luxonUtils'
import deliveryOptionRestService from '../../services/rest/DeliveryOptionRestService'
import storeScheduleRestService from '../../services/rest/StoreScheduleRestService'
import SchedulePage from '../../common/SchedulePage'
import { useSnackbar } from '../../contexts/SnackbarProvider'
import BackHeader from '../../components/commons/headers/BackHeader'

// function getInitialScheduleList(weekdays) {
//     const schedule = {};
//     weekdays.forEach(day => {
//         schedule[day] = { slots: [], present: false };
//     })
//     return schedule;
// }

// function convertStateToResponseSchedule(storeSchedule) {
//     let requestBody = { daySchedules: [] };
//     for (const key in storeSchedule.data) {
//         if (storeSchedule.data[key].present) {
//             let daySchedule = {};
//             daySchedule.dayOfWeek = key;
//             let slots = [];
//             for (const slot of storeSchedule.data[key].slots) {
//                 let obj = { type: slot.type, startTime: slot.startTime, endTime: slot.endTime };
//                 if (slot.type === 'RELATIVE') {
//                     obj.duration = slot.duration;
//                 }
//                 slots.push(obj);
//             }
//             daySchedule.timeSlots = slots;
//             requestBody.daySchedules.push(daySchedule);
//         }
//     }
//     return requestBody;

// }

// function transformStoreSchedule(responseSchedule, slots) {
//     if (!responseSchedule) return null;
//     const daySchedules = responseSchedule.daySchedules;
//     const localSchedule = getInitialScheduleList(weekdays);
//     daySchedules.forEach(daySchedule => {
//         const day = daySchedule.dayOfWeek;
//         localSchedule[day] = { slots: daySchedule.timeSlots, present: true };
//     });
//     for (const key in localSchedule) {
//         if (slots && key === slots?.day) {
//             localSchedule[key] = { slots: slots.timeSlots, present: true };
//         }
//     }
//     return localSchedule;
// }

const DeliverySchedule = () => {
    const [deliveryOptions, setDeliveryOptions] = useState({loading:true, data:null});
    // const { state } = useLocation();
    // const { editable } = state;
    // const [error, setError] = useState('');
    // const [type, setType] = useState(ScheduleType.ABSOLUTE);
    // const [loading, setLoading] = useState(false)
    // const [deliveryOption, setDeliveryOption] = useState({ loading: false, data: null });
    // const navigate = useNavigate();
    // const { showSnackbar } = useSnackbar();
    useEffect(() => {
        document.title = 'delivery schedule';
        // getWeekSchedule();
    }, []);

    // const InitializeSchedule = async (Timings, option) => {
    //     for (const currentTimings of Timings) {
    //         if (!(currentTimings.startTime && currentTimings.endTime)) {
    //             return;
    //         }
    //         if (option === ScheduleType.RELATIVE) {
    //             if (!currentTimings.duration) return;
    //         }
    //     }
    //     let TimeData = [];
    //     for (const time of Timings) {
    //         let object = {
    //             type: option,
    //             startTime: convertObjectToUtcTime(time.startTime, 'HH:mmZZ'),
    //             endTime: convertObjectToUtcTime(time.endTime, 'HH:mmZZ'),
    //         }
    //         if (option === ScheduleType.RELATIVE) {
    //             object.duration = parseInt(time.duration, 10);
    //         }
    //         TimeData.push(object);
    //     }
    //     const stateDeliveryOption = state?.deliveryOption;
    //     const weekSchedule = weekdays.map((day, index) => {
    //         const absoluteValues = {
    //             dayOfWeek: day,
    //             timeSlots: TimeData
    //         }

    //         return absoluteValues;
    //     })
    //     const { id, weekScheduleId, storePartyId, ...rest } = stateDeliveryOption;
    //     const newDeliveryOption = {
    //         ...rest,
    //         weekSchedule: { daySchedules: weekSchedule }
    //     };
    //     try {
    //         setError('');
    //         setDeliveryOption({ loading: true, data: transformStoreSchedule(newDeliveryOption.weekSchedule) });
    //         await deliveryOptionRestService.putDeliveryOption(
    //             state.deliveryOption.id,
    //             state.deliveryOption.storePartyId,
    //             newDeliveryOption
    //         );
    //         setDeliveryOption({ loading: false, data: transformStoreSchedule(newDeliveryOption.weekSchedule) });
    //     } catch (err) {

    //         setDeliveryOption({ loading: false, data: null });
    //         setError(err);
    //     }
    // }

    // const onSwitchChange = (val, day) => {
    //     setDeliveryOption(prevSchedule => ({
    //         ...prevSchedule,
    //         data: {
    //             ...prevSchedule.data,
    //             [day]: {
    //                 ...prevSchedule.data[day],
    //                 present: val.target.checked
    //             }
    //         }
    //     }));
    // }

    // const handleAddEditClick = (timeSlots, day) => {
    //     navigate('/edit', { state: { timeSlots, day, redirectTo: '/store-settings/delivery-options/schedule', type: type, deliveryOption: state?.deliveryOption, action: state?.action }, replace: true });
    // }

    // const handleSave = async () => {
    //     setLoading(true);
    //     if (state?.action) {
    //         const data = {
    //             weekSchedule: {
    //                 daySchedules: convertStateToResponseSchedule(deliveryOption)['daySchedules'],
    //                 type: type
    //             },
    //             distance: state.deliveryOption.distance,
    //             deliveryType: state.deliveryOption.deliveryType,
    //             minOrderAmount: state.deliveryOption.minOrderAmount,
    //             deliveryFee: state.deliveryOption.deliveryFee,
    //             deliveryMode: state.deliveryOption.deliveryMode
    //         }
    //         try {
    //             await deliveryOptionRestService.putDeliveryOption(state?.deliveryOption.id, state.deliveryOption.storePartyId, data);
    //             navigate('/store-settings/delivery-options', { replace: true })
    //         } catch (err) {
    //             showSnackbar('error', err.message);
    //         } finally {
    //             setLoading(false)
    //         }

    //     }
    // }

    return (
        <>
            <BackHeader headText={'Delivery options'} />
            
            {
                // deliveryOption.loading ?
                //     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                //         <Typography color={'primary'}>
                //             Loading delivery schedule...
                //         </Typography>
                //     </Box> :
                //     <Paper elevation={1} sx={{ mx: 1, display: 'flex', rowGap: 1, flexDirection: 'column', mt: 2, mb: 14 }}>
                //         <SchedulePage
                //             loading={loading}
                //             schedule={deliveryOption.data}
                //             editable={editable}
                //             scheduleOption={ScheduleLabel.DELIVERY}
                //             InitializeSchedule={InitializeSchedule}
                //             onSwitchChange={onSwitchChange}
                //             handleAddEditClick={handleAddEditClick}
                //             handleSave={handleSave}
                //         />
                //     </Paper>
            }
        </>
    );
    // async function getWeekSchedule() {
    //     try {
    //         setDeliveryOption({ loading: true, data: null });
    //         const weekSchedule = await storeScheduleRestService.getWeekSchedule(state.deliveryOption.weekScheduleId);
    //         setType(weekSchedule.type);
    //         setDeliveryOption({ loading: false, data: transformStoreSchedule(weekSchedule, state?.slots) })
    //     } catch {
    //         setDeliveryOption({ loading: false, data: null })
    //     }
    // }
}

export default DeliverySchedule