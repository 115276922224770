import React, { useEffect, useState } from 'react';
import BackHeader from '../../components/commons/headers/BackHeader';
import conversationRestService from '../../services/rest/conversationsRestService';
import MessageRow from './MessageRow';
import { Paper, Table, TableBody, TableContainer, TableHead, CircularProgress, Box } from '@mui/material';
import { getLoggedInStore } from '../../indexeddb/AppContext';

const StoreConversation = () => {
  const [messages, setMessages] = useState(null);
  const [storeId, setStoreId] = useState(null);

  const getAllStoreMessage = async () => {
    try {
      const store = await getLoggedInStore();
      const res = await conversationRestService.getAllStoreMessage(store.partyId);
      setMessages(res);
      setStoreId(store.partyId);
    } catch (error) {
      console.error('Failed to fetch messages', error);
    }
  };

  useEffect(() => {
    getAllStoreMessage();
  }, []);

  return (
    <>
      <BackHeader headText={'Store conversation'} />
      {messages === null ? (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
            </TableHead>
            <TableBody>
              {messages.map((message) => (
                <MessageRow key={message.id} message={message} storeId={storeId}/>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default StoreConversation;
