import baseUrl from "../../constants";
import authFetch from "../../utils/interceptor";

const StoreRegistrationRestService = {
    registerStore: async (data) => {
        const url = new URL(`${baseUrl}stores`);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            credentials: 'include'
        }
        try {
            const response = await authFetch(url, options);
            return await response.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    getStoreTypes: async () => {
        const url = new URL(`${baseUrl}store-types`);
        try {
            const response = await authFetch(url);
            return await response.json();
        } catch (err) {
            throw new Error(err.message);
        }
    }
};

export default StoreRegistrationRestService;