import { PrimaryButton } from '@lifease/lifease-storeui-components';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'

const TimerComponent = (props) => {
    const { timerFunction } = props;
    const countdown = 30; //in seconds
    const [timer, setTimer] = useState({ start: false, count: countdown });

    const handleResendClick = () => {
        timerFunction();
        const startTime = new Date().getTime();
        const interval = setInterval(() => {
            if (new Date().getTime() - startTime <= countdown * 1000) {
                setTimer(prevTimer => ({
                    start: true,
                    count: prevTimer.count - 1
                }));
            } else {
                setTimer({
                    start: false,
                    count: countdown
                })
                clearInterval(interval);
                return;
            }

        }, 1000);
    }

    return (
        <PrimaryButton size="small" color="primary" variant="contained" onClick={handleResendClick} disabled={timer.start} sx={{ textTransform: 'none' }}>
            {
                !timer.start ?
                    <Typography color={'white'}>
                        Resend OTP
                    </Typography> :
                    <Typography color={'gray'} fontSize={'0.9rem'} component={'span'}>
                        Resend again in 00:{timer.count.toString().padStart(2, '0')}
                    </Typography>
            }

        </PrimaryButton>
    )
}

export default TimerComponent;