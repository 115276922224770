import { ScheduleType } from "../../utils/enums";
import { addHours, convertObjectToUtcTime, convertToLuxonDateTime, defaultExportTime } from "../../utils/luxonUtils";

export function splitHours(startTime = '2023-03-08T04:30:00.000Z', endTime = '2023-03-08T15:30:00.000Z', interval = 1) {
    const startDate = convertToLuxonDateTime(startTime);
    const endDate = convertToLuxonDateTime(endTime);
    let data = [];
    let j = convertToLuxonDateTime(startTime);
    j = addHours(j, interval);
    for (let i = startDate; i < endDate; i = addHours(i, interval), j = addHours(j, interval)) {
        data.push({
            startTime: defaultExportTime(i),
            endTime: defaultExportTime(j),
            type: ScheduleType.ABSOLUTE
        })
    }
    return data;
}

export function splitHoursRelative(slot, duration) {
    return { ...slot, duration, type: ScheduleType.RELATIVE };
}

export function splitHoursAbsolute(slot, duration) {
    const startTime = convertToLuxonDateTime(slot.startTime);
    const endTime = convertToLuxonDateTime(slot.endTime);
    let data = [];
    let j = convertToLuxonDateTime(startTime);
    j = addHours(j, duration);
    for (let i = startTime; j < endTime; i = addHours(i, duration), j = addHours(j, duration)) {
        data.push({
            startTime: defaultExportTime(i),
            endTime: defaultExportTime(j),
            type: ScheduleType.ABSOLUTE
        })
    }
    return data;
}

export function convertScheduleToScheduleRequest(data, type = ScheduleType.ABSOLUTE) {
    let daySchedules = [];
    for (const item in data) {
        if (data[item].present) {
            let slots = [];
            for (const slot of data[item].slots) {
                let obj = {}
                obj.startTime = convertObjectToUtcTime(slot.startTime, "HH:mmZZ");
                obj.endTime = convertObjectToUtcTime(slot.endTime, "HH:mmZZ");
                obj.type = type;
                if (type === ScheduleType.RELATIVE) {
                    obj.duration = parseInt(slot.duration, 10) * 60;
                }
                slots.push(obj);
            }
            daySchedules.push({
                dayOfWeek: data[item].day,
                timeSlots: slots
            })
        }
    }

    const schedule = {
        type: type,
        daySchedules: daySchedules
    };

    return schedule;
}