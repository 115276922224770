import React, { useEffect, useState } from 'react';
import { ONBOARDING_TYPES } from '../../constants';
import { Box } from '@mui/material';
import OnboardingItem from './OnboardingItem';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import addressRestService from '../../services/rest/AddressRestService';
import { useNavigate } from 'react-router-dom';
import storeScheduleRestService from '../../services/rest/StoreScheduleRestService';
import { H2, PrimaryButton } from '@lifease/lifease-storeui-components';
import storeManagementRestService from '../../services/rest/StoreManagementRestService';
import { putLoggedInStore } from '../../indexeddb/AppContext';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import HelpSupport from '../commons/help-&-support/HelpSupport';
const OnboardingList = [
    {
        displayName: 'Store name and number',
        commited: true,
        type: ONBOARDING_TYPES.REGISTER
    },
    {
        displayName: 'Store address',
        commited: false,
        type: ONBOARDING_TYPES.LOCATION
    },
    {
        displayName: 'Store open and close timings',
        commited: false,
        type: ONBOARDING_TYPES.TIMINGS
    },
    {
        displayName: 'Store delivery options',
        commited: false,
        type: ONBOARDING_TYPES.DELIVERY
    },
    {
        displayName: 'Approval Pending',
        commited: false,
        type: ONBOARDING_TYPES.APPROVAL,
    }
];

const OnboardingListComponent = () => {
    const navigate = useNavigate();
    const [onboardingList, setOnboardingList] = useState(OnboardingList);
    const [currIdx, setCurrIdx] = useState(1);
    const { store } = useStore();
    const { showSnackbar } = useSnackbar();

    const onNextClick = () => {
        switch (currIdx) {
            case 1:
                navigate('/add-address');
                break;
            case 2:
                navigate('/store-timings');
                break;
            case 3:
                navigate('/delivery-options');
            default:
        }
    }

    const checkStoreActiveStatus = async () => {
        try {
            const storeRes = await storeManagementRestService.getStoreById(store.partyId);
            if (storeRes.status === "ACTIVE") {
                putLoggedInStore(storeRes);
                showSnackbar('success', "Congratulations, now you are listed on lifease marketplace");
                navigate('/');
            } else {
                showSnackbar('warning', "Review pending");
            }

        } catch (err) {

        }
    }

    useEffect(() => {
        checkStoreStatus();
    }, []);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, mt: 2 }}>
                {
                    onboardingList.map((item, index) => <OnboardingItem key={item.type} completed={currIdx > index} displayName={item.displayName} />)
                }
                {
                    currIdx <= 3 &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <PrimaryButton onClick={onNextClick}>Continue</PrimaryButton>
                    </Box>
                }
                {
                    currIdx > 3 &&
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4, mb: 4 }}>
                        <H2 style={{ marginTop: '12px', textAlign: 'center' }}>Thank you for registering your store!!!</H2>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <PrimaryButton onClick={checkStoreActiveStatus}>Check status</PrimaryButton>
                        </Box>
                    </Box>
                }
            </Box>
            <HelpSupport />
        </Box>
    );

    async function checkAddress() {
        let address;
        try {
            address = await addressRestService.getStoreAddress(store.partyId);
        } catch { }
        return address;
    }

    async function checkStoreTimings() {
        let schedule;
        try {
            schedule = await storeScheduleRestService.getStoreSchedule(store?.partyId);
        } catch { }
        return schedule;
    }

    async function checkDeliveryOptions() {
        let deliveryOptions;
        try {
            deliveryOptions = await storeScheduleRestService.getDeliveryOptions(store?.partyId);
            if (deliveryOptions?.length === 0) {
                return null;
            }
        } catch { }
        return deliveryOptions;
    }

    async function checkStoreStatus() {
        let tempIdx = currIdx;
        const address = await checkAddress();
        if (address) {
            tempIdx = 2;
        } else {
            setCurrIdx(tempIdx);
            return;
        }
        const schedule = await checkStoreTimings();
        if (schedule) {
            tempIdx = 3;
        } else {
            setCurrIdx(tempIdx);
            return;
        }
        const deliveryOptions = await checkDeliveryOptions();
        if (deliveryOptions) {
            tempIdx = 4;
        } else {
            setCurrIdx(tempIdx);
            return;
        }
        setCurrIdx(tempIdx);
    }


    // async function checkAddress() {
    //     let address;
    //     try {
    //         address = await addressRestService.getStoreAddress(store.partyId);
    //         if (store?.addressId > 0 || address) {
    //             const newOnboardingList = [...onboardingList];
    //             newOnboardingList[currIdx].commited = true;
    //             setOnboardingList(newOnboardingList);
    //             setCurrIdx(prev => prev + 1);
    //         } else {
    //             navigate('/add-address');
    //         }
    //     } catch {
    //         navigate('/add-address');
    //     }

    // }

    // async function checkStoreTimings() {
    //     try {
    //         const schedule = await storeScheduleRestService.getStoreSchedule(store?.partyId);
    //         if (schedule) {
    //             const newOnboardingList = [...onboardingList];
    //             newOnboardingList[currIdx].commited = true;
    //             setOnboardingList(newOnboardingList);
    //         } else {
    //             navigate('/store-timings');
    //         }
    //     } catch {

    //     }
    // }

    // async function checkDeliveryOptions() {
    //     try {
    //         const deliveryOptions = await storeScheduleRestService.getDeliveryOptions(store?.partyId);
    //     } catch {
    //         navigate('/delivery-options');
    //     }
    // }

    // async function checkStoreStatus() {
    //     try {
    //         // await checkAddress();
    //         await checkStoreTimings();
    //         // await checkDeliveryOptions();
    //     } catch {

    //     }
    // }
}

export default OnboardingListComponent