//@ts-nocheck

import axios from "axios";
import { getToken } from "../indexeddb/AppContext";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const authFetch = async (...args) => {
    axios.defaults.withCredentials = true;
    const [url, options] = args;
    const response = await fetch(url, options);
    if (response.ok) {
        return response;
    }

    if (response.status === 401) {
        const token = await getToken();
        if (!token) {
            throw new Error('User not logged in');
        }
        const tokenUrl = new URL(`${baseUrl}auth/login/customer/token`);
        const data = { token };
        // axios.defaults.withCredentials = true;
        try {
            // Todo: convert to Fetch, reason: giving cors error
            const res = await axios.post(tokenUrl, data);
            if (res) {
                const resp = await fetch(response.url, options);
                if (resp.ok) {
                    return resp;
                }
                throw new Error((await resp.json()).message);
            }
        } catch (err) {
            throw new Error(err.message);
        }
    }
    throw new Error((await response.json()).message);
}

export default authFetch;