import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  Alert
} from "@mui/material";
import catalogue from "../../services/rest/storeCatalogueRestService";
import { getLoggedInStore } from "../../indexeddb/AppContext";
import { useNavigate } from "react-router";

const ProductDetails = ({ cropData }) => {
  const [formData, setFormData] = useState({
    categoryId: null,
    brand: "",
    productName: "",
    type: "",
    mrp: 0,
    sellingPrice: 0,
    subCategory: "",
    metricsId: null,
    metricsValue: 0,
    description: "",
  });

  const [metricsData, setMetricsData] = useState([]);
  const [categoryIdList, setCategoryIdList] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const fileData = new FormData();
      fileData.append("file", cropData);
      const response = await catalogue.ImageUpload(fileData);
      const res = await catalogue.SetCatalogueConfig(
        {
          categoryId: formData.categoryId,
          brand: formData.brand,
          productName: formData.productName,
          type: formData.type,
          mrp: formData.mrp,
          subCategory: formData.subCategory,
          imageId: response.id,
          metricsId: formData.metricsId,
          description: formData.description,
          sellingPrice: formData.sellingPrice,
          metricsValue: formData.metricsValue,
          storeId: storeId,
        },
        storeId
      );
      setError("");
      navigate(-1);
    } catch (error) {
      handleError(error);
    }
  };

  const getCategoryIdList = async () => {
    try {
      const res = await getLoggedInStore();
      const catRes= await catalogue.GetCategoryId({ storeId: res.partyId });
      const MetRes = await catalogue.GetMetrics();
      setMetricsData(MetRes);
      setStoreId(res.partyId);
      setCategoryIdList(catRes);
      setFormData((prevFormData) => ({
        ...prevFormData,
        categoryId: catRes.length > 0 ? catRes[0].id : null,
        metricsId: MetRes.length > 0 ? MetRes[0].id : null,
      }));
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 5000);
  };

  useEffect(() => {
    getCategoryIdList();
  }, []);

  return (
    <>
      <Typography component="h5" color="gray" fontWeight={400} sx={{ mb: 2 }}>
        Product Form
      </Typography>
      <form
        style={{ display: "flex", flexDirection: "column", rowGap: "1rem" }}
        onSubmit={handleSubmit}
      >
        <Box>
          <Typography required sx={{ marginBottom: "0.5rem" }}>
            CategoryId
          </Typography>
          <Select
            value={formData.categoryId}
            onChange={handleChange("categoryId")}
          >
            {categoryIdList.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.categoryName}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField
          required
          label="Brand"
          value={formData.brand}
          onChange={handleChange("brand")}
        />
        <TextField
          required
          label="Product Name"
          value={formData.productName}
          onChange={handleChange("productName")}
        />
        <TextField
          required
          label="Type"
          value={formData.type}
          onChange={handleChange("type")}
        />
        <TextField
          required
          label="MRP"
          type="number"
          value={formData.mrp}
          onChange={handleChange("mrp")}
        />
        <TextField
          required
          label="Selling Price"
          value={formData.sellingPrice}
          onChange={handleChange("sellingPrice")}
        />
        <TextField
          required
          label="Sub Category"
          value={formData.subCategory}
          onChange={handleChange("subCategory")}
        />
        <TextField
          required
          label="Metrics Value"
          type="number"
          value={formData.metricsValue}
          onChange={handleChange("metricsValue")}
        />
        <Box>
          <Typography required sx={{ marginBottom: "0.5rem" }}>
            Metrics
          </Typography>
          <Select
            value={formData.metricsId}
            onChange={handleChange("metricsId")}
          >
            {metricsData.map((metric) => (
              <MenuItem key={metric.id} value={metric.id}>
                {metric.displayText}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField
          required
          label="Description"
          multiline
          rows={4}
          value={formData.description}
          onChange={handleChange("description")}
        />
        {error && <Alert severity="error">{error}</Alert>}
        <Button
          variant="contained"
          sx={{ color: "white" }}
          color="secondary"
          type="submit"
        >
          Save Changes
        </Button>
      </form>
    </>
  );
};

export default ProductDetails;
