import { TimePicker } from '@mui/x-date-pickers'
import { convertToLuxonDateTime } from '../../utils/luxonUtils';

const TimePickerComponent = (props) => {
    const { value, onChange, name = '', disabled, timings, ...rest } = props;
    const dateObject = convertToLuxonDateTime()
    const handleChange = (val, context) => {
        // onChange(val, name);
        onChange(val, name)
    }
    return (
        <TimePicker
            sx={{ maxWidth: '160px' }}
            name={name}
            value={value}
            onChange={handleChange}
            {...rest}
        />
    )
}

export default TimePickerComponent;