import React, { useContext, useState } from 'react'
import ProductDetailsContext from './ProductDetailsContext';

const ProductDetailsHideProvider = (props) => {
    const [productDetailsHide, setProductDetailsHide] = useState({
        leadTime: false
    });
    return (
        <ProductDetailsContext.Provider value={{ productDetailsHide, setProductDetailsHide }}>
            {props.children}
        </ProductDetailsContext.Provider>
    );
}

export default ProductDetailsHideProvider;

export const useProductDetailsHide = () => useContext(ProductDetailsContext);