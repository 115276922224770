/**
 * Method to format text in readable format
 * @param {*} text 
 * @param {*} separator character after which to apply the rule
 * @param {*} changeCase formatting rule
 * @returns 
 */
import { Share } from "@capacitor/share";

export function formatText(text, separator = "", changeCase = 'Title') {
    if (!text) {
        return '';
    }
    let result = '';
    switch (changeCase) {
        case 'Title':
            result = text.toLowerCase().split(separator).map(subText => {
                return (subText.charAt(0).toUpperCase() + subText.slice(1));
            }).join(" ");
            break;
        default:
            break;
    }
    return result;
}

/**
 * Method to extract coordinates as value from string
 * @param {*} point string containing the coordinates.
 */
export function extractCoordinates(point) {
    const regex = /\((.*?)\)/;
    const coordinatesString = point?.match(regex);
    const coordinates = coordinatesString ? coordinatesString[1].split(' ') : null;
    let latitude = parseFloat(coordinates?.[0]) || 0;
    let longitude = parseFloat(coordinates?.[1]) || 0;
    return [latitude, longitude];
}

/**
 * To return color based on the state
 * @param {*} state 
 * @returns state color value
 */
export function getStateColor(state) {
    let stateColor = 'primary';
    switch (state) {
        case 'CREATED':
            stateColor = 'action';
            break;
        case 'CANCELLED':
            stateColor = 'danger';
            break;
        case 'DELIVERED':
            stateColor = 'success';
            break;
        case 'COMPLETED':
            stateColor = 'success';
            break;
        default:
            stateColor = "action"
            break;
    }
    return stateColor;
}

/**
 * To return action resulting in a state
 * @param {*} state 
 * @returns 
 */
export function getActionFromState(state) {
    let action;
    switch (state) {
        case "CANCELLED":
            action = "CANCEL";
            break;
        case "SHIPPED":
            action = "SHIP";
            break;
        default:
            action = state.slice(0, -2);
            break;
    }
    return action;
}

export default async function ShareData(shareInfo) {
    try {
        const canShare = await Share.canShare();
        if (canShare) {
            await Share.share(shareInfo);
        }
    } catch (err) {
    }
}