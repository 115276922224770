import { Box, duration } from '@mui/material';
import React, { useState } from 'react'
import DaySlotComponent from './DaySlotComponent';
import { PrimaryButton, SecondaryButton } from '@lifease/lifease-storeui-components';

const DayScheduleComponent = (props) => {
    const { selectedDays, onChange, days, addSlot, setSteps, handleAutoDatePopulate, ScheduleType } = props;
    const [dayOfWeek, setDayOfWeek] = useState(0);
    const [autoTiming, setAutoTiming] = useState(false);

    const onNextButtonClick = () => {
        if (autoTiming) {
            const slots = selectedDays[days[0]].slots;
            for (const data in selectedDays) {
                selectedDays[data].slots = slots;
            }
            handleAutoDatePopulate(selectedDays);
        } else {
            if (dayOfWeek == days?.length - 1) {
                setSteps(prev => prev + 1);
            } else {
                setDayOfWeek(prev => prev + 1);
            }
        }
    }

    const onPrevButtonClick = () => {
        if (dayOfWeek == 0) {
            setSteps(prev => prev - 1);
        } else {
            setDayOfWeek(prev => prev - 1);
        }
    }

    const onTimeChange = (day, type, val, idx) => {
        onChange(day, type, val, idx);
    }

    const disableNext = () => {
        let disable = false;
        for (const slot of selectedDays[days[dayOfWeek]].slots) {
            if (slot.startTime === null || slot.endTime === null || slot?.duration?.length <= 0) {
                disable = true;
            }
        }
        return disable;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
            <DaySlotComponent ScheduleType={ScheduleType} setAutoTiming={setAutoTiming} autoTiming={autoTiming} dayOfWeek={dayOfWeek} addSlot={addSlot} handleTimeChange={onTimeChange} selectedDay={selectedDays[days[dayOfWeek]]} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <SecondaryButton onClick={onPrevButtonClick}>Prev</SecondaryButton>
                <PrimaryButton disabled={disableNext()} onClick={onNextButtonClick}>Next</PrimaryButton>
            </Box>
        </Box>
    )
}

export default DayScheduleComponent