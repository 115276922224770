import {
  ArrowButton,
  H3,
  OrderStateCardStack,
  StoreCard,
} from '@lifease/lifease-storeui-components';
import { Box, IconButton, Button } from '@mui/material';
import MapsUgcIcon from '@mui/icons-material/MapsUgc'; // Import the icon
import React, { useEffect } from 'react';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { addHours, getCurrentTime } from '../../utils/luxonUtils';
import ShareData from '../../utils/commons';
import conversationRestService from '../../services/rest/conversationsRestService';

const FiltersOptions = {
  PossibleDelay: 'CREATED,ACCEPTED,PACKED,SHIPPED',
  Delayed: 'CREATED,ACCEPTED,PACKED,SHIPPED',
  Processing: 'ACCEPTED,PACKED,SHIPPED',
  NewOrder: 'CREATED',
};

const OrderStatComponent = () => {
  const { store } = useStore();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {}, []);
  const handleClick = () => {
    navigate('/view-orders?filter=CREATED');
  };

  const handleFilteredStates = (e, count) => {
    searchParams.set('filter', FiltersOptions[e]);
    const currentTime = getCurrentTime();
    if (e === 'Delayed') {
      searchParams.set('shipBefore', currentTime);
    } else if (e === 'PossibleDelay') {
      const newTime = addHours(currentTime, 0.5);
      searchParams.set('shipBefore', newTime);
    }
    if (count > 0) navigate('/view-orders?' + searchParams);
  };
  const handleViewProductCategories = () => {
    navigate('view-product-categories/store-catalogue');
  };

  const handleShareStore = () => {
    let productionMode = process.env.REACT_APP_NODE_ENV;
    let baseUrl = '';
    if (productionMode === 'development') {
      baseUrl = 'https://dev-ui.lifease.in/';
    } else {
      baseUrl = 'https://www.lifease.in/';
    }
    const url = new URL(`${baseUrl}select-category`);
    url.searchParams.set('storeId', store.partyId);
    url.searchParams.set('storeName', store.storeName);
    const titleText = `${store.storeName} goes online`;
    ShareData({
      title: titleText,
      url: url.toString(),
      text: titleText,
      dialogTitle: `Quality products, discover now at ${store.storeName}`,
    });
  };

  const handleMessages = () => {
    navigate('store-conversation');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2,
        px: 2,
        mt: 2,
        position: 'relative',
      }}
    >
      {store && (
        <>
          <StoreCard store={store} handleShare={handleShareStore} />
          <Button
            variant='contained'
            color='primary'
            onClick={handleViewProductCategories}
          >
            <H3>Add own catalogue</H3>
          </Button>
          <OrderStateCardStack
            storeId={store?.partyId}
            filterState={handleFilteredStates}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Box sx={{ width: 'fit-content' }}>
              <ArrowButton onClick={handleClick} style={{ padding: '12px' }}>
                View all orders
              </ArrowButton>
            </Box>
          </Box>
          <IconButton
            onClick={handleMessages}
            sx={{
              position: 'fixed',
              bottom: 100,
              right: 0,
              marginLeft: 'auto',
            }}
          >
            <MapsUgcIcon sx={{ fontSize: 40, color: 'green' }} />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default OrderStatComponent;
