import { Box } from '@mui/material'
import React from 'react'
import ViewProductCard from '../products/ViewProductCardComponent';

const VariantList = (props) => {
    const { variants, metrics } = props;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1, alignItems: 'center' }}>
            {
                variants.map((variant, idx) => (
                    <ViewProductCard product={variant} metrics={metrics} key={idx} />
                ))
            }
        </Box>
    )
}

export default VariantList