import { Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../constants";
import { getLoggedInStore } from "../../indexeddb/AppContext";
import UserCard from "./UserCard";
import CircularLoading from "../commons/circular-progress/CircularLoading";

function UsersGrid(props) {
    const [users, setUsers] = useState({ store: null, users: [] });
    useEffect(() => { fetchUsers(); }, []);

    const handleRemoveUser = (idx, user) => {
        const res = users.users.splice(idx, 1);
        if (user.mobile === res[0].mobile) {
            setUsers({ store: true, users: users.users });
        }
    }

    return (
        <Grid container rowSpacing={{ xs: 1 }} columnSpacing={2} columns={{ xs: 12 }}>
            {users.users.map((user, index) => (
                <Grid key={"grid-item-" + user.id} xs={12} item>
                    <UserCard key={"user-card-" + user.id} index={index} user={user} store={users.store} handleRemoveUser={handleRemoveUser}></UserCard>
                </Grid>
            ))}
            {
                !users.store &&
                <CircularLoading />
            }
        </Grid>
    );

    function fetchUsers() {
        getLoggedInStore().then(store => {
            if (store !== undefined) {
                axios.get(baseUrl + "stores/" + store.partyId + "/users").then(response => {
                    setUsers({ store: store, users: response.data });
                });
            }
        }).catch(err => {
            setUsers({ store: 'err', users: [] })
        })
    }
}

export default UsersGrid;