import ReactGA from 'react-ga4';

export const initializeReactGA = (key) => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
        ReactGA.initialize(key);
    }
}

export const setReactGA = (key, value) => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
        ReactGA.set({ [key]: value });
    }
}