import React, { useEffect, useState } from 'react'
import BackHeader from '../../components/commons/headers/BackHeader'
import { useSearchParams } from 'react-router-dom'
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import SearchToUpdate from './SearchToUpdate';
import productRestService from '../../services/rest/ProductRestService';
import CircularLoading from '../../components/commons/circular-progress/CircularLoading';
import SearchToAdd from './SearchToAdd';

const SearchComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { store } = useStore();
    const searchWord = searchParams.get('searchWord');
    const type = searchParams.get('type');
    const [metrics, setMetrics] = useState([]);
    useEffect(() => {
        productRestService.getMetrics().then(res => {
            setMetrics(res);
        })
    }, []);
    return (
        <>
            <BackHeader supTitle={'Search results for'} subTitle={searchWord} />
            {
                metrics.length > 0 ?
                    <>
                        {
                            type === "UPDATE" &&
                            <SearchToUpdate store={store} searchWord={searchWord} metrics={metrics} />
                        }
                        {
                            type === "ADD" &&
                            <SearchToAdd store={store} searchWord={searchWord} metrics={metrics} />
                        }
                    </> :
                    <CircularLoading />
            }
        </>
    )
}

export default SearchComponent