import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ScheduleLabel, ScheduleType } from '../../utils/enums';
import storeScheduleRestService from '../../services/rest/StoreScheduleRestService';
import deliveryOptionRestService from '../../services/rest/DeliveryOptionRestService';
import { convertObjectToUtcTime, weekdays } from '../../utils/luxonUtils';
import SchedulePage from '../../common/SchedulePage';
import { Box, Paper, Typography } from '@mui/material';
import BackHeader from '../commons/headers/BackHeader';

function transformStoreSchedule(responseSchedule, slots) {
    if (!responseSchedule) return null;
    const daySchedules = responseSchedule.daySchedules;
    const localSchedule = getInitialScheduleList(weekdays);
    daySchedules.forEach(daySchedule => {
        const day = daySchedule.dayOfWeek;
        localSchedule[day] = { slots: daySchedule.timeSlots, present: true };
    });
    for (const key in localSchedule) {
        if (slots && key === slots?.day) {
            localSchedule[key] = { slots: slots.timeSlots, present: true };
        }
    }
    return localSchedule;
}

function getInitialScheduleList(weekdays) {
    const schedule = {};
    weekdays.forEach(day => {
        schedule[day] = { slots: [], present: false };
    })
    return schedule;
}

function convertStateToResponseSchedule(storeSchedule) {
    let requestBody = { daySchedules: [] };
    for (const key in storeSchedule.data) {
        if (storeSchedule.data[key].present) {
            let daySchedule = {};
            daySchedule.dayOfWeek = key;
            let slots = [];
            for (const slot of storeSchedule.data[key].slots) {
                slots.push({ type: slot.type, startTime: slot.startTime, endTime: slot.endTime });
            }
            daySchedule.timeSlots = slots;
            requestBody.daySchedules.push(daySchedule);
        }
    }
    return requestBody;

}

const AddDeliverySchedule = () => {
    const { state } = useLocation();
    const [error, setError] = useState('');
    const [type, setType] = useState(ScheduleType.ABSOLUTE)
    const [deliveryOption, setDeliveryOption] = useState({ loading: false, data: null });
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'add delivery schedule';
        getWeekSchedule();
    }, []);

    const onSwitchChange = (val, day) => {
        setDeliveryOption(prevSchedule => ({
            ...prevSchedule,
            data: {
                ...prevSchedule.data,
                [day]: {
                    ...prevSchedule.data[day],
                    present: val.target.checked
                }
            }
        }));
    }

    const handleAddEditClick = (timeSlots, day) => {
        navigate('/edit', { state: { timeSlots, day, redirectTo: '/add-delivery-option/schedule', type: type, deliveryOption: state?.deliveryOption, action: state?.action }, replace: true });
    }

    const InitializeSchedule = async (Timings, option) => {
        for (const currentTimings of Timings) {
            if (!(currentTimings.startTime && currentTimings.endTime)) {
                return;
            }
            if (option === ScheduleType.RELATIVE) {
                if (!currentTimings.duration) return;
            }
        }
        let TimeData = [];
        for (const time of Timings) {
            let object = {
                type: option,
                startTime: convertObjectToUtcTime(time.startTime, 'HH:mmZZ'),
                endTime: convertObjectToUtcTime(time.endTime, 'HH:mmZZ'),
            }
            if (option === ScheduleType.RELATIVE) {
                object.duration = parseInt(time.duration, 10);
            }
            TimeData.push(object);
        }
        const weekSchedule = weekdays.map((day, index) => {
            const absoluteValues = {
                dayOfWeek: day,
                timeSlots: TimeData
            }

            return absoluteValues;
        });
        if (!state?.deliveryOption) {
            navigate('/add-delivery-option', { state: { weekSchedule, type: option }, replace: true })
        } else {

        }
    }

    const handleSave = async () => {
        if (state?.deliveryOption) {
            try {
                const weekScheduleData = convertStateToResponseSchedule(deliveryOption)['daySchedules'];
                let optionType = weekScheduleData[0].timeSlots[0].type;
                const data = {
                    weekSchedule: {
                        daySchedules: weekScheduleData,
                        type: optionType
                    },
                    distance: state.deliveryOption.distance,
                    deliveryType: state.deliveryOption.deliveryType,
                    minOrderAmount: state.deliveryOption.minOrderAmount,
                    deliveryFee: state.deliveryOption.deliveryFee,
                    deliveryMode: state.deliveryOption.deliveryMode
                }
                const res = await deliveryOptionRestService.putDeliveryOption(state?.deliveryOption.id, state.deliveryOption.storePartyId, data);
                navigate('/add-delivery-option', {
                    state: {
                        deliveryOption: res, weekSchedule: {
                            daySchedules: convertStateToResponseSchedule(deliveryOption)['daySchedules'],
                            type: deliveryOption.data.type
                        }
                    }
                })
            } catch {
                navigate('/store-settings/delivery-options', { replace: true })
            } finally {
            }
        }
    }

    return (
        <>
            <BackHeader headText={'Delivery Schedule'} />
            {
                deliveryOption.loading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography color={'primary'}>
                            Loading delivery schedule...
                        </Typography>
                    </Box> :
                    <Paper elevation={1} sx={{ mx: 1, display: 'flex', rowGap: 1, flexDirection: 'column', mt: 2, mb: 14 }}>
                        <SchedulePage
                            schedule={deliveryOption?.data}
                            editable={false}
                            scheduleOption={ScheduleLabel.DELIVERY}
                            InitializeSchedule={InitializeSchedule}
                            onSwitchChange={onSwitchChange}
                            handleAddEditClick={handleAddEditClick}
                            handleSave={handleSave}
                        />
                    </Paper>
            }
        </>
    );



    async function getWeekSchedule() {
        setDeliveryOption({ loading: true, data: null });
        if (state?.deliveryOption) {
            const weekSchedule = await storeScheduleRestService.getWeekSchedule(state.deliveryOption.weekScheduleId);
            setType(weekSchedule.type);
            setDeliveryOption({ loading: false, data: transformStoreSchedule(weekSchedule, state?.slots) })
        } else {
            setDeliveryOption({ loading: false, data: null });
        }
    }
}

export default AddDeliverySchedule