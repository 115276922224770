import { Box } from '@mui/material'
import React from 'react'
import { Header } from '@lifease/lifease-storeui-components';
import { useNavigate } from 'react-router-dom';
const FilledHeader = (props) => {
    const { headText, backUrl = '/login-register' } = props;
    const navigate = useNavigate();
    return (
        <Box sx={{ position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 10 }}>
            <Header regular={true} title={headText} handleBack={() => { navigate(backUrl) }} />
        </Box>
    )
}

export default FilledHeader