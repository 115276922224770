import { ArrowButton, PrimaryButton } from '@lifease/lifease-storeui-components';
import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';

const KhataBalanceComponent = (props) => {
    const navigate = useNavigate();
    const { balance } = props;
    const getStatusColor = (balance) => {
        if (balance > 100) {
            return 'green';
        } else if (balance > 0) {
            return 'orange';
        } else {
            return 'red';
        }
    }
    const handleBalanceSheetClick = () => {
        navigate('/billing');
    }
    return (
        <Card elevation={1} sx={{ p: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', rowGap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5 }}>
                    <Typography color={'primary'}>Balance {'(in credits)'}</Typography>
                    <Typography fontSize={'2rem'} sx={{ color: getStatusColor(balance.balance) }}>{balance.balance}</Typography>
                </Box>
                <Box sx={{ display: 'flex', width: 'fit-content', alignItems: 'flex-end', mb: 1 }}>
                    <ArrowButton onClick={handleBalanceSheetClick} style={{ paddingLeft: '14px', paddingRight: '14px' }}>Ledger</ArrowButton>
                </Box>
            </Box>
        </Card>
    )
}

export default KhataBalanceComponent