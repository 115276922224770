import { Box, TextField } from '@mui/material';
import React from 'react'
import TimePickerComponent from './TimePicker';
import { convertToLuxonDateTime } from '../../utils/luxonUtils';
import { ScheduleType } from '../../utils/enums';

const TimeSlot = (props) => {
    const { type, startTime, endTime, duration = '', index, onSlotChange } = props;

    const onChange = (val, name) => {
        onSlotChange(val, name, index);
    }

    return (
        <form>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, flexWrap: 'wrap', rowGap: 1, mb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
                    <TimePickerComponent label={'Start Time'} name={'startTime'} value={convertToLuxonDateTime(startTime)} onChange={onChange} />
                    <TimePickerComponent label={'End Time'} name={'endTime'} value={convertToLuxonDateTime(endTime)} onChange={onChange} />
                    {
                        type === ScheduleType.RELATIVE &&
                        <TextField
                            onChange={(e) => { onSlotChange(e.target.value, e.target.name, index) }}
                            required
                            type='number'
                            name='duration'
                            label="duration"
                            value={duration}
                            multiline
                        />
                    }
                </Box>
            </Box>
        </form>
    )
}

export default TimeSlot