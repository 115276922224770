import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import KhataRestService from '../../services/rest/KhataRestService';
import paymentRestService from '../../services/rest/PaymentRestService';
import ProviderDisplayComponent from './ProviderDisplayComponent';

const RechargeProviders = () => {
    const navigate = useNavigate();
    const [providers, setProviders] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [amount, setAmount] = useState('');

    const onChangeRechargeProviders = (info) => {
        setSelectedProvider(info.target.value);
    }

    const handleRecharge = () => {
        navigate(`/confirm-payment/${selectedProvider}?amount=${amount}`);
    }



    useEffect(() => {
        fetchProviders();
    }, []);

    return (
        <Accordion sx={{ mt: 1 }} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon color='primary' />}>
                <Typography color={'primary'}>
                    Recharge options
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    providers ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                            <ProviderDisplayComponent providers={providers} onChangeProvider={onChangeRechargeProviders} />
                            <Box sx={{ display: 'flex', columnGap: 1 }}>
                                <TextField value={amount} onChange={(e) => { setAmount(e.target.value) }} label={'Amount in rupees'} type='number'></TextField>
                                <Button disabled={!selectedProvider || amount <= 0} onClick={handleRecharge} sx={{ width: 'fit-content', alignSelf: 'flex-end' }} variant='contained' color='secondary'>Recharge</Button>
                            </Box>
                        </Box> :
                        <Typography>Loading payment options...</Typography>
                }
            </AccordionDetails>
        </Accordion>
    );

    async function fetchProviders() {
        try {
            const providersList = await KhataRestService.getProviders();
            let providerInfoList = [];
            for (const providerId of providersList) {
                const providerInfo = await paymentRestService.getProviderInfoById(providerId);
                providerInfoList.push(providerInfo);
            }
            setProviders(providerInfoList);
        } catch {

        }
    }
}

export default RechargeProviders