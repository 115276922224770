import { Alert, Box, Button, IconButton, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ScheduleType } from '../../utils/enums';

const CreateSchedule = (props) => {
    const { InitializeSchedule, error, scheduleType, scheduleOption } = props;
    const [timings, setTimings] = useState([{ startTime: null, endTime: null }]);
    const changeHandler = (val, name, index) => {
        const newArr = [...timings];
        if (name === 'duration') {

            newArr[index][name] = val * 60;
        } else {
            newArr[index][name] = val
        }
        setTimings(newArr);
    }
    const handleDelete = (index) => () => {
        const newArr = [...timings];
        newArr.splice(index, 1);
        setTimings(newArr);
    }
    const addClickHandler = (index) => {
        setTimings((prev) => prev.concat({ startTime: null, endTime: null }))
    }

    const onSaveSchedule = (timings) => {
        for (const timing of timings) {
            if (!timing.startTime || !timing.endTime) {
                return;
            }
        }
        InitializeSchedule(timings, scheduleType);
    }
    return (
        <Box sx={{ my: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', columnGap: 2, rowGap: 1, mb: 1 }}>
                {
                    timings.map((time, index) => {
                        return <Box key={index} sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                            <Box sx={{ display: 'flex', columnGap: 1 }}>
                                <TimePicker
                                    sx={{ maxWidth: '160px' }}
                                    name={'startTime'}
                                    label={'Start time'}
                                    value={time.startTime}
                                    onChange={(val, ctx) => { changeHandler(val, 'startTime', index) }}
                                />
                                <TimePicker
                                    sx={{ maxWidth: '160px' }}
                                    name={'endTime'}
                                    label={'End time'}
                                    value={time.endTime}
                                    onChange={(val, ctx) => { changeHandler(val, 'endTime', index) }}
                                />
                                <IconButton onClick={handleDelete(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                            {
                                scheduleType === ScheduleType.RELATIVE &&
                                <TextField required type='number' name='duration' onChange={(e) => { changeHandler(e.target.value, e.target.name, index) }} label={'Delivery in mins'} sx={{ mb: 1, width: 'fit-content' }} />
                            }
                        </Box>
                    })
                }
            </Box>
            <Button variant='contained' sx={{ width: 'fit-content', my: 1 }} size='small' onClick={addClickHandler}>
                Add slot
            </Button>
            <Button variant="contained" size='small' onClick={() => { onSaveSchedule(timings) }}>
                Create Schedule
            </Button>
        </Box>
    )
}

export default CreateSchedule