import baseUrl from "../../constants";
import authFetch from "../../utils/interceptor";

/**
 * All Rest call related to Address
 */
const addressRestService = {
    getDeliveyAddress: async (customerId, deliveryAddressId) => {
        let url = `${baseUrl}customers/${customerId}/addresses/${deliveryAddressId}`;
        const options = {
            method: 'GET',
            credentials: 'include'
        }
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    getStoreAddress: async (storeId) => {
        const url = new URL(`${baseUrl}stores/${storeId}/address`);
        const options = {
            method: 'GET',
            credentials: 'include'
        };
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    },
    postStoreAddress: async (storeId, address) => {
        const url = new URL(`${baseUrl}stores/${storeId}/address`);
        const options = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(address),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    }
}

export default addressRestService;