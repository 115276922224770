import React from 'react'
import OrderStatComponent from '../../components/order-stats/OrderStatComponent'
import { LoginHeader } from '@lifease/lifease-storeui-components'

const OrderStats = () => {
    return (
        <>
            <LoginHeader />
            <OrderStatComponent />
        </>
    )
}

export default OrderStats