import authFetch from "../../utils/interceptor";

const paymentBaseUrl = process.env.REACT_APP_PAYMENTS_BASE_URL;

const BillingRestService = {
    getBalanceSheet: async (accountId, pageNo) => {
        const url = new URL(`${paymentBaseUrl}account/${accountId}?pageNo=${pageNo}&pageSize=50`);
        const options = {
            method: 'GET',
            credentials: 'include'
        };
        try {
            const res = await authFetch(url, options);
            return await res.json();
        } catch (err) {
            throw new Error(err.message);
        }
    }
};

export default BillingRestService;