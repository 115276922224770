//@ts-nocheck

import authFetch from '../../utils/interceptor';
import baseUrl from '../../constants';

const conversationRestService = {
  getConversation: async (customerId, storeId, orderId,type) => {
    const url = new URL(`${baseUrl}conversations`);
    url.searchParams.set('partyId1', customerId);
    url.searchParams.set('partyId2', storeId);
    if (orderId) {
      url.searchParams.set('typeIdentifier', orderId);
    }
    url.searchParams.set('conversationType', type);
    const options = {
      method: 'GET',
      credentials: 'include',
    };
    const res = await authFetch(url, options);
    if (res.ok) {
      return await res.json();
    }
    throw new Error(res);
  },
  getAllStoreMessage: async (storeId) => {
    const url = new URL(`${baseUrl}conversations/partyId/${storeId}`);
    url.searchParams.set('type', 'CHAT');
    const options = {
      method: 'GET',
      credentials: 'include',
    };
    const res = await authFetch(url, options);
    if (res.ok) {
      return await res.json();
    }
    throw new Error(res);
  },
  initialConversation: async (customerId, storeId, orderId,type) => {
    const url = new URL(`${baseUrl}conversation`);
    url.searchParams.set('partyId1', customerId);
    const data = {
      partyId1: customerId,
      partyId2: storeId,
      typeIdentifier: orderId?orderId:0,
      type: type,
    };
    const options = {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await authFetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    throw new Error((await response.json()).message);
  },
  getAllMessages: async (conversationId, customerId, page) => {
    const url = new URL(`${baseUrl}conversations/${conversationId}/messages`);
    url.searchParams.set('fromPartyId', customerId);
    url.searchParams.set('pageNo', page);
    const options = {
      method: 'GET',
      credentials: 'include',
    };
    const res = await authFetch(url, options);
    if (res.ok) {
      return await res.json();
    }
    throw new Error(res);
  },
  fileUpload: async (file, storeId, displayName) => {
    const url = `${baseUrl}stores/${storeId}/files`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append(
      'fileRequest',
      new Blob([JSON.stringify({ displayName: displayName })], {
        type: 'application/json',
      })
    );

    const options = {
      method: 'POST',
      credentials: 'include',
      body: formData,
    };

    const response = await authFetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    throw new Error(await response.text());
  },
  sendMessage: async (conversationId, customerId, Text, imageId) => {
    const url = new URL(`${baseUrl}conversations/${conversationId}/message`);
    url.searchParams.set('fromPartyId', customerId);

    const data = {
      text: Text.length > 0 ? Text : null,
      fileId: imageId ? imageId : null,
      type: imageId ? 'IMAGE' : 'TEXT',
    };

    const options = {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await authFetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    throw new Error((await response.json()).message);
  },
  getfiles: async (storeId) => {
    const url = new URL(`${baseUrl}stores/${storeId}/files`);
    const options = {
      method: 'GET',
      credentials: 'include',
    };
    const response = await authFetch(url, options);
    if (response.ok) {
      return await response.json();
    }
    throw new Error((await response.json()).message);
  },
};
export default conversationRestService;
