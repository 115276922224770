import { Alert, Button, Container, MenuItem, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import baseUrl from "../../constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLoggedInStore } from "../../indexeddb/AppContext";
import EastIcon from '@mui/icons-material/East';
import deliveryOptionRestService from "../../services/rest/DeliveryOptionRestService";
import storeScheduleRestService from "../../services/rest/StoreScheduleRestService";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import BackHeader from "../../components/commons/headers/BackHeader";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import CircularLoading from "../../components/commons/circular-progress/CircularLoading";
import DeliveryOptionForm from "./DeliveryOptionForm";

function AddDeliveryOption() {

    const { store } = useStore();
    const { showSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const [deliveryOption, setDeliveryOption] = useState(null);
    const [schedule, setSchedule] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchDeliveryOptions = async () => {
        if (params.deliveryOptionId > 0) {
            setLoading(true);
            deliveryOptionRestService.getDeliveryOptionById(store.partyId, params.deliveryOptionId).then(res => {
                const deliveryOption = JSON.parse(res);
                setDeliveryOption(deliveryOption);
                if (deliveryOption.weekScheduleId) {
                    storeScheduleRestService.getWeekSchedule(deliveryOption.weekScheduleId).then(response => {
                        setSchedule(response);
                        setLoading(false);

                    }).catch((err) => { showSnackbar('error', err.message) });
                }
            }).catch(err => { showSnackbar('error', err.message) })
        } else {
            setLoading(false);
        }
    }

    const handleDeliveryOptionChange = async (deliveryOption) => {
        try {
            if (schedule) {
                await deliveryOptionRestService.putDeliveryOption(params.deliveryOptionId, store.partyId, deliveryOption);
                showSnackbar('success', "Delivery option saved successfully");
            } else {
                await deliveryOptionRestService.createDeliveryOption(store.partyId, deliveryOption);
                showSnackbar('success', "Delivery option added successfully");
            }
            navigate(-1);
        } catch (err) {
            showSnackbar('error', err.message);
        }
    }

    // const { state } = useLocation();
    // const deliveryTypes = ["HOME_DELIVERY", "SELF_PICKUP"];
    // const [loading, setLoading] = useState(false);
    // const [inputs, setInputs] = useState({
    //     deliveryType: state?.deliveryOption?.deliveryType ?? deliveryTypes[0],
    //     minOrderAmount: state?.deliveryOption?.minOrderAmount ?? 0,
    //     distance: state?.deliveryOption?.distance ?? 10,
    //     deliveryFee: state?.deliveryOption?.deliveryFee ?? 0,
    //     weekSchedule: state?.weekSchedule ? { daySchedules: state.weekSchedule, type: state.type } : null
    // });
    // const { showSnackbar } = useSnackbar();

    useEffect(() => {
        document.title = "Add Delivery Option";
        fetchDeliveryOptions();
        // if (state?.deliveryOption?.weekScheduleId) {
        //     storeScheduleRestService
        //         .getWeekSchedule(state?.deliveryOption?.weekScheduleId)
        //         .then(res => {
        //             setInputs(prev => ({
        //                 ...prev,
        //                 weekSchedule: res
        //             }))
        //         })
        // }
    }, []);

    // const navigate = useNavigate();
    // const [disableDetails, setDisableDetails] = useState(false);

    // const handleOnChange = (val, type) => {
    //     setInputs(prev => ({
    //         ...prev,
    //         [type]: val
    //     }))
    // }

    // const handleOnSubmit = async () => {
    //     try {
    //         setLoading(true);
    //         const store = await getLoggedInStore();
    //         const res = await deliveryOptionRestService.createDeliveryOption(store.partyId, inputs);
    //         navigate('/store-settings/delivery-options', { replace: true });
    //     } catch (err) {
    //         showSnackbar('error', err.message);
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    return (
        <>
            <BackHeader headText={"Add delivery option"} />
            <Container maxWidth="lg">
                {
                    loading ?
                        <CircularLoading /> :
                        <DeliveryOptionForm handleDeliveryOptionChange={handleDeliveryOptionChange} schedule={schedule} deliveryOption={deliveryOption} />
                }
            </Container>
            {/* <Container direction="column" maxWidth="md" sx={{ padding: 1, mt: 2, mb: 14 }}>
                <TextField fullWidth sx={{ mt: 1 }} id="outlined-select-deliery-type" value={inputs?.deliveryType} select label="Delivery Type" helperText="Please select delivery type">
                    {deliveryTypes.map((deliveryType) => (
                        <MenuItem key={deliveryType} value={deliveryType} onClick={() => { handleOnChange(deliveryType, 'deliveryType') }}>
                            {deliveryType}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField fullWidth sx={{ mt: 1 }} type={"number"} id="outlined-order-amount"
                    label="Minimum Order Amount" variant="outlined" value={inputs?.minOrderAmount} disabled={disableDetails} onChange={(event) => { handleOnChange(event.target.value, 'minOrderAmount') }} spacing={2} />

                <TextField fullWidth sx={{ mt: 1 }} type={"number"} id="outlined-distance"
                    label="Delivery distance in meters" variant="outlined" value={inputs?.distance} disabled={disableDetails} onChange={(event) => { handleOnChange(event.target.value, 'distance') }} spacing={2} />

                <TextField fullWidth sx={{ mt: 1 }} type={"number"} id="outlined-delivery-fee"
                    label="Delivery Fee" variant="outlined" value={inputs?.deliveryFee} disabled={disableDetails} onChange={(event) => { handleOnChange(event.target.value, 'deliveryFee') }} spacing={2} />
                <Button
                    color="secondary"
                    size="small"
                    sx={{
                        my: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                    onClick={() => {
                        navigate('schedule', { state: { deliveryOption: state?.deliveryOption } })
                    }}
                >
                    {
                        inputs.weekSchedule ?
                            'Edit Schedule' :
                            'Add Schedule'
                    }
                    <EastIcon fontSize="small" />
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                    disabled={!inputs.weekSchedule || loading}
                    onClick={() => { handleOnSubmit() }}
                >
                    Save delivery option
                </Button>
            </Container> */}
        </>
    );
}

export default AddDeliveryOption;