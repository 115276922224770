import { Box, Typography } from '@mui/material';
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { H2 } from '@lifease/lifease-storeui-components';

const OnboardingItem = (props) => {
    const { completed, displayName } = props;
    return (
        <Box sx={{ display: 'flex', columnGap: 1, alignItems: 'center' }}>
            <CheckCircleIcon sx={{ color: completed ? 'green' : 'gray' }} />
            <H2>{displayName}</H2>
        </Box>
    )
}

export default OnboardingItem