import { React } from 'react';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { putFirebaseToken } from '../indexeddb/AppContext';
import { Capacitor } from '@capacitor/core';

function checkPermissions() {
    return new Promise((resolve) => {
        if (Capacitor.isNativePlatform()) {

            PushNotifications.checkPermissions().then(result => {
                if (result.receive !== 'granted') {
                    PushNotifications.requestPermissions().then(result => {
                        if (result.receive === 'granted') {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    })
                }
                else {
                    resolve(true);
                }
            })
        }
        resolve(false);
    })
}

function register() {
    checkPermissions().then(result => {
        if (result) {
            PushNotifications.register();
            PushNotifications.addListener('registration', (token) => {
                putFirebaseToken(token.value);
            })
        }
        else {
            putFirebaseToken("");
        }
    })
}

export default register;