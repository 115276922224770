import React, { useState } from 'react'
import FilledHeader from '../../components/commons/headers/FilledHeader'
import { Box, Button, Typography } from '@mui/material'
import { getCurrentLocation, getGeocodeAddress } from '../../utils/geoLocation';
import RenderMaps from '../../components/commons/google-maps/RenderMaps';
import AddressForm from '../../components/commons/store-address/AddressForm';
import addressRestService from '../../services/rest/AddressRestService';
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { H3, H6, LocateIcon, LocateMe, LocationCard, LocationFilledIcon, MapMarkerIcon, PrimaryButton } from '@lifease/lifease-storeui-components';

function getPostalCode(address_components) {
    for (let comp of address_components) {
        if (comp.types.includes("POSTAL_CODE") || comp.types.includes("postal_code")) {
            if (comp?.longName) {
                return comp.longName;
            }
            return comp.long_name;
        }
    }
}

const StoreAddress = () => {
    const { store } = useStore();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [address, setAddress] = useState(null);
    const [coords, setCoords] = useState({ lat: null, lng: null });
    const [findAddress, setFindAddress] = useState(false);
    const [markerAddress, setMarkerAddress] = useState(null);

    const onLocateClick = async () => {
        try {
            setFindAddress(true);
            const coords = await getCurrentLocation();
            if (coords) {
                setCoords({ lat: coords[0], lng: coords[1] });
            }
        } finally {
            setFindAddress(false);
        }
    }

    const handleUpdateMarkerAddress = (address, { lat, lng }) => {
        setMarkerAddress({
            lat: lat,
            lng: lng,
            place: address.formatted_address,
            mainText: address.address_components[0].long_name + " " + address.address_components[1].long_name,
            secondaryText: address.address_components[2].long_name,
            zip: getPostalCode(address.address_components) ?? ''
        });
        setCoords({ lat: lat, lng: lng });
    }

    const handleButtonClick = () => {
        setAddress(markerAddress);
    }

    const handleConfirmAddress = async (houseData) => {
        try {
            const data = {
                zip: houseData.zip,
                house: houseData.house,
                lat: coords.lat,
                lng: coords.lng,
                addressLine: address.place
            };
            await addressRestService.postStoreAddress(store.partyId, data);
            showSnackbar('success', 'Address saved successfully');
            navigate('/onboarding');
        } catch (err) {
            showSnackbar('error', err.message);
        }
    }

    return (
        <>
            <FilledHeader headText={'Locate your store'} backUrl={'/onboarding'} />
            {
                !coords.lat && !findAddress &&
                <Box sx={{ display: 'flex', mt: 4, flexDirection: 'column', alignItems: 'center', rowGap: 1 }}>
                    <LocateMe onClick={onLocateClick}>Locate me</LocateMe>
                    <H6 style={{ color: 'gray' }}>App will ask for location access</H6>
                </Box>
            }
            {
                findAddress &&
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
                        <MapMarkerIcon />
                        <H3>Locating you...</H3>
                    </Box>
                </Box>
            }
            {
                coords.lat && coords.lng && !address &&
                <>
                    <RenderMaps
                        suggestions={[]}
                        setMarkerAddress={handleUpdateMarkerAddress}
                        setMapLoading={() => { }}
                        latitude={coords.lat}
                        longitude={coords.lng}
                    />
                    <Box sx={{ position: 'absolute', width: '100%', bottom: '4%' }}>
                        <Box sx={{ backgroundColor: 'white', borderRadius: 8, width: { xs: '94%', sm: '400px' }, px: 2, py: 2, boxSizing: 'border-box', mx: 'auto', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px' }}>
                            <LocationCard primaryText={markerAddress?.mainText} secondaryText={markerAddress?.secondaryText} sideView={true} />
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <PrimaryButton onClick={handleButtonClick}>Enter complete address</PrimaryButton>
                            </Box>
                        </Box>
                    </Box>
                </>
            }
            {
                address &&
                <AddressForm handleConfirmAddress={handleConfirmAddress} address={address} />
            }
        </>
    )
}

export default StoreAddress