import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Switch, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import baseUrl from "../../constants";
import { getLoggedInStore } from "../../indexeddb/AppContext";
import SnackbarUI from "../commons/SnackbarUI";
import { useProductDetailsHide } from "../../contexts/product-details-context/ProductDetailsHideProvider";
import { useDialog } from "../../contexts/dialog/DialogContextProvider";
import { DateTimePicker } from '@mui/x-date-pickers';
import { convertDateObjectToString } from "../../utils/luxonUtils";
import productRestService from "../../services/rest/ProductRestService";

const defaultNotificationState = {
  message: null,
  severity: 'error',
  open: false
};

function UpdateProductAction(props) {
  const { product, metrics } = props;
  const { showDialog, closeDialog } = useDialog();
  const [notify, setNotify] = useState(defaultNotificationState);
  const [sellingPrice, setSellingPrice] = useState(product.sellingPrice);
  const [leadTime, setLeadTime] = useState(product?.leadTime ? product.leadTime / 60 : '');
  const [mrp, setMrp] = useState(product.mrp);
  const [updating, setUpdating] = useState(false);
  const [available, setAvailable] = useState(product.available);
  const [sellingPriceInputError, setSellingPriceInputError] = useState(false);
  const [mrpInputError, setMrpInputError] = useState(false);
  const { productDetailsHide } = useProductDetailsHide();
  const [metricsError, setMetricsError] = useState({
    metricsDropdown: '',
    metricsValue: '',
  });
  const [uom, setUom] = useState({
    metricsId: (product.metricsId !== 0) ? product.metricsId : '',
    metricsValue: (product.metricsValue !== 0) ? product.metricsValue : '',
  });

  const handleMetricChange = (e) => {
    setUom(prevMetric => (
      {
        ...prevMetric,
        [e.target.name]: e.target.value
      }
    ));
  }

  const handleLeadTimeChange = (e) => {
    if (e.target.value) {
      setLeadTime(e.target.value);
    }
  }

  let availabilityCaption;
  if (available) {
    availabilityCaption = <Typography variant="button" color='success'>Available</Typography>
  } else {
    availabilityCaption = <Typography variant="button" color={"error"}>Not Available</Typography>
  }

  const handleConfirmDialog = async (stockTime) => {
    const data = {
      isAvailable: false,
      restockTime: stockTime
    };
    try {
      const store = await getLoggedInStore();
      const response = await productRestService.setProductAvailability(store.partyId, product.id, data);
      closeDialog();
      setAvailable(response.available);
      setNotify({
        message: 'Product set to not available',
        severity: 'warning',
        open: true
      });
    } catch {

    }
  }



  const handleCloseDialog = () => {
    // getLoggedInStore().then(store => {
    //   const data = {
    //     isAvailable: false
    //   };
    //   productRestService.setProductAvailability(store.partyId, product.id, data).then(response => {
    closeDialog();
    //     setAvailable(response.available);
    //     setNotify({
    //       message: 'Product set to not available',
    //       severity: 'warning',
    //       open: true
    //     });
    //   }).catch(() => { });
    // });
  }

  const DialogTitle = () => <Typography fontSize={'large'}>Product restock time</Typography>

  const DialogComponent = () => {
    const [stockTime, setStockTime] = useState(null);
    const handleStockChange = (e) => {
      setStockTime(e.toString());
    }
    return <Box sx={{ display: 'flex', flexDirection: 'column', width: 280, rowGap: 2 }}>
      <Typography>Add time when product will be in stock</Typography>
      <DateTimePicker onAccept={handleStockChange} label={'Restock time(optional)'} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: 2 }}>
        <Button color="secondary" variant='contained' onClick={() => { handleConfirmDialog(stockTime) }}>Confirm</Button>
        <Button color="secondary" variant="contained" onClick={handleCloseDialog}>Cancel</Button>
      </Box>
    </Box>
  }

  return (
    <>
      {
        notify.message &&
        <SnackbarUI open={notify.open} type={notify.severity} message={notify.message} onClose={() => { setNotify(prev => ({ ...prev, message: null, open: false })) }} />
      }
      <Grid sx={{ mt: 1 }}>
        <Box sx={{ display: 'flex' }}>
          <Grid xs={6} sx={{ pl: 1, pr: 1, my: 1 }} item >
            <FormControl fullWidth error={metricsError.metricsDropdown === 'error'}>
              <InputLabel id="demo-simple-select-helper-label" size="small">Unit</InputLabel>
              <Select
                size="small" label="Colors"
                sx={{ maxWidth: '222px' }}
                labelId="demo-simple-select-helper-label"
                name="metricsId"
                value={uom.metricsId}
                onChange={handleMetricChange}
              >
                {
                  metrics.map((metric) => (
                    <MenuItem value={metric.id} key={metric.id}>{metric.displayText}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={6} sx={{ pr: 1, my: 1 }} item >
            <TextField error={metricsError.metricsValue === 'error'} type="number" name="metricsValue" size="small" label="Value" variant="outlined" value={uom.metricsValue} onChange={handleMetricChange}>
            </TextField>
          </Grid>
        </Box>
        <Grid container>
          <Grid xs={6} sx={{ pl: 1, pr: 1 }} item key="product-mrp-key">
            <TextField error={mrpInputError} type={"number"} id="outlined-basic" size="small" label="MRP" variant="outlined" value={mrp} onChange={(event) => handleMrpChange(event)}>
            </TextField>
          </Grid>
          <Grid xs={6} sx={{ pr: 1 }} item key="product-selling-price-key">
            <TextField error={sellingPriceInputError} type={"number"} id="outlined-basic" size="small" label="Selling Price" variant="outlined" value={sellingPrice} onChange={(event) => handleSellingPriceTextChange(event)}>
            </TextField>
          </Grid>
        </Grid>
        {
          productDetailsHide.leadTime &&
          <Box sx={{ my: 1, px: 1 }}>
            <TextField fullWidth type={"number"} size="small" label="Time to prepare product in minutes" variant="outlined" value={leadTime} onChange={handleLeadTimeChange}>
            </TextField>
          </Box>
        }
        <Grid container sx={{ mt: 1 }}>
          <Grid xs={4} item sx={{ pl: 1 }} key="product-available-switch-key">
            {availabilityCaption}
          </Grid>
          <Grid item xs={2}>
            <Switch color="secondary" checked={available} onChange={(event) => handleAvailabilityChange(event)} />
          </Grid>
          <Grid item xs={6} key="product-selling-price-update-button-key" sx={{ pl: 1, pr: 1 }}>
            <LoadingButton color="secondary" fullWidth loading={updating} size="small" variant="contained" onClick={handleAddProductClick}>Update</LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  function handleSellingPriceTextChange(event) {
    const value = event.currentTarget.value;
    validateInputs(mrp, value);
    setSellingPrice(value);
  }

  function handleMrpChange(event) {
    const value = event.currentTarget.value;
    validateInputs(value, sellingPrice);
    setMrp(value);
  }

  function handleAddProductClick() {
    if (!validateInputs(mrp, sellingPrice)) {
      return;
    }
    setUpdating(true);
    axios.defaults.withCredentials = true;
    getLoggedInStore().then(store => {
      let data = {
        sellingPrice: sellingPrice,
        mrp: mrp,
        metricsId: uom.metricsId,
        metricsValue: uom.metricsValue
      }
      if (leadTime) {
        data.leadTime = leadTime * 60;
      }
      axios.put(baseUrl + "stores/" + store?.partyId + "/products/" + product.id, data).then(response => {
        setUpdating(false);
        setNotify({
          message: "Product updated",
          severity: 'success',
          open: true
        })
      });
    })
  }



  function handleAvailabilityChange(event) {
    if (event.target.checked) {
      axios.defaults.withCredentials = true;
      getLoggedInStore().then(store => {
        const data = {
          isAvailable: true,
        }
        axios.put(baseUrl + "stores/" + store.partyId + "/products/" + product.id, data).then(response => {
          setAvailable(response.data.available);
          if (event.target.checked) {
            setNotify({
              message: 'Product set to not available',
              severity: 'warning',
              open: true
            });
          }
        });
      })
    } else {
      showDialog(() => <DialogTitle />, () => <DialogComponent />);
    }
  }

  function validateInputs(mrp, sellingPrice) {
    mrp = !isNaN(parseFloat(mrp)) ? parseFloat(mrp) : undefined;
    sellingPrice = !isNaN(parseFloat(sellingPrice)) ? parseFloat(sellingPrice) : undefined;

    if (!mrp || mrp < 0) {
      setMrpInputError(true);
      setNotify({
        message: "Please enter MRP",
        severity: "error",
        open: true
      });
      return false;
    } else if (uom.metricsId === '' || uom.metricsValue === '') {
      // setSellingPriceInputError(true);
      setMetricsError({
        metricsDropdown: (uom.metricsId === '') ? 'error' : '',
        metricsValue: (uom.metricsValue === '') ? 'error' : ''
      })
      setNotify("Please fill product metrics");
      return false;
    }
    else if (!sellingPrice || sellingPrice < 0) {
      setSellingPriceInputError(true);
      setNotify({
        message: "Please enter selling price",
        severity: "error",
        open: true
      });
      return false;
    }
    else if (sellingPrice > mrp) {
      setMrpInputError(true);
      setSellingPriceInputError(true);
      setNotify({
        message: "Selling price should not be more than MRP",
        severity: "error",
        open: true
      });
      return false;
    } else if (uom.metricsValue <= 0) {
      setMetricsError((prevError) => ({
        ...prevError,
        metricsValue: 'error'
      }));
      setNotify({
        message: "Please fill product metrics",
        severity: "error",
        open: true
      });
      return false;
    }

    setNotify({
      message: null,
      severity: 'error',
      open: false,
    });
    setMrpInputError(false);
    setSellingPriceInputError(false);
    return true;
  }
}

export default UpdateProductAction;