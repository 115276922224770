import { Header } from '@lifease/lifease-storeui-components';
import { Box } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const BackHeader = (props) => {
    const { headText, ...rest } = props;
    const navigate = useNavigate();
    return (
        <Box sx={{ position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 10 }}>
            <Header {...rest} regular={true} title={headText} handleBack={() => { navigate(-1) }} />
        </Box>
    )
}

export default BackHeader