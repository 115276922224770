import React, { useEffect, useState } from 'react';
import { Box, FormGroup, Switch, Stack } from '@mui/material';
import { H2, H9, SecondaryButton } from '@lifease/lifease-storeui-components';

import BackHeader from '../../components/commons/headers/BackHeader';
import CircularLoading from '../../components/commons/circular-progress/CircularLoading';

import { useDialog } from '../../contexts/dialog/DialogContextProvider';
import { useStore } from '../../contexts/store-context/StoreContextProvider';

import manageChannelsRestService from '../../services/rest/ManageChannelsRestService';

const ManageChannels = () => {
  const [storeChannels, setStoreChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { showDialog, closeDialog } = useDialog();
  const { store } = useStore();

  const getStoreChannels = async (storeId) => {
    try {
      setIsLoading(true);
      const res = await manageChannelsRestService.getStoreChannels(storeId);
      if (res) {
        setStoreChannels(res);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialog = async (IsRestoreStatus, channel) => {
    closeDialog();
    if (IsRestoreStatus) {
      try {
        await manageChannelsRestService.updateStoreChannels(
          store.partyId,
          channel
        );
      } catch (error) {}
    }
  };

  const handleChange = (channel) => {
    const originalStatus = channel.status;

    const newStatus = channel.status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE';
    showDialog(
      () => <H2>{`Do you really want to ${newStatus} the channel ?`}</H2>,
      () => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <SecondaryButton
            variant='danger'
            onClick={() => {
              handleDialog(false, { ...channel, status: originalStatus });
              setStoreChannels((prevChannels) =>
                prevChannels.map((ch) =>
                  ch.channel === channel.channel
                    ? { ...ch, status: originalStatus }
                    : ch
                )
              );
            }}
          >
            Cancel{' '}
          </SecondaryButton>
          <SecondaryButton
            variant='success'
            onClick={() =>
              handleDialog(true, { ...channel, status: newStatus })
            }
          >
            Save
          </SecondaryButton>
        </Box>
      )
    );

    const updatedChannels = storeChannels.map((ch) =>
      ch.channel === channel.channel ? { ...ch, status: newStatus } : ch
    );
    setStoreChannels(updatedChannels);
  };

  useEffect(() => {
    getStoreChannels(store.partyId);
  }, []);

  return (
    <>
      <BackHeader headText={'Manage Channels'} />
      {isLoading ? (
        <CircularLoading />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 5,
            gap: 5,
          }}
        >
          <FormGroup>
            {storeChannels.map((channel) => (
              <Stack
                key={channel.channelId}
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{ width: '100%', px: 2 }}
              >
                <Box>
                  <H2>{channel.channel}</H2>
                  <H9
                    style={{
                      color: channel.status === 'ACTIVE' ? 'green' : 'red',
                    }}
                  >
                    {channel.status === 'ACTIVE'
                      ? ' Accepting orders'
                      : 'Not accepting orders'}
                  </H9>
                </Box>
                <Switch
                  color='secondary'
                  checked={channel.status === 'ACTIVE'}
                  onChange={() => handleChange(channel)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            ))}
          </FormGroup>
        </Box>
      )}
    </>
  );
};

export default ManageChannels;
