import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../constants";
// import AddProductCard from "./AddProductCardComponent";
import { getLoggedInStore } from "../../indexeddb/AppContext";
import productRestService from "../../services/rest/ProductRestService";
import { AddProductCard, PrimaryButton, ProductCard } from "@lifease/lifease-storeui-components";
import { useDrawer } from "../../contexts/drawer/DrawerContextProvider";
import ProductModalComponent from "./ProductModalComponent";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import CircularLoading from "../commons/circular-progress/CircularLoading";

function AddProductsGrid(props) {
    const { productCategory, setAlertContent, search, metrics } = props;
    const { store } = useStore();
    const { showSnackbar } = useSnackbar();
    const [productMasters, setProductMasters] = useState([]);
    const { openDrawer, closeDrawer } = useDrawer();
    const [loading, setLoading] = useState(true);
    const [hasProducts, setHasProducts] = useState(true);
    const productUnavailableDiv = <Alert sx={{ mt: 2, ml: "auto", mr: "auto" }} severity="warning">No more products!!</Alert>;
    const [productLoad, setProductLoad] = useState(false);
    const handleOnButtonClick = async (data, product, type) => {
        try {
            setProductLoad(true);
            if (type === "ADD") {
                const newVariant = {
                    categoryId: product.categoryId,
                    brand: product.brand,
                    productName: product.productName,
                    type: product.type,
                    subCategory: product.subCategory,
                    mrp: parseFloat(data.mrp),
                    sellingPrice: parseFloat(data.sellingPrice),
                    imageId: product.imageId,
                    productMasterId: product.id,
                    metricsId: parseInt(data.metricsId),
                    metricsValue: parseFloat(data.metricsValue),
                    description: product.description,
                    leadTime: parseInt(data?.leadTime * 60),
                    productAttributeList: product.productMasterAttributeList
                };
                await productRestService.addProduct(store.partyId, newVariant);
            } else {
                await productRestService.updateProduct(store.partyId, product.id, { ...product, ...data });
            }
            closeDrawer();
        } catch (err) {
            showSnackbar("error", err.message);
        } finally {
            setProductLoad(false);
        }
    }

    const handleAddProduct = (data) => {
        openDrawer(() => <></>, () => <ProductModalComponent loading={productLoad} metrics={metrics} store={store} handleOnButtonClick={handleOnButtonClick} product={data} />)

    }

    useEffect(() => {
        if (!search) { setLoading(true); fetchProductMasters(); }
        else {
            setProductMasters(productCategory);
        }
    }, []);


    return (
        <>
            {
                !loading ?
                    <Grid container>
                        <Grid container rowSpacing={{ xs: 1 }} columnSpacing={2}>
                            {productMasters.map((productMaster) => (
                                <Grid key={productMaster.id} xs={12} sm={6} md={4} item>
                                    <AddProductCard product={productMaster} handleAddProduct={() => { handleAddProduct(productMaster) }} />
                                    {/* <AddProductCard metrics={metrics} setAlertContent={setAlertContent} key={productMaster.id} productMaster={productMaster}></AddProductCard> */}
                                </Grid>
                            ))}
                        </Grid>
                        {
                            (search && productMasters.length === 0) && <Typography sx={{ width: "100%", display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>No Search results found</Typography>
                        }
                        {!search &&
                            (hasProducts ?
                                <Box sx={{ mt: 2, ml: "auto", mr: "auto" }} >
                                    <PrimaryButton onClick={fetchProductMasters}>Show more</PrimaryButton>
                                </Box> :
                                productUnavailableDiv)
                        }
                    </Grid> :
                    <CircularLoading />
            }
        </>
    );

    function fetchProductMasters() {
        axios.defaults.withCredentials = true;
        let minProductId;
        if (productMasters.length !== 0) {
            minProductId = productMasters[productMasters.length - 1].id - 1;
        }
        let url = baseUrl + "mdm/product-masters?categoryId=" + productCategory.id;
        if (minProductId !== undefined) {
            url += "&maxProductId=" + minProductId;
        }
        axios.get(url).then(response => {
            if (response.data.length === 0) {
                setHasProducts(false);
            } else {
                setProductMasters(pms => {
                    return pms.concat(response.data);
                });
            }
        }).finally(() => { setLoading(false) });
    }
}

export default AddProductsGrid;
