import React, { useContext, useState } from 'react'
import { DialogContext } from './DialogContext';
import { Box, Dialog, DialogTitle } from '@mui/material';
import { CancelIcon, H2 } from '@lifease/lifease-storeui-components';

const DialogContextProvider = (props) => {
    const [dialog, setDialog] = useState({ open: false, dialogTitle: () => <></>, dialogComponent: () => <></> });
    const handleOnClose = () => {
        setDialog(false);
    }

    const showDialog = (DialogTitleComponent, DialogComponent) => {
        setDialog({ open: true, dialogComponent: () => <DialogComponent />, dialogTitle: () => <DialogTitleComponent /> })
    }

    const closeDialog = () => {
        setDialog({ open: false, dialogTitle: () => null, dialogComponent: () => null })
    }



    return (
        <DialogContext.Provider value={{ showDialog, closeDialog }}>
            <Dialog onClose={handleOnClose} open={dialog.open ?? false}>
                <Box sx={{ width: { xs: '80vw', sm: '500px' } }}>
                    {
                        dialog.dialogTitle &&
                        <>
                            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <H2>
                                    {
                                        dialog.dialogTitle()
                                    }
                                </H2>
                                <Box sx={{ float: 'right' }} onClick={() => { closeDialog() }}>
                                    <CancelIcon fill='red' />
                                </Box>
                            </DialogTitle>
                            <Box sx={{ p: 2 }}>
                                {
                                    dialog.dialogComponent()
                                }
                            </Box>
                        </>
                    }
                </Box>
            </Dialog>
            {
                props.children
            }
        </DialogContext.Provider>
    )
}

export const useDialog = () => useContext(DialogContext);

export default DialogContextProvider;