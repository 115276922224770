import { Box, Button, Container, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import UsersGrid from "../../components/users/UsersGrid";
import BackHeader from "../../components/commons/headers/BackHeader";
import { H2, PrimaryButton } from "@lifease/lifease-storeui-components";
import { useDialog } from "../../contexts/dialog/DialogContextProvider";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import axios from "axios";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import baseUrl from "../../constants";
import CircularLoading from "../../components/commons/circular-progress/CircularLoading";

function ManageUsers(props) {
    // const navigate = useNavigate();
    const { store } = useStore();
    const { showDialog, closeDialog } = useDialog();
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const handleMobileNumberAdded = (form) => {
        setLoading(true);
        closeDialog();
        let tempForm = { ...form, role: "STORE_USER", roleType: "STORE" };
        axios.put(baseUrl + "stores/" + store.partyId + "/user", tempForm).then(response => {
            showSnackbar('success', 'Successfully added user to store')
        })
            .catch((error) => {
                showSnackbar('error', error.response.data.message);
            }).finally(() => { setLoading(false); });
    }

    const UserDetails = (props) => {
        const { handleMobileNumberAdded } = props;
        const [form, setForm] = useState({ mobile: '' });
        return <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
            <TextField value={form.mobile} name="mobile" onChange={(e) => { setForm(prev => ({ ...prev, [e.target.name]: e.target.value })) }} required variant="standard" label={"Mobile number"} />
            <Box sx={{ mt: 2, float: 'right' }}>
                <PrimaryButton onClick={() => { handleMobileNumberAdded(form) }} disabled={form.mobile.length !== 10}>Confirm</PrimaryButton>
            </Box>
        </Box>
    }

    const handleAddUser = () => {
        showDialog(() => <H2>User details</H2>, () => <UserDetails handleMobileNumberAdded={handleMobileNumberAdded} />)
    }

    useEffect(() => {
        document.title = 'manage users';
    }, [])

    return (
        <>
            <BackHeader headText={'Manage Users'} />
            <Container direction="column" maxWidth="md" sx={{ padding: 1 }}>
                {
                    !loading ?
                        <>
                            <UsersGrid />
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <PrimaryButton onClick={() => { handleAddUser() }}>Add User</PrimaryButton>
                            </Box>
                        </> :
                        <CircularLoading />
                }
            </Container>
        </>
    );
}

export default ManageUsers;