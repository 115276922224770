import { Alert, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import StoreStatusSettings from '../../components/account-settings/StoreStatusSettings'
import { getLoggedInStore } from '../../indexeddb/AppContext';
import storeManagementRestService from '../../services/rest/StoreManagementRestService';
import BackHeader from '../../components/commons/headers/BackHeader';


const AccountSettings = () => {
    const [storeInfo, setStoreInfo] = useState({ loading: true, data: null });
    const [error, setError] = useState('');
    useEffect(() => {
        document.title = 'account settings';
        fetchStore();
    }, []);

    const onStoreStatusChange = async (e) => {
        let status = e.target.checked ? 'ACTIVE' : 'DISABLED';
        try {
            setStoreInfo(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    status: status,
                }
            }));
            const data = {
                storeType: storeInfo.data.storeType,
                storeName: storeInfo.data.storeName,
                description: storeInfo.data.description,
                mobile: storeInfo.data.mobile,
                status: status,
            }
            const res = await storeManagementRestService
                .updateStoreStatus(storeInfo.data.partyId, data);
        } catch (e) {
            setStoreInfo(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    status: status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE'
                }
            }));
            setError(e);
        }
    }
    return (
        <>
            <BackHeader headText={'Account Settings'} />
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, mt: 2 }}>
                {
                    error.length > 0 &&
                    <Alert severity='error' sx={{ mx: 1 }}>{error}</Alert>
                }
                {
                    storeInfo.loading ?
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography color={'primary'}>Loading Account Details...</Typography>
                        </Box> :
                        <StoreStatusSettings storeDetails={storeInfo} onStoreStatusChange={onStoreStatusChange} />
                }
            </Box>
        </>
    )

    async function fetchStore() {
        const loggedInStore = await getLoggedInStore();
        const storeDetails = await storeManagementRestService.getStoreById(loggedInStore.partyId);
        setStoreInfo({ loading: false, data: storeDetails });
    }
}

export default AccountSettings