import axios from "axios";
import baseUrl from "../../constants";
import axiosCommons from "./AxiosCommons";
import authFetch from "../../utils/interceptor";

const catalogue = {
  ImageUpload: async (file) => {
    const url = new URL(`${baseUrl}files?fileType=PRODUCT_IMAGE`);
    const axiosResponse = axios.post(url, file);
    return axiosCommons.resolveResponse(axiosResponse);
  },
  GetCategoryId: async ({ storeId }) => {
    const url = new URL(`${baseUrl}stores/${storeId}/product-category`);
    const options = {
      method: 'GET',
      credentials: 'include'
    }
    try {
      const res = await authFetch(url, options);
      return await res.json();
    } catch (err) {
      throw new Error(err.message);
    }
  },
  SetCatalogueConfig: async (req, storeId) => {
    const url = new URL(`${baseUrl}stores/${storeId}/product-appeal`);
    const axiosResponse = axios.post(url, req);
    return axiosCommons.resolveResponse(axiosResponse);
  },
  GetMetrics: async () => {
    const url = new URL(`${baseUrl}metrics`);
    const options = {
      method: 'GET',
      credentials: 'include'
    }
    try {
      const res = await authFetch(url, options);
      return await res.json();
    } catch (err) {
      throw new Error(err.message);
    }
  },
  GetCatalogueStatue: async (storeId, status) => {
    const url = new URL(`${baseUrl}stores/${storeId}/products-appeals?status=${status}`);
    const options = {
      method: 'GET',
      credentials: 'include'
    }
    try {
      const res = await authFetch(url, options);
      return await res.json();
    } catch (err) {
      throw new Error(err.message);
    }
    // const res = await authFetch(url, options);
    // if (res.ok) {
    //   return await res.json();
    // }
    // throw new Error(res.statusText);
  },
  fetchImage: async (id) => {
    const url = new URL(`${baseUrl}files/${id}`);
    const options = {
      method: 'GET',
      credentials: 'include'
    }
    try {
      const res = await authFetch(url, options);
      return await res.json();
    }
    catch (err) {
      throw new Error(err.message);
    }
  }
};

export default catalogue;
