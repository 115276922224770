import React, { useState } from 'react'
import FilledHeader from '../commons/headers/FilledHeader'
import DeliveryOptionForm from './DeliveryOptionForm'
import { useStore } from '../../contexts/store-context/StoreContextProvider';
import storeScheduleRestService from '../../services/rest/StoreScheduleRestService';
import { useDialog } from '../../contexts/dialog/DialogContextProvider';
import WeekScheduleComponent from '../commons/week-schedule/WeekScheduleComponent';
import { convertScheduleToScheduleRequest } from './splitHoursUtil';
import deliveryOptionRestService from '../../services/rest/DeliveryOptionRestService';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { useNavigate } from 'react-router-dom';
import { ScheduleType } from '../../utils/enums';
import ViewSchedule from '../commons/week-schedule/ViewSchedule';

const CustomDeliveryOption = () => {
    const { showDialog, closeDialog } = useDialog();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [deliveryType, setDeliveryType] = useState(ScheduleType.RELATIVE);
    const { store } = useStore();
    const [duration, setDuration] = useState('');
    const [custom, setCustom] = useState(false);
    const [schedule, setSchedule] = useState(null);

    const handleConfirm = async (form) => {
        if (!custom) {
            const data = {
                ...form,
                weekSchedule: schedule
            };
            try {
                await deliveryOptionRestService.createDeliveryOption(store.partyId, data);
                showSnackbar('success', 'Delivery option added successfully');
                navigate('/onboarding');
            } catch (err) {
                showSnackbar('error', err.message);
            }
        }
    }

    const onScheduleAdd = (data) => {
        const schedule = convertScheduleToScheduleRequest(data, deliveryType);
        setSchedule(schedule);
        closeDialog();
    }

    const handleAddSchedule = () => {
        showDialog(() => <span>Add Schedule</span>, () => <WeekScheduleComponent ScheduleType={deliveryType} handleScheduleSubmit={onScheduleAdd} />)
    }

    const handleViewSchedule = () => {
        showDialog(() => <span>Schedule</span>, () => <ViewSchedule schedule={schedule} />)
    }

    return (
        <>
            <FilledHeader headText={'Add delivery option'} backUrl={'/onboarding'} />
            <DeliveryOptionForm handleViewSchedule={handleViewSchedule} schedule={schedule} handleAddSchedule={handleAddSchedule} handleConfirm={handleConfirm} custom={custom} setCustom={setCustom} duration={duration} setDuration={setDuration} deliveryType={deliveryType} setDeliveryType={setDeliveryType} />
        </>
    )
}

export default CustomDeliveryOption