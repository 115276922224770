import React, { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useNavigate } from 'react-router-dom';
import conversationRestService from '../../services/rest/conversationsRestService';
import { H3 } from '@lifease/lifease-storeui-components';

const MessageRow = ({ message,storeId }) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchMessages = async (conversationId,storeId) => {
    try {
      const res = await conversationRestService.getAllMessages(conversationId, storeId, 1);
      setMessages(res);
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchConversations = async (storeId, customerId) => {
    try {
      const res = await conversationRestService.getConversation(storeId, customerId, 0, 'CHAT');
      if (res.length > 0) {
        fetchMessages(res[0].id,storeId);
      } else {
        const res = await conversationRestService.initialConversation(storeId, customerId, 0, 'CHAT');
        if (res) {
          fetchMessages(res.id,storeId);
        }
      }
    } catch (error) {
      console.error('Failed to fetch conversations:', error);
    }
  };

  const handleRowClick = () => {
    navigate('/conversation', {
      state: {
        orderDetails: {
          id: message.typeIdentifier,
          fromPartyId: storeId,
          toPartyId: storeId === message.partyId1 ? message.partyId2 : message.partyId1,
          type: 'CHAT',
        },
      },
    });
  };

  useEffect(() => {
    if(storeId === message.partyId1){
        fetchConversations(storeId,message.partyId2);
    }
    else{
        fetchConversations(storeId,message.partyId1);
    }
  }, []);

  const renderMessageContent = () => {
    if (messages && messages.length > 0) {
      const firstMessage = messages[0];
      if (firstMessage.type === 'TEXT') {
        return firstMessage.text;
      } else if (firstMessage.file && firstMessage.file.url) {
        return 'Attachment';
      }
    }
    return null;
  };

  return (
    loading ? (
      <TableRow>
        <TableCell><H3>Loading...</H3></TableCell>
      </TableRow>
    ) : (
      messages && (
        <TableRow hover onClick={handleRowClick} style={{ cursor: 'pointer' }}>
          <TableCell><H3>{renderMessageContent()}</H3></TableCell>
        </TableRow>
      )
    )
  );
};

export default MessageRow;
