import { Schedule } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { ScheduleType } from '../../utils/enums';
import { BrandPrimaryButton, Dropdown, PrimaryButton, TextInput } from '@lifease/lifease-storeui-components';

const Data = [
    { value: 'SELF_PICKUP', label: 'Self pickup' },
    { value: 'HOME_DELIVERY', label: 'Home delivery' }
];

const DeliveryOptionForm = (props) => {
    const { deliveryType, handleViewSchedule, schedule, setDeliveryType, duration, setDuration, custom, setCustom, handleConfirm, handleAddSchedule } = props;
    const [form, setForm] = useState({ orderAmount: '', deliveryFee: '', distance: '', deliveryType: 'HOME_DELIVERY' });
    const handleChange = (e) => {
        setForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleClear = (name) => {
        setForm(prev => ({ ...prev, [name]: '' }));
    }

    const disableConfirm = () => {
        if (!schedule || form.deliveryFee.length === 0 || form.deliveryType.length === 0 || form.orderAmount.length === 0) return true;
        if (parseInt(form.orderAmount) < 0) return true;
        if (parseInt(form.deliveryFee) < 0) return true;
        if (parseInt(form.distance) < 0) return true;
        return false;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1.5, px: 2, mt: 4 }}>
            <Dropdown items={Data} name={'deliveryType'} onSelect={(e) => { handleChange({ target: { name: 'deliveryType', value: e } }) }} value={form.deliveryType} label={"Delivery type"} />
            <TextInput handleClear={() => { handleClear('orderAmount') }} value={form.orderAmount} label={'Min order amount'} name='orderAmount' onChange={handleChange} />
            <TextInput handleClear={() => { handleClear('deliveryFee') }} value={form.deliveryFee} label={'Delivery fee'} name='deliveryFee' onChange={handleChange} />
            <TextInput handleClear={() => { handleClear('distance') }} value={form.distance} label={'Distance(in metre)'} name='distance' onChange={handleChange} />
            <RadioGroup onChange={(e) => { setDeliveryType(e.target.value) }} value={deliveryType}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <FormControlLabel value={ScheduleType.RELATIVE} control={<Radio />} label="Express Delivery" />
                    <FormControlLabel value={ScheduleType.ABSOLUTE} control={<Radio />} label="Scheduled Delivery" />
                </Box>
            </RadioGroup>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
                <PrimaryButton onClick={handleAddSchedule}>Add Schedule</PrimaryButton>
                {
                    schedule &&
                    <PrimaryButton onClick={handleViewSchedule}>View Schedule</PrimaryButton>
                }
            </Box>
            <BrandPrimaryButton disabled={disableConfirm()} onClick={() => { handleConfirm(form) }}>Confirm</BrandPrimaryButton>
        </Box>
    )
}

export default DeliveryOptionForm