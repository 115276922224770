import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress  from '@mui/material/CircularProgress';

function ProgressBackdrop(props) {
    const {progress} = props;
    return (
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={progress}
        >
            <CircularProgress  color="success" />
      </Backdrop>
    );
}

export default ProgressBackdrop;