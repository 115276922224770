import React, { useState } from 'react'
import FilledHeader from '../../components/commons/headers/FilledHeader'
import DefaultDeliveryOptions from '../../components/delivery-options/DefaultDeliveryOptions'
import { useStore } from '../../contexts/store-context/StoreContextProvider'
import CustomDeliveryOption from '../../components/delivery-options/CustomDeliveryOption'
import CircularLoading from '../../components/commons/circular-progress/CircularLoading'

const DeliveryOptions = () => {
    const { store } = useStore();
    const [step, setStep] = useState(0);
    return (
        <>
            {
                step === 0 &&
                <DefaultDeliveryOptions store={store} setStep={setStep} />
            }
            {
                step === 1 &&
                <CustomDeliveryOption />
            }
        </>
    )
}

export default DeliveryOptions