import baseUrl, { Header } from '../../constants';
import authFetch from '../../utils/interceptor';

const manageChannelsRestService = {
  getStoreChannels: async (storeId) => {
    const url = new URL(`${baseUrl}stores/${storeId}/channels`);
    const options = {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': Header.CONTENT_TYPE,
      },
    };
    try {
      const res = await authFetch(url, options);
      return await res.json();
    } catch (err) {
      throw new Error(err.message);
    }
  },
  updateStoreChannels: async (storeId, body) => {
    const url = new URL(`${baseUrl}stores/${storeId}/channel`);
    const options = {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': Header.CONTENT_TYPE,
      },
    };
    try {
      const res = await authFetch(url, options);
      return await res.json();
    } catch (err) {}
  },
};

export default manageChannelsRestService;
