import { Header } from '@lifease/lifease-storeui-components';
import { Box } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const SearchIconHeader = (props) => {
    const { headText, ...rest } = props;
    const navigate = useNavigate();
    const [show, setShow] = useState(true);
    return (
        <Box sx={{ position: 'sticky', top: 0, left: 0, width: '100%', zIndex: 10 }}>
            <Header
                {...rest}
                title={headText}
                handleBack={() => { navigate(-1) }}
            />
        </Box>
    )
}

export default SearchIconHeader;