import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeliveryOptionsGrid from "../../components/delivery-option/DeliveryOptionsGridComponent";
import { getLoggedInStore } from "../../indexeddb/AppContext";
import axios from "axios";
import baseUrl from "../../constants";
import deliveryOptionRestService from "../../services/rest/DeliveryOptionRestService";
import BackHeader from "../../components/commons/headers/BackHeader";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { DeliveryConfiguration, PrimaryButton } from "@lifease/lifease-storeui-components";
import CircularLoading from "../../components/commons/circular-progress/CircularLoading";
import storeScheduleRestService from "../../services/rest/StoreScheduleRestService";
import { ScheduleType } from "../../utils/enums";
import ViewSchedule from "../../components/commons/week-schedule/ViewSchedule";
import { useDialog } from "../../contexts/dialog/DialogContextProvider";

function ViewDeliveryOptions(props) {
    const { store } = useStore();
    const { showSnackbar } = useSnackbar();
    const { showDialog, closeDialog } = useDialog();
    const [deliveryOptions, setDeliveryOptions] = useState({ loading: true, data: [] });
    const navigate = useNavigate();
    const onDeliveryAdd = () => {
        navigate(`/add-delivery-option/0`)
    }

    const onViewClick = async (weekScheduleId, e) => {
        try {
            const schedule = await storeScheduleRestService.getWeekSchedule(weekScheduleId);
            let temp = [];
            if (schedule.type === ScheduleType.RELATIVE) {
                for (const daySchedule of schedule.daySchedules) {
                    let slots = [];
                    for (const slot of daySchedule.timeSlots) {
                        slots.push({ ...slot, duration: slot.duration });
                    }
                    temp.push({ ...daySchedule, timeSlots: slots });
                };
            } else {
                temp = schedule.daySchedules;
            }
            showDialog(() => <span>Schedule</span>, () => <ViewSchedule schedule={{ daySchedules: temp }} />)

        } catch (err) {
            showSnackbar('error', err.message);
        }
    }

    const onDeleteClick = async (deliveryOption, idx) => {
        try {
            const deleted = await deliveryOptionRestService.deleteDeliveryOption(deliveryOption.id, store.partyId);
            setDeliveryOptions(prev => ({ loading: false, data: prev.data.filter(res => res.id !== idx) }));

        } catch (err) {
            showSnackbar('error', err.message);
        }
    }

    const onDeliveryOptionClick = (deliveryOptionId) => {
        navigate(`/add-delivery-option/${deliveryOptionId}`)
    }

    useEffect(() => {
        document.title = "View delivery options";
        deliveryOptionRestService.getDeliveryOptions(store.partyId).then(res => {
            if (res) {
                setDeliveryOptions({ loading: false, data: JSON.parse(res) });
            }
        }).catch(err => { showSnackbar('error', err.message); setDeliveryOptions({ loading: false, data: [] }) });
    }, []);
    // const navigate = useNavigate();
    // const [deliveryOptions, setDeliveryOptions] = React.useState({ loading: false, data: [] });
    // useEffect(() => {
    //     document.title = 'view delivery options';
    //     fetchDeliveryOptions();
    // }, []);
    // const onScheduleClick = (deliveryOption) => {
    //     navigate("schedule", { state: { deliveryOption: deliveryOption, action: true } });
    // }
    // const handleDelete = (deliveryOption, index) => {
    //     setDeliveryOptions(prev => ({
    //         ...prev,
    //         loading: true
    //     }))
    //     deliveryOptionRestService
    //         .deleteDeliveryOption(deliveryOption.id, deliveryOption.storePartyId)
    //         .then((res) => {
    //             setDeliveryOptions(prev => ({
    //                 ...prev,
    //                 loading: false,
    //                 data: [...prev.data.slice(0, index), ...prev.data.slice(index + 1)]  // remove the deleted item from the list of items.
    //             }))
    //         }).catch(err => {
    //             setDeliveryOptions(prev => ({
    //                 ...prev,
    //                 loading: false,
    //             }))
    //         })
    // }

    // const onEditClick = (deliveryOption) => {
    //     navigate('/add-delivery-option', { state: { deliveryOption } })
    // }

    return (
        <>
            <BackHeader headText={'Delivery Options'} />
            {
                !deliveryOptions.loading ?
                    <Container maxWidth="lg" sx={{ mt: 2, mb: 14 }}>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            {
                                deliveryOptions.data.map((res, idx) => {
                                    return <Grid item key={res.id} xs={12} sm={6} md={4} >
                                        <Box sx={{ cursor: 'pointer' }}>
                                            <DeliveryConfiguration showActionButton={true} onEditClick={() => { onDeliveryOptionClick(res.id) }} onDeleteClick={() => { onDeleteClick(res, res.id) }} onViewClick={(e) => { onViewClick(res.weekScheduleId, e) }} deliveryOptions={res} />
                                        </Box>
                                    </Grid>
                                })
                            }
                            {/* <DeliveryConfiguration key={idx} onEditClick={() => { }} onDeleteClick={() => { }} onViewClick={() => { onScheduleView(idx) }} deliveryOptions={options} /> */}
                        </Grid>
                        <PrimaryButton onClick={onDeliveryAdd} style={{ marginTop: '16px' }}>Add delivery option</PrimaryButton>
                    </Container> :
                    <CircularLoading />
            }
            {/* <Container direction="column" maxWidth="md" sx={{ padding: 1, mt: 2, mb: 14 }}>
                {
                    deliveryOptions.loading ?
                        <Typography color={'primary'} sx={{ display: 'flex', justifyContent: 'center' }}>Loading delivery options...</Typography> :
                        <>
                            <DeliveryOptionsGrid onEditClick={onEditClick} handleDelete={handleDelete} deliveryOptions={deliveryOptions.data} onScheduleClick={onScheduleClick} />
                            <Button sx={{ mt: 1 }} fullWidth color="secondary" variant="contained" onClick={() => navigate("/add-delivery-option")}>Add</Button>
                        </>
                }
            </Container> */}
        </>
    );

    // function fetchDeliveryOptions() {
    //     setDeliveryOptions({ loading: true, data: [] });
    //     axios.defaults.withCredentials = true;
    //     getLoggedInStore().then(store => {
    //         if (store !== undefined) {
    //             axios.get(baseUrl + "stores/" + store.partyId + "/delivery-options").then(response => {
    //                 setDeliveryOptions({ loading: false, data: response.data });
    //             });
    //         }
    //     })
    // }
}

export default ViewDeliveryOptions;