import { Paper, Switch, Typography } from '@mui/material'
import React from 'react'

const StoreStatusSettings = (props) => {
    const { storeDetails, onStoreStatusChange } = props;
    return (
        <Paper elevation={1} sx={{ mx: 1, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography color={'primary'}>Stop accepting orders</Typography>
            <Switch checked={storeDetails.data.status === 'ACTIVE' ? true : false} onChange={onStoreStatusChange} />
        </Paper>
    );
}

export default StoreStatusSettings