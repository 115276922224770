import { CircularProgress, Container, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import BillingRestService from '../../services/rest/BillingRestService';
import { getLoggedInStore } from '../../indexeddb/AppContext';
import BillingTableComponent from '../../components/billing/BillingTableComponent';
import PaginationNextPrevBar from '../../components/add-variants/PaginationNextPrevBar';
import BackHeader from '../../components/commons/headers/BackHeader';

const BillingComponent = () => {

    const [balanceSheet, setBalanceSheet] = useState({ data: null, page: 1, loading: true });
    const [pageNo, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = 'Billing info';
    }, []);

    useEffect(() => {
        getBalanceSheet();
    }, [pageNo])

    const prevHandler = () => {
        setPageNo(prev => prev - 1);
    }

    const nextHandler = () => {
        setPageNo(prev => prev + 1);
    }

    return (
        <>
            <BackHeader headText={'Ledger'} />
            <Container maxWidth="lg" sx={{ padding: 1, mb: 8 }}>
                {
                    balanceSheet.data ?
                        <>
                            <BillingTableComponent loading={loading} data={balanceSheet.data} />
                            <PaginationNextPrevBar products={balanceSheet} prevHandler={prevHandler} nextHandler={nextHandler} minProductId={(pageNo == 1) ? null : pageNo} />
                        </> :
                        <Typography sx={{ display: 'flex', justifyContent: 'center', mt: 3 }} color={'primary'}>
                            Loading ledger...
                        </Typography>
                }
            </Container>
        </>
    );

    async function getBalanceSheet() {
        try {
            setLoading(true);
            const data = await getLoggedInStore();
            const balanceRes = await BillingRestService.getBalanceSheet(data.partyId, pageNo);
            setBalanceSheet({ data: balanceRes });
        } catch {
        } finally {
            setLoading(false);
        }
    };
}

export default BillingComponent