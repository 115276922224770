import React, { useCallback, useState } from 'react'
import SearchIconHeader from '../../components/commons/headers/SearchIconHeader'
import BackHeader from '../../components/commons/headers/BackHeader'
import ViewAllProductsList from '../../components/view-all-products/ViewAllProductsList'
import { useStore } from '../../contexts/store-context/StoreContextProvider'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import productRestService from '../../services/rest/ProductRestService'
import loadash from 'lodash';
import SearchSuggestionComponent from '../../components/commons/search-suggestion/SearchSuggestionComponent'

const DebounceTime = 500;
const ViewAllProducts = () => {
    const { store } = useStore();
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const onAction = (e) => {
        setShowSearchIcon(prev => !prev);
    }

    const handleClearInput = () => {
        setSearchText('');
    }

    const searchSuggestion = async (searchWord) => {
        try {
            const suggestions = await productRestService.storeProductSearchSuggestion(searchWord, store.partyId);
            setSuggestions(suggestions);
        } catch { }
    }

    const handleDebounceFn = (searchWord) => {
        searchSuggestion(searchWord);
    }

    const debounceFn = useCallback(loadash.debounce(handleDebounceFn, DebounceTime), []);

    const onChange = (e) => {
        setSearchText(e.target.value);
        if (e.target.value.length > 2) {
            debounceFn(e.target.value);
        } else {
            setSuggestions([]);
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.length > 2) {
            navigate(`/search?searchWord=${e.target.value}&type=UPDATE`);
        }
    }

    const onSuggestionClick = (word) => {
        navigate(`/search?searchWord=${word}&type=UPDATE`)
    }
    return (
        <>
            <SearchIconHeader
                headText={'View All Products'}
                placeholder={"Search product to update"}
                showSearchIcon={showSearchIcon}
                onAction={onAction}
                title={'adk'}
                handleClearInput={handleClearInput}
                value={searchText}
                onChange={onChange}
                onKeyDown={onKeyDown}
                autoFocus
            />
            <SearchSuggestionComponent suggestions={suggestions} onSuggestionClick={onSuggestionClick} />
            <ViewAllProductsList />
        </>
    )
}

export default ViewAllProducts