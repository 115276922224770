let baseUrl = process.env.REACT_APP_API_BASE_URL;
export const APP_NAME = process.env.REACT_APP_NAME;

export default baseUrl;

// If the payment status is Pending, then Check Status API should be called in the following interval:
// The first status check at 20-25 seconds post transaction start, then
// Every 3 seconds once for the next 30 seconds,
// Every 6 seconds once for the next 60 seconds,
// Every 10 seconds for the next 60 seconds,
// Every 30 seconds for the next 60 seconds, and then
// Every 1 min until timeout (15 mins).
export const refreshIntervals = [
    20, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 10, 10, 10, 10, 10, 10, 30, 30, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60
];

export const AVAILABLE_PROVIDERS = {
    'PHONE_PE': true,
    'OFFLINE_UPI': false,
    'CASH': false
};

export const ONBOARDING_TYPES = {
    REGISTER: 'REGISTER',
    LOCATION: 'LOCATION',
    TIMINGS: 'TIMINGS',
    DELIVERY: 'DELIVERY',
    PAYMENT: 'PAYMENT',
    APPROVAL: 'APPROVAL'
};

export const NavbarElements = [
    'Orders',
    'Products',
    'Finance',
    'More'
];

export const NavbarTypes = {
    'Orders': 'Orders',
    'Products': 'Products',
    'Finance': 'Finance',
    'More': 'More'
};

export const Header = {
     CONTENT_TYPE: 'application/json'
}