import { Alert, Container } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import AddProductsGrid from "../../components/products/AddProductsGridComponent";
import ProgressBackdrop from "../../common/ProgressBackdropComponent";
import BackHeader from "../../components/commons/headers/BackHeader";
import SearchIconHeader from "../../components/commons/headers/SearchIconHeader";
import { useStore } from "../../contexts/store-context/StoreContextProvider";
import productRestService from "../../services/rest/ProductRestService";
import loadash from "lodash";
import productCategoryRestService from "../../services/rest/ProductCategoryRestService";
import SearchSuggestionComponent from "../../components/commons/search-suggestion/SearchSuggestionComponent";
import { useNavigate } from 'react-router-dom';

const DebounceTime = 500;
function AddProducts() {
    const { store } = useStore();
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const categoryName = searchParams.get('categoryName');
    const [category, setCategory] = useState(null);
    const categoryId = params.categoryId;
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [metrics, setMetrics] = useState([]);

    const onAction = (e) => {
        setShowSearchIcon(prev => !prev);
    }

    const handleClearInput = () => {
        setSearchText('');
    }

    const searchSuggestion = async (searchWord, categories) => {
        try {
            const categoryList = categories.map(item => item.id);
            const suggestions = await productRestService.productSearchSuggestion(searchWord, categoryList);
            setSuggestions(suggestions);
        } catch { }
    }

    const handleDebounceFn = (searchWord, categories) => {
        searchSuggestion(searchWord, categories);
    }

    const debounceFn = useCallback(loadash.debounce(handleDebounceFn, DebounceTime), []);

    const onChange = (e) => {
        setSearchText(e.target.value);
        if (e.target.value.length > 2) {
            debounceFn(e.target.value, [{ id: categoryId }]);
        } else {
            setSuggestions([]);
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            navigate(`/search?searchWord=${e.target.value}&type=ADD`);
        }
    }

    const onSuggestionClick = (word) => {
        navigate(`/search?searchWord=${word}&type=ADD`);
    }
    // const { state } = useLocation();
    // const productCategory = state.productCategory;
    // const search = state?.search;
    // const metrics = state.metrics;
    // const [alertContent, setAlertContent] = useState();
    // const [progress, setProgress] = useState(false);
    // let alertDiv;
    // if (alertContent !== undefined) {
    //     alertDiv = <Alert severity="error">{alertContent}</Alert>
    // }

    useEffect(() => {
        document.title = 'add product';
        productRestService.getMetrics().then(res => {
            setMetrics(res);
        }).catch((err) => { });
    }, [])

    return (
        <>
            <SearchIconHeader
                placeholder={"Search product to add"}
                showSearchIcon={showSearchIcon}
                onAction={onAction}
                subTitle={categoryName}
                supTitle={'Add Products'}
                handleClearInput={handleClearInput}
                value={searchText}
                onChange={onChange}
                onKeyDown={onKeyDown}
                autoFocus
            />
            <SearchSuggestionComponent suggestions={suggestions} onSuggestionClick={onSuggestionClick} />
            <Container direction="column" maxWidth="lg" sx={{ padding: 1, mt: 2, mb: 14 }}>
                <AddProductsGrid productCategory={{ id: categoryId, categoryName: categoryName }} metrics={metrics}></AddProductsGrid>
            </Container>
        </>
    );
}

export default AddProducts;