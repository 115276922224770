import { H4 } from '@lifease/lifease-storeui-components';
import { Box } from '@mui/material';
import React from 'react'




const NavbarElement = (props) => {
    return (
        <Box
            onClick={() => { props.handleClick(props.name) }}
            sx={{
                width: '80px',
                display: 'flex',
                p: 1,
                flexDirection: 'column',
                rowGap: 1,
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                borderTop: (props.selected) ? '4px solid #1C1B1F' : '4px solid transparent'
            }}>
            <H4>
                {props.Icon}
            </H4>
            <H4>
                {props.name}
            </H4>
        </Box>
    )
}

export default NavbarElement