import React, { useEffect, useState } from 'react'
import deliveryOptionRestService from '../../services/rest/DeliveryOptionRestService';
import storeScheduleRestService from '../../services/rest/StoreScheduleRestService';
import { Box } from '@mui/material';
import FilledHeader from '../commons/headers/FilledHeader';
import { useDialog } from '../../contexts/dialog/DialogContextProvider';
import ViewSchedule from '../commons/week-schedule/ViewSchedule';
import { splitHoursAbsolute, splitHoursRelative } from './splitHoursUtil';
import { DeliveryConfiguration, H6, PrimaryButton, SecondaryButton } from '@lifease/lifease-storeui-components';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import { useNavigate } from 'react-router-dom';
import { ScheduleType } from '../../utils/enums';

const AllDefaultOptions = [
    {
        deliveryType: 'SELF_PICKUP',
        deliveryFee: 0,
        distance: 10000,
        minOrderAmount: 0,
        type: ScheduleType.RELATIVE,
        duration: 30,
        daySchedule: null
    },
    {
        deliveryType: 'HOME_DELIVERY',
        distance: 500,
        minOrderAmount: 150,
        deliveryFee: 20,
        type: ScheduleType.RELATIVE,
        duration: 30,
        daySchedule: null
    },
    {
        deliveryType: 'HOME_DELIVERY',
        distance: 500,
        minOrderAmount: 150,
        deliveryFee: 0,
        type: ScheduleType.ABSOLUTE,
        duration: 1,
        daySchedule: null
    },
    {
        deliveryType: 'HOME_DELIVERY',
        distance: 2000,
        minOrderAmount: 250,
        deliveryFee: 35,
        type: ScheduleType.RELATIVE,
        duration: 45,
        daySchedule: null
    },
    {
        deliveryType: 'HOME_DELIVERY',
        distance: 2000,
        minOrderAmount: 250,
        deliveryFee: 0,
        type: ScheduleType.ABSOLUTE,
        duration: 1,
        daySchedule: null
    }
];

const DefaultDeliveryOptions = (props) => {
    const { store, setStep } = props;
    const { showSnackbar } = useSnackbar();
    const { showDialog } = useDialog();
    const [loading, setLoading] = useState(false);
    const [allSchedule, setAllSchedule] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getDefaultOptions();
    }, []);

    const onScheduleView = (idx) => {
        let temp = [];
        if (AllDefaultOptions[idx].type === ScheduleType.RELATIVE) {
            for (const daySchedule of allSchedule[idx]) {
                let slots = [];
                for (const slot of daySchedule.timeSlots) {
                    slots.push({ ...slot, duration: parseInt(slot.duration) * 60 });
                }
                temp.push({ ...daySchedule, timeSlots: slots });
            };
        } else {
            temp = allSchedule[idx];
        }
        showDialog(() => <span>Schedule</span>, () => <ViewSchedule schedule={{ daySchedules: temp }} />)
    }

    const onConfirmDefault = async () => {
        setLoading(true);
        try {
            for (let i = 0; i < 5; i++) {
                let data = {
                    deliveryType: AllDefaultOptions[i].deliveryType,
                    deliveryFee: AllDefaultOptions[i].deliveryFee,
                    distance: AllDefaultOptions[i].distance,
                    minOrderAmount: AllDefaultOptions[i].minOrderAmount
                }
                if (AllDefaultOptions[i].type === ScheduleType.RELATIVE) {
                    let temp = [];
                    for (const daySchedule of allSchedule[i]) {
                        let slots = [];
                        for (const slot of daySchedule.timeSlots) {
                            slots.push({ ...slot, duration: parseInt(slot.duration) * 60 });
                        }
                        temp.push({ ...daySchedule, timeSlots: slots });
                    };
                    data.weekSchedule = { daySchedules: temp, type: ScheduleType.RELATIVE };
                } else {
                    data.weekSchedule = { daySchedules: allSchedule[i], type: ScheduleType.ABSOLUTE };
                }
                await deliveryOptionRestService.createDeliveryOption(store.partyId, data);
            }
            showSnackbar('success', 'Default delivery options set');
            navigate('/onboarding');
        } catch (err) {
            showSnackbar('error', err.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <FilledHeader headText={'Add default delivery configuration'} backUrl={'/onboarding'} />
            <Box sx={{ mt: 2, px: 2, mb: 4 }}>
                <H6>NOTE: You can edit default options later</H6>
                <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, mt: 1 }}>
                    {
                        AllDefaultOptions.map((options, idx) => {
                            return <DeliveryConfiguration key={idx} onEditClick={() => { }} onDeleteClick={() => { }} onViewClick={() => { onScheduleView(idx) }} deliveryOptions={options} />
                        })
                    }
                </Box>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-around' }}>
                    <SecondaryButton disabled={loading} onClick={() => { setStep(prev => prev + 1) }}>Custom</SecondaryButton>
                    <PrimaryButton disabled={loading} onClick={onConfirmDefault}>Confirm default</PrimaryButton>
                </Box>
            </Box>
        </>
    );

    async function getDefaultOptions() {
        setLoading(true);
        try {
            let storeTimings = await storeScheduleRestService.getStoreSchedule(store.partyId);
            let finalSchedule = [];
            if (storeTimings) {
                storeTimings = JSON.parse(storeTimings);
                const schedule1 = [];
                const schedule2 = [];
                const schedule3 = [];
                const schedule4 = [];
                const schedule5 = [];
                for (const schedule of storeTimings.daySchedules) {
                    let timeSlot1 = [];
                    let timeSlot2 = [];
                    let timeSlot3 = [];
                    let timeSlot4 = [];
                    let timeSlot5 = [];
                    for (const slot of schedule.timeSlots) {
                        timeSlot1.push(splitHoursRelative(slot, 30));
                        timeSlot2.push(splitHoursRelative(slot, 30));
                        timeSlot3.push(splitHoursAbsolute(slot, 1));
                        timeSlot4.push(splitHoursRelative(slot, 45));
                        timeSlot5.push(splitHoursAbsolute(slot, 1));
                    }
                    schedule1.push({ dayOfWeek: schedule.dayOfWeek, timeSlots: timeSlot1 });
                    schedule2.push({ dayOfWeek: schedule.dayOfWeek, timeSlots: timeSlot2 });
                    schedule3.push({ dayOfWeek: schedule.dayOfWeek, timeSlots: [].concat.apply([], timeSlot3) });
                    schedule4.push({ dayOfWeek: schedule.dayOfWeek, timeSlots: timeSlot4 });
                    schedule5.push({ dayOfWeek: schedule.dayOfWeek, timeSlots: [].concat.apply([], timeSlot5) });
                }
                finalSchedule = [schedule1, schedule2, schedule3, schedule4, schedule5];
            }
            setAllSchedule(finalSchedule);
        } catch (err) {
            showSnackbar('error', err.message);
        } finally {
            setLoading(false);
        }
    }
}

export default DefaultDeliveryOptions